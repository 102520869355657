import { defineStore } from "pinia";
import { storeKeys } from "./storeKeys";
import { ITransaction, ITransactionStatus } from "@/api/resources/Transactions/transactionResource";

type Filters = {
  entityIds: string[],
  ids: string[],
  amountFrom: number | null,
  amountTo: number | null,
  status: ITransactionStatus[] | [],
  dateFrom: Date | null,
  dateTo: Date | null,
}

type Paging = {
  page: number,
  limit: number
}

export interface ITransactionStore extends ITransaction {
  selected: boolean;
}

export const useTransactionStore = defineStore(storeKeys.transaction, {
  state: () => ({
    transactionList: [] as ITransactionStore[],
    summaryTransaction: {
      [ITransactionStatus.Approved]: 0,
      [ITransactionStatus.Denied]: 0,
      [ITransactionStatus.Analyzing]: 0,
      [ITransactionStatus.Canceled]: 0
    } as Record<ITransactionStatus, number>,
    filters: {
      entityIds: [] as string[],
      ids: [] as string[],
      amountFrom: null,
      amountTo: null,
      status: [],
      dateFrom: null,
      dateTo: null,
    } as Filters,
    paging: {
      page: 1,
      limit: 20
    } as Paging
  }),
  actions: {
    addTransaction(transaction: ITransaction) {
      this.transactionList.push({ ...transaction, selected: false })
    },
    removeTransaction(transaction: ITransaction) {
      const index = this.transactionList.findIndex(t => t.id === transaction.id)
      if (index === -1) return
      this.transactionList.splice(index, 1)
    },
    updateTransactionList(transactionList: ITransaction[]) {
      this.transactionList = transactionList.map(t => ({ ...t, selected: false }))
    },
    updateSummaryTransaction(summaryTransaction: Record<ITransactionStatus, number>) {
      this.summaryTransaction = summaryTransaction
    }
  },
  getters: {
    getTransactionList(): ITransaction[] {
      return this.transactionList
    },
    getSummaryTransaction(): Record<ITransactionStatus, number> {
      return this.summaryTransaction
    },
  },
})
