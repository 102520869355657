import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-on-surface text-left text-h5 mb-6" }
const _hoisted_2 = { class: "d-flex ga-6" }
const _hoisted_3 = ["data-testid", "placeholder"]
const _hoisted_4 = ["data-testid", "placeholder"]

import { ref, computed } from "vue";
import ButtonFilterMobile from "@/components/shared/ButtonFilterMobile/ButtonFilterMobile.vue";
import { generateTestId } from "@/helpers/generateTestId";
import { ButtonFilterValueMobileContent as btnContent } from "./constants/ButtonFilterValueMobileElements";
import { useButtonFilterValueMobile } from "./hooks/useButtonFilterValueMobile";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonFilterValueMobile',
  setup(__props) {

const { t } = useI18n();
const { fromInput, toInput, isValid, handleSubmitFilter, handleClearAll } =
  useButtonFilterValueMobile();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ButtonFilterMobile, {
    label: _unref(t)(_unref(label).ButtonFilter.Value.title),
    active: _unref(isValid),
    height: 30,
    onCloseFilter: _unref(handleSubmitFilter),
    onClearFilter: _unref(handleClearAll)
  }, {
    default: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_unref(t)(_unref(label).ButtonFilter.Value.title)), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _withDirectives(_createElementVNode("input", {
            "data-testid": 
            _unref(generateTestId)(_unref(btnContent).ButtonFilterValueMobileFromElement)
          ,
            class: "w-100 pb-1 border-b-thin",
            type: "number",
            name: "from",
            id: "from",
            placeholder: _unref(t)(_unref(label).ButtonFilter.Value.From),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(fromInput) ? (fromInput).value = $event : null))
          }, null, 8, _hoisted_3), [
            [_vModelText, _unref(fromInput)]
          ])
        ]),
        _createElementVNode("div", null, [
          _withDirectives(_createElementVNode("input", {
            "data-testid": 
            _unref(generateTestId)(_unref(btnContent).ButtonFilterValueMobileToElement)
          ,
            class: "w-100 pb-1 border-b-thin",
            type: "number",
            name: "to",
            id: "to",
            placeholder: _unref(t)(_unref(label).ButtonFilter.Value.To),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(toInput) ? (toInput).value = $event : null))
          }, null, 8, _hoisted_4), [
            [_vModelText, _unref(toInput)]
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["label", "active", "onCloseFilter", "onClearFilter"]))
}
}

})