import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "on-surface" }

import { useDisplay, useTheme } from "vuetify";

import { transactionStatusIcon } from "@/model/transaction";
import ContainerSwiper from "@/components/shared/ContainerSwiper/ContainerSwiper.vue";
import SummaryCard from "@/components/shared/SummaryCard/SummaryCard.vue";
import CustomHero from "../shared/CustomHero/CustomHero.vue";
import { useTransactionStore } from "@/store/TransactionStore";
import { onMounted, reactive, ref, watch, computed } from "vue";
import gsap from "gsap";
import { ITransactionStatus } from "@/api/resources/Transactions/transactionResource";

import imgDesktopLight from "@/assets/backgrounds/transaction-desktop-light.png";
import imgDesktopDark from "@/assets/backgrounds/transaction-desktop-dark.png";
import { label } from "@/plugins/locales/global";
import { useI18n } from "vue-i18n";

type Language = "en-US" | "pt-BR";


export default /*@__PURE__*/_defineComponent({
  __name: 'SummaryCardTransaction',
  setup(__props) {

const { t } = useI18n();
const { mdAndUp } = useDisplay();

const { name: themeName } = useTheme();

const heroLabel = computed(() =>
  mdAndUp.value ? t(label.SummaryCard.title) : ""
);
const srcImage = computed(() => {
  if (mdAndUp.value) {
    if (themeName.value === "dark") return imgDesktopDark;
    else return imgDesktopLight;
  } else return "";
});

const transactionStore = useTransactionStore();

function formatNumber(number: number, language: Language) {
  const currencySymbol = {
    "en-US": "$",
    "pt-BR": "R$",
  };

  const suffixes = {
    "en-US": ["K", "M", "B", "T", "Q"],
    "pt-BR": ["mil", "mi", "bi", "tri", "qua"],
  };

  if (number < 1000) {
    return {
      format: number.toString(),
      value: number,
      currency: currencySymbol[language],
    };
  }

  const languageSuffixes = suffixes[language];

  for (let i = languageSuffixes.length - 1; i >= 0; i--) {
    const divisor = Math.pow(10, 3 * (i + 1));
    const quotient = number / divisor;

    if (quotient >= 1) {
      const formattedNumber = quotient.toFixed(3).replace(/\.?0+$/, "");
      return {
        format: `${formattedNumber}${languageSuffixes[i]}`,
        value: number,
        currency: currencySymbol[language],
      };
    }
  }
  return {
    format: number.toString(),
    value: number,
    currency: currencySymbol[language],
  };
}

const transactionTotals = ref({
  [ITransactionStatus.Approved]: 0,
  [ITransactionStatus.Denied]: 0,
  [ITransactionStatus.Analyzing]: 0,
  [ITransactionStatus.Canceled]: 0,
});

const animatedNumber = reactive({
  dataValue: 0,
});

onMounted(() => {
  gsap.to(animatedNumber, {
    dataValue: 10000,
    duration: 1,
  });
});

watch(
  () => transactionStore.getSummaryTransaction,
  () => {
    transactionTotals.value = {
      [ITransactionStatus.Approved]:
        transactionStore.getSummaryTransaction[ITransactionStatus.Approved],
      [ITransactionStatus.Denied]:
        transactionStore.getSummaryTransaction[ITransactionStatus.Denied],
      [ITransactionStatus.Analyzing]:
        transactionStore.getSummaryTransaction[ITransactionStatus.Analyzing],
      [ITransactionStatus.Canceled]:
        transactionStore.getSummaryTransaction[ITransactionStatus.Canceled],
    };
  }
);

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({
      height: _unref(mdAndUp) ? '220px' : '120px',
      paddingRight: _unref(mdAndUp) && '1rem',
    })
  }, [
    _createVNode(CustomHero, {
      class: _normalizeClass({ 'd-flex align-end justify-space-between': _unref(mdAndUp) }),
      label: heroLabel.value,
      srcImage: srcImage.value
    }, {
      default: _withCtx(() => [
        _createVNode(ContainerSwiper, null, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "status-cards d-flex flex-row ga-2",
              style: _normalizeStyle({ padding: !_unref(mdAndUp) && '0 1rem' })
            }, [
              (!_unref(mdAndUp))
                ? (_openBlock(), _createBlock(SummaryCard, {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = (e) => console.log(e)),
                    "background-image": "transaction",
                    width: "186px",
                    justify: "flex-end"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("h2", _hoisted_1, _toDisplayString(_unref(t)(_unref(label).SummaryCard.title)), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(transactionTotals.value, (transaction, key, index) => {
                return (_openBlock(), _createBlock(SummaryCard, {
                  key: index,
                  onClick: (e) => console.log(`transaction ${key} -- ${transaction}`),
                  status: Number(key),
                  justify: "flex-end"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, [
                      _createVNode(_component_v_icon, {
                        icon: _unref(transactionStatusIcon)[key as ITransactionStatus],
                        size: "8px"
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_unref(t)(
                    `${_unref(label).Shared.TransactionStatus._}.${_unref(ITransactionStatus)[key]}`
                  )), 1)
                    ]),
                    _createElementVNode("p", {
                      ref_for: true,
                      ref: "animateElement",
                      class: _normalizeClass(_unref(themeName) === 'light' ? 'text-black' : 'text-white')
                    }, [
                      _createElementVNode("span", null, _toDisplayString(formatNumber(transaction ?? 0, "en-US").currency), 1),
                      _createTextVNode(" " + _toDisplayString(transaction > 0
                    ? formatNumber(
                        transaction ?? animatedNumber.dataValue,
                        "en-US"
                      ).format
                    : animatedNumber.dataValue === 10000 && !transaction
                    ? 0
                    : formatNumber(
                        !transaction
                          ? animatedNumber.dataValue
                          : transaction ?? 0,
                        "en-US"
                      ).format), 1)
                    ], 2)
                  ]),
                  _: 2
                }, 1032, ["onClick", "status"]))
              }), 128))
            ], 4)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["class", "label", "srcImage"])
  ], 4))
}
}

})