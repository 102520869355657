import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "on-surface-variant" }
const _hoisted_2 = { class: "d-flex justify-space-between" }
const _hoisted_3 = { class: "d-flex justify-space-between text-caption" }

import { ITransactionDetails_Items } from "@/api/resources/Transactions/transactionResource";
import { computed } from "vue";
import { label } from "@/plugins/locales/global";
import { useI18n } from "vue-i18n";
import { useDisplay } from "vuetify";
import { useCardColors } from "@/components/hooks/useCardColors";


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionItems',
  props: {
    items: {}
  },
  setup(__props: any) {



const { t } = useI18n();
const display = useDisplay();
const {
  titleComputedClass: contentComputedClass,
  contentComputedClass: titleComputedClass,
} = useCardColors();

const displaySizeClass = computed(() => (display.mdAndUp.value ? "md" : "sm"));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['content', displaySizeClass.value])
  }, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_unref(t)(
          _unref(label).TransactionDetailsPage.DetailTransactionTabs.Items.Breakdown
            .title
        )), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (e, i) => {
      return (_openBlock(), _createElementBlock("section", {
        key: i,
        class: "elevation-1 rounded-xl pa-6"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", {
            class: _normalizeClass(_unref(titleComputedClass))
          }, _toDisplayString(e.name), 3),
          _createElementVNode("span", {
            class: _normalizeClass(_unref(titleComputedClass))
          }, _toDisplayString((e.price * e.quantity).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })), 3)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", {
            class: _normalizeClass(_unref(contentComputedClass))
          }, _toDisplayString(e.price.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })), 3),
          _createElementVNode("span", {
            class: _normalizeClass(_unref(contentComputedClass))
          }, "x" + _toDisplayString(e.quantity), 3)
        ])
      ]))
    }), 128))
  ], 2))
}
}

})