import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-testid"]

import ButtonFilter from "@/components/shared/ButtonFilter/ButtonFilter.vue";
import { generateTestId } from "@/helpers/generateTestId";
import ChipSelectable from "../ChipSelectable/ChipSelectable.vue";
import { ButtonFilterStatusElements } from "./constants/ButtonFilterStatusElements";
import { useButtonFilterStatus } from "./useButtonFilterStatus";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonFilterStatus',
  setup(__props) {

const { t } = useI18n();
const {
  status,
  handleClearAll,
  handleSubmitStatus,
  handleCloseFilter,
  modelStatus,
} = useButtonFilterStatus();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    "data-testid": _unref(generateTestId)(_unref(ButtonFilterStatusElements).Content)
  }, [
    _createVNode(ButtonFilter, {
      label: _unref(t)(_unref(label).ButtonFilter.Status),
      onClearFilter: _unref(handleClearAll),
      onSubmitFilter: _unref(handleSubmitStatus),
      onCloseFilter: _unref(handleCloseFilter),
      active: (_unref(modelStatus) ?? []).length > 0
    }, {
      default: _withCtx(() => [
        _createVNode(ChipSelectable, {
          chips: _unref(status),
          modelValue: _unref(modelStatus),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(modelStatus) ? (modelStatus).value = $event : null)),
          multiple: ""
        }, null, 8, ["chips", "modelValue"])
      ]),
      _: 1
    }, 8, ["label", "onClearFilter", "onSubmitFilter", "onCloseFilter", "active"])
  ], 8, _hoisted_1))
}
}

})