<template>
  <ButtonFilter
    :label="t(label.ButtonFilter.Date.title)"
    @submit-filter="submitButtonFilter"
    @clear-filter="clearAllFilter"
    @close-filter="handleCloseFilter"
    :active="isFilterActive"
  >
    <div style="display: flex; gap: 16px; align-items: center">
      <ChipSelectable
        :chips="timePeriodOptions"
        v-model="selectedTimePeriodModel"
      ></ChipSelectable>
      <ButtonDatePicker
        :active="isCustomFilterActive"
        @click="handleClickCustom"
      />
    </div>
    <DatePicker
      v-if="dialogModel"
      v-model:dialog="dialogModel"
      :dates="datePickerModel"
      @cancel="cancelDatePicker"
      @submit="submitDatePicker"
      range
    />
  </ButtonFilter>
</template>

<script setup lang="ts">
import ButtonFilter from "@/components/shared/ButtonFilter/ButtonFilter.vue";
import ChipSelectable from "@/components/shared/ChipSelectable/ChipSelectable.vue";
import DatePicker from "@/components/shared/DatePicker/DatePicker.vue";
import ButtonDatePicker from "@/components/shared/ButtonDatePicker/ButtonDatePicker.vue";

import { useButtonFilterDate } from "./useButtonFilterDate";
import { useTransactionStore } from "@/store/TransactionStore";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";

const { t,locale } = useI18n();
const { $state } = useTransactionStore();
const {
  isFilterActive,
  isCustomFilterActive,
  cancelDatePicker,
  clearAllFilter,
  datePickerModel,
  dialogModel,
  selectedTimePeriodModel,
  submitButtonFilter,
  submitDatePicker,
  timePeriodOptions,
  handleCloseFilter,
  handleClickCustom,
} = useButtonFilterDate();
</script>
