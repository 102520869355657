<template>
  <ButtonDatePicker :active="!!datePickerModel" @click="handleClick" />

  <Datepicker
    v-if="dialogModel"
    v-model:dialog="dialogModel"
    :dates="datePickerModel"
    @cancel="cancelDatePicker"
    @submit="submitDatePicker"
    range
  />
</template>
<script setup lang="ts">
import ButtonDatePicker from "@/components/shared/ButtonDatePicker/ButtonDatePicker.vue";
import Datepicker from "@/components/shared/DatePicker/DatePicker.vue";
import { ref } from "vue";

const dialogModel = ref(false);
const datePickerModel = ref<Date[] | undefined>(undefined);

function handleClick() {
  dialogModel.value = true;
}

function cancelDatePicker() {
  dialogModel.value = false;
  datePickerModel.value = undefined;
}

function submitDatePicker(date: Date[]) {
  datePickerModel.value = date;
  dialogModel.value = false;
}
</script>
