import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, Transition as _Transition } from "vue"

const _hoisted_1 = ["data-testid"]
const _hoisted_2 = ["data-testid"]
const _hoisted_3 = ["placeholder", "data-testid"]
const _hoisted_4 = ["data-testid"]

import { nextTick, onMounted, ref, watch } from "vue";
import gsap from "gsap";

import ButtonFilter from "@/components/shared/ButtonFilter/ButtonFilter.vue";
import ChipRemovable from "@/components/shared/ChipRemovable/ChipRemovable.vue";

import {
  ButtonFilterTextEmits,
  useButtonFilterText,
} from "@/components/shared/ButtonFilterText/useButtonFilterText";
import { SuggestionItem } from "@/api/resources/Transactions/transactionResource";
import { generateTestId } from "@/helpers/generateTestId";
import { ButtonFilterTextConstants } from "./constants/ButtonFilterIDs";
import ButtonFilterTextSuggestionBox from "./ButtonFilterTextSuggestionBox.vue";

const TIME_ANIMATION_EXPANDED = 600;


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonFilterText',
  props: /*@__PURE__*/_mergeModels({
    label: {},
    placeholder: {},
    percentLimit: {},
    options: {},
    loadingSuggestions: { type: Boolean },
    showOptions: { type: Boolean },
    name: {}
  }, {
    "input": {},
    "inputModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["submit", "clear"], ["update:input"]),
  setup(__props: any, { emit: __emit }) {

const emits = __emit;
const props = __props;

function handleBlurInput() {
  const time = setTimeout(() => {
    openSuggestionBox.value = false;

    clearTimeout(time);
  }, 200);
}

const inputModel = _useModel(__props, "input");

const {
  selectedChipList,
  loadSelectedChipList,
  submitButtonFilterText,
  updateSelectedChipList,
  clearSelectedChipList,
} = useButtonFilterText(emits);

const {
  suggestionBoxList: suggestionBoxListMemory,
  selectedChipList: selectedChipListMemory,
  removeSelectedChipsList: removeSelectedChipsListMemory,
  addSelectedChipsList: addSelectedChipsListMemory,
  loadSelectedChipList: loadSuggestionChipListMemory,
  clearSelectedChipList: clearSuggestionsChipListMemory,
  updateSelectedChipList: updateSelectedChipListMemory,
  loadSuggestionBoxList: loadSuggestionBoxListMemory,
  clearSuggestionBoxList: clearSuggestionBoxListMemory,
} = useButtonFilterText(emits);

const openSuggestionBox = ref(false);
const buttonOpen = ref(false);

const chipsSelectedRef = ref<HTMLElement | null>(null);
const containerRef = ref<HTMLElement | null>(null);
const chipContainerRef = ref<HTMLElement | null>(null);
const chipsGreaterThanInputContainer = ref(false);

function handleCloseButtonFilterText() {
  buttonOpen.value = false;
  updateSelectedChipListMemory(selectedChipList);
}

function handleSubmitButtonFilterText(ev: () => void) {
  ev();
  updateSelectedChipList(selectedChipListMemory);
  submitButtonFilterText();
  buttonOpen.value = false;
}

function handleClearButtonFilterText() {
  clearSuggestionsChipListMemory();
  clearSelectedChipList();
  buttonOpen.value = false;
}

function setGreaterThanInputContainer(chipContainerWidth: number) {
  if (!containerRef.value || chipContainerWidth < 0) return;

  const containerWidth = containerRef.value.offsetWidth;

  const percentage = (chipContainerWidth * 100) / containerWidth;
  chipsGreaterThanInputContainer.value = isNaN(percentage)
    ? 0 > props.percentLimit
    : percentage > props.percentLimit;
}

async function handleAddSuggestion(suggestion: SuggestionItem) {
  addSelectedChipsListMemory(suggestion);
  inputModel.value = "";
  await nextTick();
  if (!containerRef.value || !chipContainerRef.value) return;

  const chipContainerWidth = chipContainerRef.value.offsetWidth;
  setGreaterThanInputContainer(chipContainerWidth);
}

async function handleRemoveSuggestion(suggestion: SuggestionItem) {
  removeSelectedChipsListMemory(suggestion);
  await nextTick();

  if (!containerRef.value || !chipsSelectedRef.value) return;

  const childrensArray = Array.from(chipsSelectedRef.value.children);
  const childrenFilterChips = childrensArray.filter((child) => {
    return child.className.includes("v-chip");
  });

  const reduceGetTotalWidth = childrenFilterChips.reduce(
    (acc, child) => acc + (child as HTMLElement).offsetWidth,
    0
  );

  const chipContainerWidth = reduceGetTotalWidth;

  setGreaterThanInputContainer(chipContainerWidth);
}

watch(buttonOpen, async () => {
  const root_properties = document.querySelector(":root") as HTMLElement;

  if (
    buttonOpen.value &&
    chipsGreaterThanInputContainer &&
    selectedChipListMemory.length
  ) {
    root_properties.style.setProperty("--chip-selected-box-delay", "500ms");

    if (buttonOpen.value) {
      const time = setTimeout(async () => {
        await nextTick();
        if (!containerRef.value || !chipContainerRef.value) return;

        const chipContainerWidth = chipContainerRef.value.offsetWidth;

        setGreaterThanInputContainer(chipContainerWidth);
        clearTimeout(time);
      }, TIME_ANIMATION_EXPANDED);
    }

    return;
  }
  root_properties.style.setProperty("--chip-selected-box-delay", "0ms");
  gsap.to(chipsSelectedRef.value, {
    opacity: 0,
    display: "flex",
    flexWrap: "nowrap",
    duration: 0.1,
  });
});

watch(
  () => props.options,
  (value) => {
    clearSuggestionBoxListMemory();
    loadSuggestionBoxListMemory(value);
  },
  {
    deep: true,
  }
);
watch(
  () => props.showOptions,
  (value) => {
    openSuggestionBox.value = value;
  }
);

watch(
  inputModel,
  () => {
    if ((inputModel.value as string).length === 0) {
      openSuggestionBox.value = false;
      return;
    }
    openSuggestionBox.value = true;
  },
  {
    immediate: true,
  }
);
onMounted(() => {
  loadSelectedChipList([]);
  loadSuggestionChipListMemory(selectedChipList);
});

return (_ctx: any,_cache: any) => {
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", {
    style: {"position":"relative"},
    "data-testid": 
      _unref(generateTestId)(_unref(ButtonFilterTextConstants).ButtonFilterText, _ctx.name ?? '')
    
  }, [
    _createVNode(_component_v_menu, {
      "close-on-content-click": "",
      "open-on-click": false,
      modelValue: openSuggestionBox.value,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((openSuggestionBox).value = $event))
    }, {
      activator: _withCtx(({ props }) => [
        _createVNode(ButtonFilter, {
          label: _ctx.label,
          onOpenFilter: _cache[3] || (_cache[3] = 
            () => {
              buttonOpen.value = true;
            }
          ),
          onCloseFilter: handleCloseButtonFilterText,
          onSubmitFilter: handleSubmitButtonFilterText,
          onClearFilter: handleClearButtonFilterText,
          active: _unref(selectedChipList).length > 0
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "d-flex align-items-center w-100",
              ref_key: "containerRef",
              ref: containerRef,
              style: {"flex-wrap":"nowrap","gap":"1rem"}
            }, [
              (!chipsGreaterThanInputContainer.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "d-flex align-items-center",
                    style: {"flex-wrap":"nowrap","gap":"1rem"},
                    ref_key: "chipContainerRef",
                    ref: chipContainerRef,
                    "data-testid": 
                _unref(generateTestId)(
                  _unref(ButtonFilterTextConstants).ButtonFilterTextSelectedItemsInput,
                  _ctx.name ?? ''
                )
              
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(selectedChipListMemory), (suggestion) => {
                      return (_openBlock(), _createBlock(ChipRemovable, {
                        key: suggestion.id,
                        "chip-id": (suggestion.id as string),
                        label: suggestion.value,
                        onClick: ($event: any) => (inputModel.value = suggestion.value),
                        onRemoveChip: 
                  () => {
                    _unref(removeSelectedChipsListMemory)(suggestion);
                  }
                ,
                        style: {"width":"max-content"}
                      }, null, 8, ["chip-id", "label", "onClick", "onRemoveChip"]))
                    }), 128))
                  ], 8, _hoisted_2))
                : _createCommentVNode("", true),
              _withDirectives(_createElementVNode("input", _mergeProps({
                style: {"width":"100%"},
                type: "text"
              }, props, {
                placeholder: _ctx.placeholder,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputModel).value = $event)),
                onBlur: handleBlurInput,
                onFocus: _cache[1] || (_cache[1] = 
                () => {
                  if (_unref(suggestionBoxListMemory).length > 0 && (inputModel.value as string).length){
                      openSuggestionBox.value = true;

                  }
                }
              ),
                onKeypress: _cache[2] || (_cache[2] = _withKeys(
                () => {
                  const regex = new RegExp(inputModel.value as string, 'i');
                  const suggestionFind = _unref(suggestionBoxListMemory).find(
                    (suggestion) => regex.test(suggestion.value.toLowerCase())
                  );
                  if (suggestionFind) handleAddSuggestion(suggestionFind);
                }
              , ["enter"])),
                "data-testid": 
                _unref(generateTestId)(
                  _unref(ButtonFilterTextConstants).ButtonFilterTextInput,
                  _ctx.name ?? ''
                )
              
              }), null, 16, _hoisted_3), [
                [_vModelText, inputModel.value]
              ])
            ], 512)
          ]),
          _: 2
        }, 1032, ["label", "active"])
      ]),
      default: _withCtx(() => [
        _createVNode(ButtonFilterTextSuggestionBox, {
          show: openSuggestionBox.value!,
          options: _ctx.options,
          "loading-suggestions": _ctx.loadingSuggestions,
          name: _ctx.name,
          "handle-add-suggestion": handleAddSuggestion
        }, null, 8, ["show", "options", "loading-suggestions", "name"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_Transition, { mode: "in-out" }, {
      default: _withCtx(() => [
        (
          Boolean(
            buttonOpen.value &&
              chipsGreaterThanInputContainer.value &&
              _unref(selectedChipListMemory).length
          )
        )
          ? (_openBlock(), _createBlock(_component_v_card, {
              key: 0,
              style: {"position":"absolute","width":"100%","padding":"0.5rem 1.5rem","gap":"1rem","display":"flex","flex-wrap":"nowrap","top":"3rem"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  style: {"display":"flex","flex-wrap":"wrap","gap":"1rem"},
                  ref_key: "chipsSelectedRef",
                  ref: chipsSelectedRef,
                  "data-testid": 
            _unref(generateTestId)(
              _unref(ButtonFilterTextConstants).ButtonFilterTextSelectedItemsBox,
              _ctx.name ?? ''
            )
          
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(selectedChipListMemory), (suggestion) => {
                    return (_openBlock(), _createBlock(ChipRemovable, {
                      key: suggestion.id,
                      "chip-id": (suggestion as any).id,
                      label: suggestion.value,
                      onRemoveChip: 
              () => {
                handleRemoveSuggestion(suggestion);
              }
            
                    }, null, 8, ["chip-id", "label", "onRemoveChip"]))
                  }), 128))
                ], 8, _hoisted_4)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 8, _hoisted_1))
}
}

})