import { createI18n } from "vue-i18n";
import { label as english } from "./locales/english";
import { label as portuguese } from "./locales/portuguese";
import { label as spanish } from "./locales/spanish";

const locales = {
  'en-US': { label: english },
  'pt-BR': { label: portuguese },
  es: { label: spanish }
}

function loadLocale(): string {
  const lang = localStorage.getItem("language") || "en-US";
  return lang;
}

export const i18n = createI18n({
  legacy: false,
  locale: loadLocale(),
  fallbackLocale: 'en-US',
  messages: Object.assign(locales),
  datetimeFormats: {
    'en-US': {
      short: {
        year: 'numeric', month: 'short', day: 'numeric'
      },
      long: {
        year: 'numeric', month: 'short', day: 'numeric',
        weekday: 'short', hour: 'numeric', minute: 'numeric'
      },
      timeago: { hour: "2-digit", minute: "2-digit" }
    },
    'pt-BR': {
      short: {
        year: 'numeric', month: 'short', day: 'numeric'
      },
      long: {
        year: 'numeric', month: 'short', day: 'numeric',
        weekday: 'short', hour: 'numeric', minute: 'numeric'
      },
      timeago: { hour: "2-digit", minute: "2-digit", dayPeriod: "short" },
    },
    es: {
      short: {
        year: 'numeric', month: 'short', day: 'numeric'
      },
      long: {
        year: 'numeric', month: 'short', day: 'numeric',
        weekday: 'short', hour: 'numeric', minute: 'numeric'
      },
      timeago: { hour: "2-digit", minute: "2-digit", dayPeriod: "short" },
    }
  }
});
