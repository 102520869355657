<template>
  <TransactionCardList
    v-if="data.length"
    style="display: flex; flex-direction: column; gap: 8px"
    :onOnLoadLastItem="() => emits('fetchData')"
  >
    <template v-for="(item, index) in data" :key="index">
      <CardItemDesktop
        :item="item"
        :selectable="false"
        :actions="false"
        :columns="hasEntityName ? 5 : 4"
        v-model:selected="item.selected"
        @click.stop="emits('containerClick', item)"
      >
        <template #iconOrCheckbox="props">
          <v-checkbox-btn
            v-if="props.data.selectable && props.data.isHovering"
            style="width: 32px; height: 32px"
            size="32px"
            color="information"
            v-model="item.selected"
            @click.stop="emits('selected', item)"
          ></v-checkbox-btn>

          <v-icon
            v-else
            :size="transactionStatusIconSize[item.status]"
            style="width: 32px; height: 32px"
            class="rounded-xl"
            :icon="transactionStatusIcon[item.status]"
            :class="[classNameWhole[item.status], 'whole']"
          />
        </template>
        <template #default="props">
          <span class="text-cs_on_surface_variant">
            <span class="text-cs_ref_palette_neutral70">#</span>{{ item.code }}
          </span>
          <span
            v-if="hasEntityName"
            class="text-cs_on_surface_variant flex-shrink-1"
            style="justify-self: start"
          >
            {{ ellipsisText(item.entityName) }}
          </span>
          <span class="text-cs_on_surface_variant">
            {{
              item.totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
            }}
          </span>
          <div class="status">
            <div :class="[themeName, classNameText[item.status], 'text']">
              <v-icon :icon="transactionStatusIcon[item.status]" />
              <span>{{
                t(
                  `${label.Shared.TransactionStatus._}.${
                    ITransactionStatus[item.status]
                  }`
                )
              }}</span>
            </div>
          </div>
          <span style="justify-self: end" class="text-cs_on_surface_variant">
            <div
              v-if="!isSelected && props.data.isHovering && props.data.actions"
              class="text-overline"
            >
              <v-btn
                size="40px"
                style="width: 32px; height: 32px"
                variant="text"
                icon="mdi-check"
                color="information"
                @click.prevent.stop="() => console.log('mdi-check')"
              />
              <v-btn
                size="40px"
                style="width: 32px; height: 32px"
                variant="text"
                icon="mdi-repeat"
                color="information"
                @click.prevent.stop="() => console.log('mdi-repeat')"
              />
              <v-btn
                size="40px"
                style="width: 32px; height: 32px"
                variant="text"
                icon="mdi-priority-high"
                color="information"
                @click.prevent.stop="() => console.log('mdi-priority-high')"
              />
              <v-btn
                size="40px"
                style="width: 32px; height: 32px"
                variant="text"
                icon="mdi-rotate-left"
                color="information"
                @click.prevent.stop="() => console.log('mdi-rotate-left')"
              />
              <v-btn
                size="40px"
                style="width: 32px; height: 32px"
                variant="text"
                icon="mdi-square"
                color="information"
                @click.prevent.stop="() => console.log('mdi-square')"
              />
            </div>
            <span
              v-else
              style="font-size: var(--v-typescale-label-medium-size)"
              >{{
                d(
                  new Date(item.consumerRequestedAt),
                  isTodayDate(new Date(item.consumerRequestedAt))
                    ? "timeago"
                    : "short"
                )
              }}</span
            >
          </span>
        </template>
      </CardItemDesktop>
    </template>
  </TransactionCardList>
</template>

<script setup lang="ts">
import CardItemDesktop from "@/components/nexus/CardItem/CardItemDesktop.vue";
import TransactionCardList from "@/components/shared/TransactionCardList/TransactionCardList.vue";

import {
  transactionStatusIcon,
  transactionStatusIconSize,
} from "@/model/transaction";
import { useTheme } from "vuetify";
import { computed, ref, watch } from "vue";
import {
  ITransaction,
  ITransactionStatus,
} from "@/api/resources/Transactions/transactionResource";
import {
  useClassByThemeStatus,
  UseClassByThemeStatusProps,
} from "../hooks/useClassByTheme";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";
import { ITransactionStore } from "@/store/TransactionStore";
import { ellipsisText } from "@/utils/ellipsisUtility";

const classThemeWhole = {
  light: {
    [ITransactionStatus.Approved]: "bg-success-container text-success",
    [ITransactionStatus.Denied]: "bg-error-container text-error",
    [ITransactionStatus.Analyzing]: "bg-information-container text-information",
    [ITransactionStatus.Canceled]:
      "bg-cs_ref_palette_neutral80 text-cs_ref_palette_neutral_variant30",
    [ITransactionStatus.None]: "",
  },
  dark: {
    [ITransactionStatus.Approved]:
      "bg-cs_ref_palette_success80 text-cs_ref_palette_success10",
    [ITransactionStatus.Denied]:
      "bg-cs_ref_palette_error95 text-cs_ref_palette_error10",
    [ITransactionStatus.Analyzing]:
      "bg-cs_ref_palette_information90 text-cs_ref_palette_information10",
    [ITransactionStatus.Canceled]:
      "bg-cs_ref_palette_neutral80 text-cs_ref_palette_neutral_variant30",
    [ITransactionStatus.None]: "",
  },
};

const classThemeText = {
  light: {
    [ITransactionStatus.Approved]: "text-success",
    [ITransactionStatus.Denied]: "text-error",
    [ITransactionStatus.Analyzing]: "text-information",
    [ITransactionStatus.Canceled]: "text-dim",
    [ITransactionStatus.None]: "",
  },
  dark: {
    [ITransactionStatus.Approved]: "text-success",
    [ITransactionStatus.Denied]: "text-error",
    [ITransactionStatus.Analyzing]: "text-information",
    [ITransactionStatus.Canceled]: "text-dim",
    [ITransactionStatus.None]: "",
  },
};

const props = defineProps<{ data: ITransactionStore[] }>();

const emits = defineEmits<{
  (e: "selected", transaction: ITransactionStore): void;
  (e: "containerClick", transaction: ITransaction): void;
  (e: "fetchData"): void;
}>();

const { name: themeName } = useTheme();
const { t, d } = useI18n();

const classNameWhole = useClassByThemeStatus(classThemeWhole);
const classNameText = useClassByThemeStatus(classThemeText);

const isSelected = ref(false);

const hasEntityName = computed(() => {
  return props.data.filter((t) => t.entityName).length > 0;
});

watch(
  () => props.data,
  (newV, oldV) => {
    isSelected.value = newV.some((t) => t.selected);
  },
  { deep: true }
);

function isTodayDate(date: Date) {
  const dateNow = new Date(date);
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  dateNow.setHours(0, 0, 0, 0);

  return dateNow.getTime() === todayDate.getTime();
}
</script>

<style scoped lang="scss">
.status {
  .v-icon {
    $size: var(--mdui-typescale-body-small-size);

    width: $size;
    min-width: $size;
    height: $size;
    font-size: $size;

    margin-right: 4px;
  }
}
</style>
