import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import TabsSwitchComponentDesktop from "@/components/one/TabsSwitchComponent/TabsSwitchComponentDesktop/TabsSwitchComponentDesktop.vue";
import TabsSwitchComponentMobile from "@/components/one/TabsSwitchComponent/TabsSwitchComponentMobile/TabsSwitchComponentMobile.vue";
import { TabsSwitchItem } from "@/model/tabSwitch";
import { DetailTransactionTabs } from "@/model/transaction";
import { useDisplay } from "vuetify";


export default /*@__PURE__*/_defineComponent({
  __name: 'TabsSwitch',
  props: {
    options: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {


const emits = __emit;

const { mdAndUp } = useDisplay();

return (_ctx: any,_cache: any) => {
  return (_unref(mdAndUp))
    ? (_openBlock(), _createBlock(TabsSwitchComponentDesktop, {
        key: 0,
        options: _ctx.options,
        onClick: _cache[0] || (_cache[0] = (id) => emits('click', id))
      }, null, 8, ["options"]))
    : (_openBlock(), _createBlock(TabsSwitchComponentMobile, {
        key: 1,
        options: _ctx.options,
        onClick: _cache[1] || (_cache[1] = (id) => emits('click', id))
      }, null, 8, ["options"]))
}
}

})