import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"


import { useTheme } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { SummaryCardElement } from "./constants/SummaryCardId";
import { ITransactionStatus } from "@/api/resources/Transactions/transactionResource";
import { useClassByThemeStatus } from "@/components/hooks/useClassByTheme";

type Props = {
  status?: ITransactionStatus;
  justify?: "flex-start" | "center" | "flex-end";
  width?: `${number}px`;
  height?: `${number}px`;
  backgroundImage?: string;
};

type Events = {
  click: [Props];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SummaryCard',
  props: {
    status: { default: ITransactionStatus.Analyzing },
    justify: { default: "flex-start" },
    width: { default: "120px" },
    height: { default: "120px" },
    backgroundImage: { default: "" }
  },
  emits: ["click"],
  setup(__props: any) {



const classThemeWholeDefault = {
  [ITransactionStatus.Approved]: "bg-success-container text-success",
  [ITransactionStatus.Denied]: "bg-error-container text-error",
  [ITransactionStatus.Analyzing]: "bg-information-container text-information",
  [ITransactionStatus.Canceled]: "bg-surface-dim text-on-surface-variant",
  [ITransactionStatus.None]: "",
};

const classThemeWhole = {
  light: {
    ...classThemeWholeDefault,
  },
  dark: {
    ...classThemeWholeDefault,
    [ITransactionStatus.Denied]: "bg-ref-palette-error10 text-error",
    [ITransactionStatus.Analyzing]:
      "bg-ref-palette-information10 text-ref-palette-information",
    [ITransactionStatus.Canceled]:
      "bg-ref-palette-neutral20 text-ref-palette-neutral-variant80",
    [ITransactionStatus.None]: "",
  },
};

const classTheme = useClassByThemeStatus(classThemeWhole);



const { name: themeName } = useTheme();

return (_ctx: any,_cache: any) => {
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    id: "summary-card",
    "data-testid": _unref(generateTestId)(_unref(SummaryCardElement)),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', { ..._ctx.$props }))),
    class: _normalizeClass([_unref(themeName), _unref(classTheme)[_ctx.status], _ctx.backgroundImage]),
    image: _ctx.backgroundImage,
    variant: "flat"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, {
        style: _normalizeStyle({
        display: 'flex',
        flexDirection: 'column',
        'justify-content': 'end',
        minWidth: _ctx.width,
        height: _ctx.height,
      })
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["style"])
    ]),
    _: 3
  }, 8, ["data-testid", "class", "image"]))
}
}

})