export const label = {
  TopMenu: {
    Search: "Search on ClearSale"
  },
  Shared: {
    TransactionStatus: {
      Approved: "Approved",
      Denied: "Denied",
      Analyzing: "Analyzing",
      Canceled: "Canceled",
      _: "label.Shared.TransactionStatus"
    },
    TransactionActions: {
      ForceApproval: "Force approval",
      Reanalyze: "Reanalyze",
      Prioritize: "Prioritize",
      RequestChargeback: "Request chargeback",
      CancelOrder: "Cancel order",
      _: "label.Shared.TransactionActions"
    },
    PaymentType: {
      CreditCard: 'Credit Card',
      BoletoBancario: 'Bank Slip',
      DebitoBancario: 'Bank Debit',
      DebitoBancarioDinheiro: 'Cash Bank Debit',
      DebitoBancarioCheque: 'Check Bank Debit',
      TransferenciaBancaria: 'Bank Transfer',
      SedexCobrar: 'Sedex Collect',
      Cheque: 'Check',
      Cash: 'Cash',
      Financiamento: 'Financing',
      Fatura: 'Invoice',
      Cupom: 'Coupon',
      Multicheque: 'Multi-check',
      Others: 'Others',
      Vale: 'Voucher',
      DebitoParcelado: 'Installment Debit',
      ValeDesconto: 'Discount Voucher',
      CartaoDebito: 'Debit Card',
      ValeAlimentacao: 'Food Voucher',
      Pix: 'Pix',
      Cashback: 'Cashback',
      GiftCard: 'Gift Card',
      CartaoDebitoTransferencia: 'Debit Card Transfer',
      _: "label.Shared.PaymentType"
    }
  },
  SummaryCard: {
    title: "Orders"
  },
  AddOrder: "Add",
  ButtonFilter: {
    ID: {
      title: "ID",
      placeholder: "Enter IDs"
    },
    Date: {
      title: "Date",
      Week: "1 week",
      MTD: "Month to date (MTD)",
      Month: "1 month",
      ThreeMonths: "3 months",
      SixMonths: "6 months",
      OneYear: "1 year",
      Custom: "Custom"
    },
    Store: {
      title: "Store",
      placeholder: "Enter store names"
    },
    Status: "Status",
    Value: {
      title: "Value",
      From: "From:",
      To: "To:"
    },
    Advanced: "Advanced",
    BottomSheet: {
      suggestions: "Suggestions",
      Date: {
        CustomPeriod: "Custom period"
      }
    }
  },
  TransactionDetailsPage: {
    title: "Order details",
    Actions: "Actions",
    DetailTransactionTabs: {
      Summary: {
        title: "Summary",
        Order: {
          title: "Order",
          ID: "ID",
          Store: "Store",
          Value: "Value"
        },
        Status: {
          title: "Status",
          TransactionStatus: "Order status",
          ChargebackStatus: "Chargeback status"
        },
        Costumer: {
          title: "Costumer",
          Email: "Email"
        },
        Dates: {
          title: "Dates",
          TransactionDate: "Order date",
          ClearSaleDate: "ClearSale date"
        }
      },
      History: {
        title: "History",
        OrderHistory: {
          title: "Order history"
        },
        TransactionReceived: "Order received",
        Analysis: "Analysis",
        Risks: "Risks",
        FraudScore: "Fraud score",
        Results: "Results",
        TransactionDenied: "Order denied",
        ReanalysisRequested: "Reanalysis",
        User: "User",
        Attachments: "Attachments",
        Comments: "Comments",
        TransactionApproved: "Order approved",
        ChargebackRequested: "Chargeback requested",
        ChargebackApproved: "Chargeback approved",
        ChargebackPaid: "Chargeback paid",
        ShowMore: "Show more",
        ShowLess: "Show less"
      },
      Items: {
        title: "Items",
        Breakdown: {
          title: "Breakdown",
          Total: "Total",
          Categories: "Categories"
        }
      },
      Payment: {
        title: "Payment",
        Device: {
          title: "Device",
          IPAddress: "IP Address"
        },
        PaymentType: {
          Name: "Name",
          Number: "Number",
          Amount: "Amount"
        }
      },
      Contacts: {
        title: "Contacts",
        Billing: {
          title: "Billing"
        },
        Phone: "Phone",
        Address: "Address",
        Shipping: {
          title: "Shipping"
        },
        ViewMap: "View map"
      }
    }
  }
}
