import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mr-5 d-flex align-center" }

import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import { ref, watch } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CardItemDesktop',
  props: /*@__PURE__*/_mergeModels({
    selectable: { type: Boolean },
    columns: {},
    icon: {},
    actions: { type: Boolean },
    item: {}
  }, {
    "selected": { type: Boolean },
    "selectedModifiers": {},
  }),
  emits: ["update:selected"],
  setup(__props: any) {



const modelSelected = _useModel<boolean>(__props, "selected");

const isHovering = ref(false);

const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};

const selectedBackgroundClass = {
  light: "bg-cs_ref_palette_information95",
  dark: "bg-cs_ref_palette_information30",
};

const className = useClassByTheme(classTheme);
const backgroundName = useClassByTheme(selectedBackgroundClass);

function handleMouseEnter() {
  isHovering.value = true;
}

function handleMouseLeave() {
  if (!modelSelected.value) isHovering.value = false;
}

watch(
  modelSelected,
  (value) => {
    if (value) {
      isHovering.value = true;
      return;
    }
    isHovering.value = false;
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    id: "card-item",
    class: _normalizeClass(["elevation-1 pr-4 pl-2 py-2 bg-cs_background rounded-pill d-flex align-center justify-space-between", [modelSelected.value ? _unref(backgroundName) : _unref(className)]]),
    onMouseover: handleMouseEnter,
    onMouseleave: handleMouseLeave
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "iconOrCheckbox", {
          data: { item: _ctx.item, isHovering: isHovering.value, selectable: _ctx.selectable }
        })
      ]),
      _createElementVNode("div", {
        class: "flex-grow-1",
        style: _normalizeStyle([{"display":"grid","grid-template-rows":"32px","align-items":"center"}, `grid-template-columns: repeat(${_ctx.columns}, 1fr)`])
      }, [
        _renderSlot(_ctx.$slots, "default", {
          data: { item: _ctx.item, isHovering: isHovering.value, isSelected: modelSelected.value, actions: _ctx.actions }
        })
      ], 4)
    ]),
    _: 3
  }, 8, ["class"]))
}
}

})