<template>
  <div :style="{ padding: `${mdAndUp ? '8px 0' : '8px 16px'}` }">
    <div
      :style="{
        height: mdAndUp ? '220px' : '120px',
      }"
    >
      <CustomHero
        class="d-flex align-end justify-space-between"
        :label="t(label.TransactionDetailsPage.title)"
        :src-image="srcImage"
      />
    </div>
    <TabsSwitch
      :options="tabsSwitchOptions"
      @click="(id: DetailTransactionTabs) => (currentTab = id)"
    />
    <div
      style="
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr minmax(100px, 600px) 1fr;
        justify-content: space-evenly;
        padding-top: 10px;
      "
    >
      <div style="grid-column: 2 / 3">
        <TransactionSummary
          v-if="currentTab == DetailTransactionTabs.Summary"
          :summary="transactionDetails?.summary"
        />
        <TransactionHistory
          v-if="currentTab == DetailTransactionTabs.History"
          :history="transactionDetails?.history"
        />
        <TransactionItems
          v-if="currentTab == DetailTransactionTabs.Items"
          :items="transactionDetails?.items"
        />
        <TransactionPayments
          v-if="currentTab == DetailTransactionTabs.Payment"
          :device="transactionDetails?.device"
          :payments="transactionDetails?.payments ?? []"
        />
        <TransactionContacts
          v-if="currentTab == DetailTransactionTabs.Contacts"
          :billing="transactionDetails.contacts?.billing"
          :shipping="transactionDetails.contacts?.shipping"
        />
      </div>
      <ActionDetails
        style="justify-self: flex-end"
        :options="actionDetailsOptions"
        @click="(id: DetailTransactionTabs) => console.log(id)"
      />
    </div>
  </div>
  <div
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 0px;
    "
  >
    <img
      style="width: 24px"
      :src="imgClearsaleOptions.src"
      :alt="imgClearsaleOptions.alt"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed, reactive, watch } from "vue";
import { useDisplay, useTheme } from "vuetify";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

import ActionDetails from "@/components/one/ActionDetailsComponent/ActionDetails.vue";
import CustomHero from "@/components/shared/CustomHero/CustomHero.vue";
import TabsSwitch from "@/components/one/TabsSwitchComponent/TabsSwitch.vue";
import TransactionSummary from "@/components/transactions/details/TransactionSummary/TransactionSummary.vue";
import TransactionHistory from "@/components/transactions/details/TransactionHistory/TransactionHistory.vue";
import TransactionItems from "@/components/transactions/details/TransactionItems/TransactionItems.vue";
import TransactionPayments from "@/components/transactions/details/TransactionPayments/TransactionPayments.vue";
import TransactionContacts from "@/components/transactions/details/TransactionDetailsContacts/TransactionDetailsContacts.vue";

import imgDesktopLight from "@/assets/backgrounds/transaction-desktop-light.png";
import imgDesktopDark from "@/assets/backgrounds/transaction-desktop-dark.png";
import imgClearsale from "@/assets/logo_mobile.png";

import { DetailTransactionTabs } from "@/model/transaction";
import { TabsSwitchItem } from "@/model/tabSwitch";
import { ActionItem, ActionTransactionType } from "@/model/actionsDetails";

import {
  ITransactionDetails,
  getTransactionDetailAPIGET,
} from "@/api/resources/Transactions/transactionResource";

import { label } from "@/plugins/locales/global";
import { useTransactionStore } from "../store/TransactionStore";

const { t } = useI18n();
const { mdAndUp } = useDisplay();
const { name } = useTheme();
const route = useRoute();

const currentTab = ref(DetailTransactionTabs.Summary);

const transactionStore = useTransactionStore();

const imgClearsaleOptions = ref({
  src: imgClearsale,
  alt: "Logo Clear Sale",
});

const transactionDetails = reactive<Partial<ITransactionDetails>>({});

const tabsSwitchOptions: TabsSwitchItem<DetailTransactionTabs>[] = reactive([
  {
    id: DetailTransactionTabs.Summary,
    label: label.TransactionDetailsPage.DetailTransactionTabs.Summary.title,
    active: false,
  },
  {
    id: DetailTransactionTabs.History,
    label: label.TransactionDetailsPage.DetailTransactionTabs.History.title,
    active: false,
  },
  {
    id: DetailTransactionTabs.Items,
    label: label.TransactionDetailsPage.DetailTransactionTabs.Items.title,
    active: false,
  },
  {
    id: DetailTransactionTabs.Payment,
    label: label.TransactionDetailsPage.DetailTransactionTabs.Payment.title,
    active: false,
  },
  {
    id: DetailTransactionTabs.Contacts,
    label: label.TransactionDetailsPage.DetailTransactionTabs.Contacts.title,
    active: false,
  },
]);

onMounted(async () => {
  const { transactionId } = route.params;
  fetchTransactionDetails(transactionId);
});

const srcImage = computed(() => {
  if (name.value == "light") {
    return imgDesktopLight;
  } else {
    return imgDesktopDark;
  }
});

watch(
  currentTab,
  (newValue) => {
    tabsSwitchOptions.forEach(
      (v) => (v.active = v.id == newValue ? true : false)
    );
  },
  { immediate: true }
);

const actionDetailsOptions: ActionItem<ActionTransactionType>[] = [
  {
    id: ActionTransactionType.ForceApproval,
    icon: "mdi-check",
  },
  {
    id: ActionTransactionType.Reanalyze,
    icon: "mdi-repeat",
  },
  {
    id: ActionTransactionType.Prioritize,
    icon: "mdi-priority-high",
  },
  {
    id: ActionTransactionType.RequestChargeback,
    icon: "mdi-rotate-left",
  },
  {
    id: ActionTransactionType.CancelOrder,
    icon: "mdi-square",
  },
];

async function fetchTransactionDetails(transactionId: string) {
  await getTransactionDetailAPIGET(transactionId).then((data) => {
    transactionDetails.summary = data.summary;
    transactionDetails.contacts = data.contacts;
    transactionDetails.history = data.history;
    transactionDetails.items = data.items;
    transactionDetails.device = data.device;
    transactionDetails.payments = data.payments;
  });
}
</script>
