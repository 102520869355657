<template>
  <div
    :style="{
      position: 'sticky',
      top: 0,
      width: '100%',
    }"
    class="bg-cs_background"
    :data-testid="
      generateTestId(ContainerFixedTopElements.ContainerFixedTop, name)
    "
    ref="containerFixedTop"
  >
    <div
      :data-testid="
        generateTestId(ContainerFixedTopElements.ContainerFixedTopContent, name)
      "
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { generateTestId } from "@/helpers/generateTestId";
import { ContainerFixedTopElements } from "./constants/ContainerFixedTopId";
import { useContainerFixedTop } from "./useContainerFixedTop";
import { defineEmits } from "vue";

defineProps<{ name?: string }>();

const emit = defineEmits<{
  (e: "handleTop", isTop: boolean): void;
}>();

const { containerFixedTop } = useContainerFixedTop(emit);
</script>
