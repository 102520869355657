import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted, reactive, ref, watch } from "vue";
import { useTheme } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { ActionItem, ActionTransactionType } from "@/model/actionsDetails";
import gsap from "@/plugins/gsap";

import {
  ActionDetailsMobileMainButton,
  ActionDetailsMobileOptionButtonContainer,
  ActionDetailsMobileOptionButtonLeft,
  ActionDetailsMobileOptionButtonRight,
} from "./constants/ActionDetailsMobileIds";
import { useStyleByTheme } from "@/components/hooks/useStyleByTheme";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";

const ACTION_BUTTON_HEIGHT = 80; //actual button height + bottom from position-fixed;


export default /*@__PURE__*/_defineComponent({
  __name: 'ActionDetailsMobile',
  props: {
    options: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();
const props = __props;
const emits = __emit;

const { name: themeName } = useTheme();

const refOverlay = ref<HTMLElement | undefined>(undefined);
const actionDetailsButtons: Element[] = reactive([]);
const toggle = ref(false);

const overlayStyleAccordingTheme = useStyleByTheme({
  light: { backgroundColor: "rgba(var(--v-ref-palette-neutral10), 0.5)" },
  dark: { backgroundColor: "rgba(0, 0, 0, 0.4)" },
});

const buttonStyle = {
  light: {
    backgroundColor: "rgb(var(--v-ref-palette-information95))",
    color: "rgb(var(--v-ref-palette-tertiary40))",
  },
  dark: {
    backgroundColor: "rgb(var(--v-ref-palette-information30))",
    color: "rgb(var(--v-ref-palette-tertiary80))",
  },
};

const buttonStyleAccordingTheme = useStyleByTheme(buttonStyle);

watch(
  () => props.options,
  (newOptions, oldOptions) => {
    actionDetailsButtons.splice(0, actionDetailsButtons.length);
    actionDetailsButtons.push(
      ...gsap.utils.toArray<Element>(
        `#${ActionDetailsMobileOptionButtonContainer}`
      )
    );
  }
);

onMounted(() => {
  actionDetailsButtons.push(
    ...gsap.utils.toArray<Element>(
      `#${ActionDetailsMobileOptionButtonContainer}`
    )
  );

  gsap.set(actionDetailsButtons, { autoAlpha: 0 });
});

function emitAndRipple(id: number) {
  emits("click", id);
  animate();
}

function animate() {
  toggle.value = !toggle.value;

  gsap.to(actionDetailsButtons, {
    bottom: (i) => (toggle.value ? 50 * i + ACTION_BUTTON_HEIGHT : 0),
    ease: "back.out(1)",
    autoAlpha: +toggle.value,
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      ref_key: "refOverlay",
      ref: refOverlay,
      class: "position-fixed",
      style: _normalizeStyle([{"top":"0","bottom":"0","left":"0","right":"0","height":"100%","width":"100%","overflow":"hidden","z-index":"999"}, {
        ..._unref(overlayStyleAccordingTheme),
        display: toggle.value ? 'initial' : 'none',
      }]),
      onClick: animate
    }, null, 4)
  ]))
}
}

})