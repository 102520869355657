import Hammer from "hammerjs";
import { Ref } from "vue";

export function useContainerSwiper() {
  const initializeHammer = (element: HTMLDivElement) => {
    const hammer = new Hammer(element);
    hammer.get("pan").set({ direction: Hammer.DIRECTION_HORIZONTAL });
    return hammer;
  };

  const handlePanScrollStart = (scrollLeft: number) => {
    return scrollLeft ?? 0;
  };


  const calculateResult = (deltaX: number, initialScroll: number): number => {
    return initialScroll - deltaX;
  };

  const validateResult = (result: number): number => {
    if (result <= 0) return 0;


    return result;
  };

  const validateResultGtScrollWidth = (result: number, containerSwipeRef: Ref): number => {
    if (containerSwipeRef.value && result > containerSwipeRef.value.scrollWidth) {
      return containerSwipeRef.value.scrollWidth;
    }

    return result;
  }

  const calculateDeltaX = (deltaX: number, initialScroll: number, containerSwipeRef: Ref) => {
    return validateResultGtScrollWidth(
      validateResult(
        calculateResult(deltaX, initialScroll)), containerSwipeRef);
  };

  const scrollContainer = (deltaX: number, containerSwipeRef: Ref) => {
    containerSwipeRef.value.scroll({
      left: deltaX,
      behavior: "smooth",
    });
  };


  const handlePanMove = (e: globalThis.HammerInput, initialScroll: number, containerSwipeRef: Ref) => {
    const deltaX = calculateDeltaX(
      e.deltaX,
      initialScroll,
      containerSwipeRef
    );
    if (containerSwipeRef.value) {
      scrollContainer(deltaX, containerSwipeRef);
    }
  };

  const startEvent = (initialScroll: number, containerSwipeRef: Ref) => {
    if (containerSwipeRef.value) {
      const containerSwipeHammer = initializeHammer(containerSwipeRef.value);

      containerSwipeHammer.on("panstart", () => {
        initialScroll = handlePanScrollStart(
          containerSwipeRef.value.scrollLeft
        );
      });

      containerSwipeHammer.on("panmove", (e) =>
        handlePanMove(e, initialScroll, containerSwipeRef)
      );
    }
  }

  return { initializeHammer, startEvent, handlePanMove, handlePanScrollStart, calculateResult, validateResult, validateResultGtScrollWidth, calculateDeltaX, scrollContainer }
}
