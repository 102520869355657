<template>
  <TransactionDetails_Contacts_Desktop
    v-if="mdAndUp"
    :billing="billing"
    :shipping="shipping"
  />
  <TransactionDetails_Contacts_Mobile
    v-else
    :billing="billing"
    :shipping="shipping"
  />
</template>
<script setup lang="ts">
import TransactionDetails_Contacts_Desktop from "@/components/shared/TransactionDetailsContactsContentDesktop/TransactionDetailsContactsContentDesktop.vue";
import TransactionDetails_Contacts_Mobile from "@/components/shared/TransactionDetailsContactsContentMobile/TransactionDetailsContactsContentMobile.vue";
import { TransactionDetailsContactsProps } from "@/model/transactionDetailsContacts";
import { useDisplay } from "vuetify";

defineProps<TransactionDetailsContactsProps>();

const { mdAndUp } = useDisplay();
</script>
