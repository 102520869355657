import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import TabsSwitch from "@/components/shared/TabsSwitch/TabsSwitch.vue";
import WidthScrollContainer from "@/components/shared/WidthScrollContainer/WidthScrollContainer.vue";

import { TabsSwitchItem } from "@/model/tabSwitch";
import { DetailTransactionTabs } from "@/model/transaction";
import { ref } from "vue";

import { routeNames } from "@/router";
import { useStyleByTheme } from "@/components/hooks/useStyleByTheme";


export default /*@__PURE__*/_defineComponent({
  __name: 'TabsSwitchComponentDesktop',
  props: {
    options: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const scrollContainerHeight = { from: "48px", to: "40px" };

const containerClass = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};

const returnButtonStyle = {
  light: {
    backgroundColor: "rgba(var(--v-ref-palette-neutral95))",
    color: "rgba(var(--v-ref-palette-neutral-variant30))",
  },
  dark: {
    backgroundColor: "rgba(var(--v-theme-surface-container-high))",
    color: "rgba(var(--v-theme-on-surface-variant))",
  },
};


const emits = __emit;

const isOnTop = ref(false);

const computedContainerClass = useClassByTheme(containerClass);
const computedReturnButtonStyle = useStyleByTheme(returnButtonStyle);

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(WidthScrollContainer, {
    width: "600px",
    height: scrollContainerHeight.to,
    onScrollOnTop: _cache[1] || (_cache[1] = (onTop) => (isOnTop.value = onTop))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["d-flex ga-3 pt-1 rounded-b-xl", _unref(computedContainerClass)])
      }, [
        _createVNode(_component_v_btn, {
          variant: "flat",
          density: "comfortable",
          size: isOnTop.value ? scrollContainerHeight.to : scrollContainerHeight.from,
          style: _normalizeStyle([{"transition":"width ease-in-out 0.3s, height ease-in-out 0.3s"}, _unref(computedReturnButtonStyle)]),
          color: "",
          icon: "mdi-arrow-left",
          to: { name: _unref(routeNames).transactionView }
        }, null, 8, ["size", "style", "to"]),
        _createVNode(TabsSwitch, {
          options: _ctx.options,
          onSelected: _cache[0] || (_cache[0] = (id) => emits('click', id))
        }, null, 8, ["options"])
      ], 2)
    ]),
    _: 1
  }, 8, ["height"]))
}
}

})