<template>
  <div :data-testid="generateTestId(ButtonFilterStatusElements.Content)">
    <ButtonFilter
      :label="t(label.ButtonFilter.Status)"
      @clear-filter="handleClearAll"
      @submit-filter="handleSubmitStatus"
      @close-filter="handleCloseFilter"
      :active="(modelStatus ?? []).length > 0"
    >
      <ChipSelectable :chips="status" v-model="modelStatus" multiple />
    </ButtonFilter>
  </div>
</template>

<script setup lang="ts">
import ButtonFilter from "@/components/shared/ButtonFilter/ButtonFilter.vue";
import { generateTestId } from "@/helpers/generateTestId";
import ChipSelectable from "../ChipSelectable/ChipSelectable.vue";
import { ButtonFilterStatusElements } from "./constants/ButtonFilterStatusElements";
import { useButtonFilterStatus } from "./useButtonFilterStatus";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";

const { t } = useI18n();
const {
  status,
  handleClearAll,
  handleSubmitStatus,
  handleCloseFilter,
  modelStatus,
} = useButtonFilterStatus();
</script>
