import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"


import { generateTestId } from "@/helpers/generateTestId";
import { Chip } from "@/model/chip";
import { ChipSelectableElements } from "./constants/ChipSelectableElements";
import {
  SelectChipEmits,
  useSelectChip,
} from "./constants/ChipSelectableEvents";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'ChipSelectable',
  props: /*@__PURE__*/_mergeModels({
    chips: {},
    multiple: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["selectChip"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();


const model = _useModel(__props, "modelValue");

const emit = __emit;

const { handleSelectChip } = useSelectChip(emit);

return (_ctx: any,_cache: any) => {
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_chip_group = _resolveComponent("v-chip-group")!

  return (_openBlock(), _createBlock(_component_v_chip_group, {
    "data-testid": _unref(generateTestId)(_unref(ChipSelectableElements).ChipGroupContent),
    "selected-class": "bg-secondary-container text-shadow border-none",
    modelValue: model.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
    multiple: _ctx.multiple,
    column: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass({ 'd-flex ga-4': !_ctx.isMobile })
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chips, (chip, i) => {
          return (_openBlock(), _createBlock(_component_v_chip, {
            "data-testid": 
          _unref(generateTestId)(
            (_unref(ChipSelectableElements).ChipGroupContent,
            `${_unref(ChipSelectableElements).ChipSelectableContent}-${chip.chipId}`)
          )
        ,
            class: _normalizeClass(["rounded-lg on-surface-variant", { 'ma-0': !_ctx.isMobile }]),
            variant: "outlined",
            style: {"font-weight":"bold"},
            value: chip,
            key: chip.chipId,
            filter: "",
            "filter-icon": _unref(ChipSelectableElements).ChipSelectedIcon,
            onClick: ($event: any) => (_unref(handleSelectChip)(chip, i))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)(chip.label)), 1)
            ]),
            _: 2
          }, 1032, ["data-testid", "class", "value", "filter-icon", "onClick"]))
        }), 128))
      ], 2)
    ]),
    _: 1
  }, 8, ["data-testid", "modelValue", "multiple"]))
}
}

})