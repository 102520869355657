import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-end" }
const _hoisted_2 = {
  key: 0,
  class: "header d-flex justify-space-between mb-2"
}
const _hoisted_3 = { class: "content d-flex align-start" }
const _hoisted_4 = { class: "mr-4" }

import { onMounted, ref } from "vue";
import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import { swipeUtility } from "@/utils/swipeUtility";


export default /*@__PURE__*/_defineComponent({
  __name: 'CardItemMobile',
  props: /*@__PURE__*/_mergeModels({
    header: { type: Boolean, default: true },
    selectable: { type: Boolean },
    columns: {},
    actions: { type: Boolean },
    icon: {},
    item: {}
  }, {
    "selected": { type: Boolean },
    "selectedModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["select"], ["update:selected"]),
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;

const modelSelected = _useModel<boolean>(__props, "selected");

const cardItemRef = ref<HTMLElement | null>(null);
const hiddenContent = ref<HTMLElement | null>(null);

const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};
const className = useClassByTheme(classTheme);

function activeSelected(value: boolean) {
  if (props.selectable) modelSelected.value = value;
}

function handleSelectItem() {
  emits("select", props.item);
}

onMounted(() => {
  if (props.actions && cardItemRef.value && hiddenContent.value) {
    let swipe = swipeUtility(cardItemRef.value, {
      leftElement: hiddenContent.value,
      rightElement: cardItemRef.value,
      type: "LEFT",
    });

    // if (mobile && props.active) {
    //   swipe.hammer.destroy();
    //   return;
    // }
    swipe.onLoadEvents(swipe.hammer);
  }
});

return (_ctx: any,_cache: any) => {
  const _component_v_card = _resolveComponent("v-card")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_component_v_card, {
    id: "card-item",
    class: "position-relative rounded-xl",
    elevation: "1",
    onClick: _withModifiers(handleSelectItem, ["stop"])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "pa-4 bg-cs_ref_palette_information95" }, null, -1)),
        _createElementVNode("div", {
          ref_key: "hiddenContent",
          ref: hiddenContent,
          class: "pa-4 bg-cs_ref_palette_information95",
          style: _normalizeStyle({
          height: cardItemRef.value?.getBoundingClientRect().height + 'px',
          borderRadius: '0px 24px 24px 0px',
        })
        }, [
          _renderSlot(_ctx.$slots, "back", {
            data: { item: _ctx.item }
          })
        ], 4)
      ]),
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["position-absolute top-0 left-0 right-0 pa-4 elevation-1 rounded-xl", modelSelected.value ? 'bg-cs_ref_palette_information95' : _unref(className)]),
        ref_key: "cardItemRef",
        ref: cardItemRef
      }, [
        (_ctx.header)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "header", {
                data: { item: _ctx.item }
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "icon", {
              data: { selectable: _ctx.selectable, item: _ctx.item, activeSelected: activeSelected }
            })
          ]),
          _createElementVNode("div", {
            class: "flex-grow-1",
            style: _normalizeStyle(`display: grid; grid-template-columns: repeat(${
            _ctx.columns ?? 2
          }, 1fr); align-items:center; `)
          }, [
            _renderSlot(_ctx.$slots, "default", {
              data: { item: _ctx.item }
            })
          ], 4)
        ])
      ], 2)), [
        [_directive_ripple]
      ])
    ]),
    _: 3
  }))
}
}

})