import { computed, ref, watch } from "vue";
import { useTransactionStore } from "@/store/TransactionStore";

import { Chip } from "@/model/chip";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";

export enum TimePeriodEnum {
  ONE_WEEK = 0,
  MTD = 1,
  ONE_MONTH = 2,
  THREE_MONTHS = 3,
  SIX_MONTHS = 4,
  ONE_YEAR = 5,
  CUSTOM = 6,
}

export type ButtonFilterDateMobileProps = { open: boolean };

export interface PayloadSubmitButtonFilter {
  chipId: TimePeriodEnum;
  label: string;
  date: Date[];
}


export function useButtonFilterDate() {
  const { t } = useI18n();

  const timePeriodOptions = [
    {
      chipId: TimePeriodEnum.ONE_WEEK,
      label: label.ButtonFilter.Date.Week,
    },
    {
      chipId: TimePeriodEnum.MTD,
      label: label.ButtonFilter.Date.MTD,
    },
    {
      chipId: TimePeriodEnum.ONE_MONTH,
      label: label.ButtonFilter.Date.Month,
    },
    {
      chipId: TimePeriodEnum.THREE_MONTHS,
      label: label.ButtonFilter.Date.ThreeMonths,
    },
    {
      chipId: TimePeriodEnum.SIX_MONTHS,
      label: label.ButtonFilter.Date.SixMonths,
    },
    {
      chipId: TimePeriodEnum.ONE_YEAR,
      label: label.ButtonFilter.Date.OneYear,
    },
    {
      chipId: TimePeriodEnum.CUSTOM,
      label: label.ButtonFilter.BottomSheet.Date.CustomPeriod,
    },
  ];
  const actualDate = new Date();

  const dialogModel = ref(false);
  const datePickerModel = ref<Date[]>([]);

  const dateChips = ref<Date[]>([new Date(), actualDate]);

  const selectedTimePeriodMemory = ref<Chip | null>();
  const selectedTimePeriod = ref<Chip | null>();
  const selectedTimePeriodOld = ref<Chip | null>();

  const isDatePickerCancelledWithoutSelection = ref(false);

  const transactionStore = useTransactionStore();

  const isFilterActive = computed(() => !!selectedTimePeriod.value)

  function getStartDateForPeriod(
    period: TimePeriodEnum,
    currentDate: Date
  ): Date {
    const startDate = new Date(currentDate);

    switch (period) {
      case TimePeriodEnum.ONE_WEEK:
        startDate.setDate(startDate.getDate() - 8);
        break;
      case TimePeriodEnum.MTD:
        startDate.setDate(1);
        break;
      case TimePeriodEnum.ONE_MONTH:
        startDate.setMonth(startDate.getMonth() - 1);
        break;
      case TimePeriodEnum.THREE_MONTHS:
        startDate.setMonth(startDate.getMonth() - 3);
        break;
      case TimePeriodEnum.SIX_MONTHS:
        startDate.setMonth(startDate.getMonth() - 6);
        break;
      case TimePeriodEnum.ONE_YEAR:
        startDate.setFullYear(startDate.getFullYear() - 1);
        break;
    }

    return startDate;
  }

  function submitDatePicker(date: Date[]) {
    datePickerModel.value = date;
    dialogModel.value = false;
  }

  function cancelDatePicker() {
    dialogModel.value = false;

    cancelWithoutDateSelected();
  }

  function cancelWithoutDateSelected() {
    if (!datePickerModel.value.length) {
      isDatePickerCancelledWithoutSelection.value = true;
      selectOldTimePeriod();
      return;
    }

    isDatePickerCancelledWithoutSelection.value = false;
  }

  function selectOldTimePeriod() {
    if (selectedTimePeriodOld.value) {
      selectedTimePeriodMemory.value = selectedTimePeriodOld.value;
      return;
    }
    selectedTimePeriodMemory.value = null;
  }

  function clearChipsWhenNotSelectedDate() {
    if (
      isDatePickerCancelledWithoutSelection.value &&
      !selectedTimePeriodMemory.value
    ) {
      selectedTimePeriod.value = null;
      selectedTimePeriodMemory.value = null;
      isDatePickerCancelledWithoutSelection.value = false;
      return;
    }

    dialogModel.value = true;
  }

  function selectOnlyOneChip() {
    if (selectedTimePeriodMemory.value === undefined) {
      selectedTimePeriodMemory.value = selectedTimePeriod.value;
    }
    selectedTimePeriod.value = selectedTimePeriodMemory.value;
  }

  function selectOldTimePeriodByWatch(oldValue: Chip | null | undefined) {
    selectedTimePeriodOld.value = oldValue;
  }

  function selectCustomChip() {
    if (
      selectedTimePeriod.value &&
      selectedTimePeriod.value.chipId === TimePeriodEnum.CUSTOM
    ) {
      clearChipsWhenNotSelectedDate();
    }
  }

  function selectChip() {
    if (
      selectedTimePeriod.value &&
      selectedTimePeriod.value.chipId !== TimePeriodEnum.CUSTOM
    ) {
      dateChips.value[0] = getStartDateForPeriod(
        selectedTimePeriod.value?.chipId as TimePeriodEnum,
        actualDate
      );
    }
  }

  function submitButtonFilter() {
    if (!selectedTimePeriod.value) {
      return;
    }

    if (selectedTimePeriod.value.chipId !== TimePeriodEnum.CUSTOM) {
      const [dateFrom, dateTo] = dateChips.value;

      resetDatepickerUponSubmit();

      transactionStore.$patch({
        filters: { dateFrom, dateTo },
      });
      transactionStore.$patch({ paging: { page: 1, limit: 20 } });
      return;
    }

    transactionStore.$patch({
      filters: {
        dateFrom: datePickerModel.value[0],
        dateTo: datePickerModel.value[datePickerModel.value.length - 1],
      },
    });
    transactionStore.$patch({ paging: { page: 1, limit: 20 } });
  }

  function resetDatepickerUponSubmit() {
    if (selectedTimePeriodOld.value?.chipId === TimePeriodEnum.CUSTOM) {
      datePickerModel.value = [];
    }
  }

  function clearAllFilter() {
    selectedTimePeriod.value = null;
    selectedTimePeriodMemory.value = null;
    selectedTimePeriodOld.value = null;
    datePickerModel.value = [];
    dateChips.value = [new Date(), actualDate];
    dialogModel.value = false;
    isDatePickerCancelledWithoutSelection.value = false;

    transactionStore.$patch({ filters: { dateFrom: null, dateTo: null } });
    transactionStore.$patch({ paging: { page: 1, limit: 20 } });

  }

  watch(selectedTimePeriodMemory, (_, oldValue) => {
    selectOldTimePeriodByWatch(oldValue);
    selectOnlyOneChip();
    selectChip();
    selectCustomChip();
  });

  return {
    isFilterActive,
    timePeriodOptions,
    selectedTimePeriodMemory,
    submitButtonFilter,
    clearAllFilter,
    dialogModel,
    datePickerModel,
    cancelDatePicker,
    submitDatePicker,
  };
}
