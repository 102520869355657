<template>
  <ButtonFilterText
    v-model:input="inputValue"
    :options="suggestionBoxListFiltered"
    :percent-limit="70"
    :loadingSuggestions="loadingSuggestionBox"
    :label="t(label.ButtonFilter.ID.title)"
    :placeholder="t(label.ButtonFilter.ID.placeholder)"
    :show-options="suggestionBoxListFiltered.length > 0"
    @submit="
      (value) => {
        $patch({ filters: { codes: value.map((chip) => chip.value) } });
        $patch({ page: 1, offset: 20 });
      }
    "
    @clear="
      () => {
        $patch({ filters: { codes: [] } });
        $patch({ page: 1, offset: 20 });
      }
    "
    name="filter-id"
  />
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useDebounceFn } from "@vueuse/core";

import ButtonFilterText from "@/components/shared/ButtonFilterText/ButtonFilterText.vue";
import {
  getSuggestionAPIPOST,
  SuggestionItem,
} from "@/api/resources/Transactions/transactionResource";
import { useTransactionStore } from "@/store/TransactionStore";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";

const TIME_DEBOUNCE_INPUT_VALUE = 500;

const inputValue = ref("");
const suggestionBoxListFiltered = ref<SuggestionItem[]>([]);
const loadingSuggestionBox = ref(false);

const { t } = useI18n();
const { $patch } = useTransactionStore();

const debounceInputValue = useDebounceFn((value: string) => {
  getSuggestionAPIPOST({ type: 1, limit: 10, value })
    .then((data) => {
      suggestionBoxListFiltered.value = data.result;
    })
    .finally(() => {
      loadingSuggestionBox.value = false;
    });
}, TIME_DEBOUNCE_INPUT_VALUE);

watch(inputValue, (value) => {
  loadingSuggestionBox.value = true;
  debounceInputValue(value);
});
</script>
