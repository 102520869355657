import { ITransactionStatus } from "@/api/resources/Transactions/transactionResource";
import { CSSProperties, StyleValue, computed } from "vue";
import { useTheme } from "vuetify";

interface UseStyleByThemeProps {
  light: CSSProperties;
  dark: CSSProperties;
}

export function useStyleByTheme({ dark, light }: UseStyleByThemeProps) {
  const { name } = useTheme();

  return computed(() => {
    const styleByTheme = {
      light,
      dark
    };

    return styleByTheme[name.value as keyof typeof styleByTheme]; // Return the variable or use it in a function call
  });
}
