import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "overflow-y-auto",
  style: {"height":"120px"}
}


import { generateTestId } from "@/helpers/generateTestId";
import { Chip } from "@/model/chip";
import { ChipSelectableElements } from "@/components/shared/ChipSelectable/constants/ChipSelectableElements";
import {
  SelectChipEmits,
  useSelectChip,
} from "@/components/shared/ChipSelectable/constants/ChipSelectableEvents";
import { useClassByTheme } from "@/components/hooks/useClassByTheme";


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonFilterTextMobileSuggestionBox',
  props: /*@__PURE__*/_mergeModels({
    chips: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["selectChip"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {



const model = _useModel(__props, "modelValue");

const emits = __emit;

const { handleSelectChip } = useSelectChip(emits);
const classSelectedByTheme = useClassByTheme({
  light: "text-shadow",
  dark: "",
});

return (_ctx: any,_cache: any) => {
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_chip_group = _resolveComponent("v-chip-group")!

  return (_openBlock(), _createBlock(_component_v_chip_group, {
    "data-testid": _unref(generateTestId)(_unref(ChipSelectableElements).ChipGroupContent),
    "selected-class": `bg-secondary-container border-none ${_unref(classSelectedByTheme)}`,
    modelValue: model.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
    multiple: "",
    column: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chips, (chip, index) => {
          return (_openBlock(), _createBlock(_component_v_chip, {
            "data-testid": 
          _unref(generateTestId)(
            (_unref(ChipSelectableElements).ChipGroupContent,
            `${_unref(ChipSelectableElements).ChipSelectableContent}-${chip.chipId}`)
          )
        ,
            class: "rounded-lg on-surface-variant",
            variant: "outlined",
            style: {"font-weight":"bold"},
            value: chip,
            key: chip.chipId,
            filter: "",
            "filter-icon": _unref(ChipSelectableElements).ChipSelectedIcon,
            onClick: ($event: any) => (_unref(handleSelectChip)(chip, index))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(chip.label), 1)
            ]),
            _: 2
          }, 1032, ["data-testid", "value", "filter-icon", "onClick"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["data-testid", "selected-class", "modelValue"]))
}
}

})