import { Ref, toValue } from "vue";
import * as btnClasses from "../../ButtonFilter/constants/ButtonFilterClasses";
import gsap, { GsapTimeline } from "@/plugins/gsap";
import { ButtonFilterContainerAnimationContext } from "../constants/ButtonFilterContainer";

export type UseButtonFilterContainer = {
  buildAnimationContext: (
    refContainer: Ref<Element>
  ) => ButtonFilterContainerAnimationContext;
};

export function useButtonFilterContainer(): UseButtonFilterContainer {
  function buildAnimationContext(refContainer: Ref<Element>) {
    const ctx = gsap.context((self) => {
      const buttonFilters = gsap.utils.toArray<Element>(
        `.${btnClasses.ButtonFilterBaseClass}`
      );

      const buttonFilterSizes: { width: number; height: number }[] =
        buttonFilters.map((f) => ({
          width: gsap.getProperty(f, "width") as number,
          height: gsap.getProperty(f, "height") as number,
        }));

      gsap.to(buttonFilters.slice(1), { marginLeft: 8 });

      self.add("hideOtherFilters", (target: Element) => {
        const tl: GsapTimeline = gsap.timeline({
          defaults: { duration: 0.3 },
        });
        const SPACE_FOR_CLOSE_BUTTON_IN_PIXELS = 68;

        const btnSelectedIndex = buttonFilters.indexOf(target);
        const totalWidth = toValue(refContainer)
          ? (gsap.getProperty(toValue(refContainer), "width") as number) -
            SPACE_FOR_CLOSE_BUTTON_IN_PIXELS
          : 0;

        const leftFilters = buttonFilters.slice(0, btnSelectedIndex);
        const rightFilters = buttonFilters.slice(
          btnSelectedIndex + 1,
          buttonFilters.length
        );

        const moveAround = (xValue: number) => ({ x: xValue, autoAlpha: 0 });

        if (leftFilters.length > 0 || rightFilters.length > 0) {
          if (btnSelectedIndex == 0) {
            tl.to(rightFilters, moveAround(100));
            tl.set(rightFilters, { width: 0, height: 0 });
          } else if (btnSelectedIndex == buttonFilters.length - 1) {
            tl.to(leftFilters, moveAround(-100));
            tl.to(leftFilters, { width: 0, height: 0 });
          } else {
            tl.to(leftFilters, moveAround(-100));
            tl.to(rightFilters, moveAround(100), "<");
            tl.to([leftFilters, rightFilters], { width: 0, height: 0 });
          }
        }

        if (buttonFilters.length > 1)
          tl.to(buttonFilters.slice(1), { marginLeft: 0 }, "<");

        tl.to(target, { width: totalWidth }, "<");

        return tl;
      });

      self.add("appearOtherFilters", (target: Element) => {
        const tl: GsapTimeline = gsap.timeline({
          defaults: { duration: 0.3 },
        });

        const btnSelectedIndex = buttonFilters.indexOf(target);

        const leftFilters = buttonFilters.slice(0, btnSelectedIndex);
        const rightFilters = buttonFilters.slice(
          btnSelectedIndex + 1,
          buttonFilters.length
        );

        tl.to(
          buttonFilters[btnSelectedIndex],
          { width: buttonFilterSizes[btnSelectedIndex].width },
          "<"
        );

        if (leftFilters.length > 0 || rightFilters.length > 0) {
          if (btnSelectedIndex == 0) {
            tl.to(buttonFilters.slice(1), { marginLeft: 8 });
            tl.set(rightFilters, {
              width: (i) => buttonFilterSizes[i + 1].width,
              height: 40,
            });
            tl.to(rightFilters, { x: 0, autoAlpha: 1 });
          } else if (btnSelectedIndex == buttonFilters.length - 1) {
            tl.to(buttonFilters.slice(1), { marginLeft: 8 });
            tl.to(leftFilters, { width: (i) => buttonFilterSizes[i].width });
            tl.to(leftFilters, { x: 0, autoAlpha: 1 });
          } else {
            const leftFiltersOffset = leftFilters.length + 1;
            tl.to(leftFilters, {
              width: (index) => buttonFilterSizes[index].width,
              height: 40,
            });
            tl.set(rightFilters, {
              width: (index) =>
                buttonFilterSizes[leftFiltersOffset + index].width,
              height: 40,
            });
            tl.to([leftFilters, rightFilters], { x: 0, autoAlpha: 1 });
            tl.to(buttonFilters.slice(1), { marginLeft: 8 }, "<");
          }
        }

        return tl;
      });
    }, toValue(refContainer)) as unknown;

    return ctx as ButtonFilterContainerAnimationContext;
  }

  return {
    buildAnimationContext,
  };
}
