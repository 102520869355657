import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderSlot as _renderSlot, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-testid"]

import BottomSheet from "@/components/shared/BottomSheet/BottomSheet.vue";
import {
  ButtonFilterMobileEmits,
  ButtonFilterMobileProps,
  useButtonFilterMobile,
} from "./hooks/useButtonFilterMobile";
import {
  ButtonFilterMobileId,
  ButtonFilterMobileClearButtonId,
  IconSize,
} from "./constants/ButtonFilterMobile";
import { generateTestId } from "@/helpers/generateTestId";


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonFilterMobile',
  props: {
    label: {},
    height: { default: 90 },
    active: { type: Boolean, default: false }
  },
  emits: ["closeFilter", "clearFilter", "openFilter"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;

const {
  activeBottomSheet,
  filterClasses,
  openBottomSheet,
  onCloseBottomSheet,
  clearFilter,
} = useButtonFilterMobile(props, emits);

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      "data-testid": _unref(generateTestId)(_unref(ButtonFilterMobileId)),
      class: _normalizeClass(_unref(filterClasses)),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(openBottomSheet) && _unref(openBottomSheet)(...args)))
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.label), 1),
      (!_ctx.active)
        ? (_openBlock(), _createBlock(_component_v_icon, {
            key: 0,
            icon: "mdi-chevron-down",
            size: _unref(IconSize)
          }, null, 8, ["size"]))
        : _createCommentVNode("", true),
      (_ctx.active)
        ? (_openBlock(), _createBlock(_component_v_icon, {
            key: 1,
            icon: "mdi-close",
            size: _unref(IconSize),
            "data-testid": _unref(generateTestId)(_unref(ButtonFilterMobileClearButtonId)),
            onClick: _withModifiers(_unref(clearFilter), ["stop"])
          }, null, 8, ["size", "data-testid", "onClick"]))
        : _createCommentVNode("", true)
    ], 10, _hoisted_1),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(BottomSheet, {
        active: _unref(activeBottomSheet),
        "onUpdate:active": _cache[1] || (_cache[1] = ($event: any) => (_isRef(activeBottomSheet) ? (activeBottomSheet).value = $event : null)),
        height: _ctx.height,
        onClosed: _unref(onCloseBottomSheet)
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["active", "height", "onClosed"])
    ]))
  ], 64))
}
}

})