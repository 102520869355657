import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-testid"]
const _hoisted_2 = { class: "on-surface-variant" }
const _hoisted_3 = { class: "elevation-1 rounded-xl pa-6" }

import { computed } from "vue";
import { useDisplay, useTheme } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { JoinedListItem } from "@/components/shared/JoinedList/useJoinedList";
import JoinedList from "@/components/shared/JoinedList/JoinedList.vue";
import { TransactionHistoryElements } from "./constants/TransactionHistoryElements";
import { ITransactionDetails_History } from "@/api/resources/Transactions/transactionResource";
import { label } from "@/plugins/locales/global";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionHistory',
  props: {
    history: {}
  },
  setup(__props: any) {

const props = __props;
const { t } = useI18n();
const { name: theme } = useTheme();
const { mdAndUp } = useDisplay();

const orderList = computed(() => {
  const list = [];

  list.push({
    label:
      label.TransactionDetailsPage.DetailTransactionTabs.History
        .TransactionReceived,
    classLabel: "default",
  });

  props.history?.map((item) => list.push(item));

  return list as JoinedListItem[];
});

const displaySizeClass = computed(() => (mdAndUp.value ? "md" : "sm"));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['order-history', displaySizeClass.value]),
    style: {"padding-bottom":"40px"},
    "data-testid": _unref(generateTestId)(_unref(TransactionHistoryElements).Id)
  }, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_unref(t)(_unref(label).TransactionDetailsPage.DetailTransactionTabs.History.title)), 1),
    _createElementVNode("section", _hoisted_3, [
      _createVNode(JoinedList, { list: orderList.value }, null, 8, ["list"])
    ])
  ], 10, _hoisted_1))
}
}

})