import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ComponentPreview from '../views/ComponentPreview.vue'
import TransactionView from '@/views/TransactionView.vue'
import TransactionDetailsView from '@/views/TransactionDetailsView.vue'


export const routeNames = {
  transactionView: 'ShieldView',
  transactionsDetails: 'transactionDetailView',
  preview: 'preview'
}


const routes: Array<RouteRecordRaw> = [
  {
    path: '/shield',
    component: { template: '', beforeCreate() { this.$router.push({ name: routeNames.transactionView }) } }
  },
  {
    path: '/shield/transactions',
    name: routeNames.transactionView,
    component: TransactionView,
  },
  {
    path: '/shield/transactions/:transactionId',
    name: routeNames.transactionsDetails,
    component: TransactionDetailsView
  },
  {
    path: '/shield/preview',
    name: routeNames.preview,
    component: ComponentPreview
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const params = {
  clientId: "257a0662-93ec-432d-a6e1-099926dfe3ec",
  authUrl: "https://authdev.clear.sale",
  redirectUri: "https://one-qa.clear.sale/shield",
  tenant: "ssoclearsaledev.onmicrosoft.com",
  userFlow: "B2C_1A_HRD_SIGNUP_SIGNIN",
  scopes: ["openid", "offline_access"],
  tokenName: 'shield/token',
  codeVerifierName: 'shield/code-verifier',
};

export default router
