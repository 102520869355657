import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"background-color":"white","min-height":"100vh","display":"flex","flex-direction":"column"}
}
const _hoisted_2 = {
  class: "bg-cs_background",
  style: {"flex":"1","display":"flex","flex-direction":"column"}
}

import { onMounted, ref, onUnmounted, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import type {
  LANGUAGE_CHANGED_PAYLOAD,
  THEME_CHANGED_PAYLOAD,
} from "@clearsale/one-lib-events";
import { customEvents } from "@clearsale/one-lib-events";

import imgShield from "@/assets/logo_shield.svg";
import { MergeHead, ActiveHeadEntry, useHead, UseHeadInput } from "@unhead/vue";
import { useTheme } from "vuetify";
import { i18n } from "./plugins/i18n";
import { auth } from "./services/auth";
import { dispatchConfigEvent } from "./utils/loadTopMenu";
// import { lib } from "@clearsale/one-lib-auth";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const theme = useTheme();

const headInfo = useHead({
  title: "Shield",
  link: [{ rel: "icon", href: imgShield, type: "image/svg+xml" }],
}) as ActiveHeadEntry<UseHeadInput<MergeHead>>;

const activeSSO = process.env.VUE_APP_ENV !== "development";

const router = useRouter();

const showApp = ref(false);

function changeThemeOnEvent(event: CustomEvent<THEME_CHANGED_PAYLOAD>) {
  const themeName = event.detail;
  theme.global.name.value = themeName;
}

function changeLanguageOnEvent(event: CustomEvent<LANGUAGE_CHANGED_PAYLOAD>) {
  const language = event.detail;
  i18n.global.locale.value = language;
}

onBeforeMount(() => {
  const storeThemeName = localStorage.getItem("theme");
  if (storeThemeName && storeThemeName?.length > 0) {
    theme.global.name.value = storeThemeName;
  }

  const storeLanguage = localStorage.getItem("language");
  if (storeLanguage && storeLanguage?.length > 0) {
    i18n.global.locale.value = storeLanguage;
  }
});

onMounted(async () => {
  customEvents.themeEvents.listener(changeThemeOnEvent);
  customEvents.languageEvent.listener(changeLanguageOnEvent);

  if (activeSSO) {
    await auth.execute(() => {
      showApp.value = true;
      dispatchConfigEvent();
    });
    return;
  }

  showApp.value = true;
});

onUnmounted(() => {
  customEvents.languageEvent.unsubscribe();
  customEvents.themeEvents.unsubscribe();

  headInfo.dispose();
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_v_theme_provider = _resolveComponent("v-theme-provider")!

  return (showApp.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_theme_provider, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_RouterView)
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})