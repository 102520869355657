<template>
  <transaction-card-list
    v-if="data.length"
    :onOnLoadLastItem="() => emits('fetchData')"
  >
    <template v-for="(item, index) in data" :key="index">
      <CardItemMobile
        :header="true"
        :selectable="false"
        :actions="false"
        :item="item"
        v-model:selected="item.selected"
        @select="() => emits('containerClick', item)"
      >
        <template #header>
          <span class="text-caption on-surface-variant">
            <span class="text-cs_ref_palette_neutral70">#</span>{{ item.code }}
          </span>
          <div>
            <div
              :class="[
                themeName,
                classNameText[item.status],
                'text',
                'd-flex',
                'align-center',
              ]"
              style="font-size: var(--v-typescale-title-small-size)"
            >
              <v-icon
                style="margin-right: 2px"
                size="8px"
                :icon="transactionStatusIcon[item.status]"
              />
              <span>{{
                t(
                  `${label.Shared.TransactionStatus._}.${
                    ITransactionStatus[item.status]
                  }`
                )
              }}</span>
            </div>
          </div>
        </template>

        <template #icon="props">
          <v-btn
            icon
            size="36px"
            variant="flat"
            :color="item.selected ? 'tertiary-container' : ''"
            @click.stop="handleSelectItem(props.data, item)"
            :class="!item.selected ? classNameWhole[item.status] : ''"
          >
            <v-icon
              :icon="
                item.selected
                  ? 'mdi-circle'
                  : transactionStatusIcon[item.status]
              "
              :size="
                item.selected ? '12px' : transactionStatusIconSize[item.status]
              "
              color=""
              :class="item.selected ? 'text-cs_on_tertiary_container' : ''"
            ></v-icon>
          </v-btn>
        </template>

        <template #default>
          <div class="d-flex flex-column">
            <span
              class="on-surface-variant"
              style="
                font-size: var(--v-typescale-label-large-size);
                font-weight: var(--v-typescale-title-medium-weight);
              "
            >
              {{ ellipsisText(item.entityName) }}
            </span>
            <time
              class="on-surface-variant"
              style="
                font-size: var(--v-typescale-label-medium-size);
                font-weight: var(--v-typescale-title-large-weight);
              "
            >
              {{
                d(
                  new Date(item.consumerRequestedAt),
                  isTodayDate(new Date(item.consumerRequestedAt))
                    ? "timeago"
                    : "short"
                )
              }}
            </time>
          </div>

          <div style="justify-self: end">
            <h2
              class="on-surface"
              style="
                font-size: var(--v-typescale-title-large-size);
                font-weight: var(--v-typescale-body-large-weight);
              "
            >
              {{
                item.totalAmount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
              }}
            </h2>
          </div>
        </template>

        <template #back>
          <div class="h-100 d-flex align-center">
            <v-btn variant="text" icon="mdi-check" color="information" />
            <v-btn variant="text" icon="mdi-repeat" color="information" />
            <v-btn
              variant="text"
              icon="mdi-priority-high"
              color="information"
            />
            <v-btn variant="text" icon="mdi-rotate-left" color="information" />
            <v-btn variant="text" icon="mdi-square" color="information" />
          </div>
        </template>
      </CardItemMobile>
    </template>
  </transaction-card-list>
</template>

<script setup lang="ts">
import { defineEmits, defineProps } from "vue";
import { useI18n } from "vue-i18n";

import TransactionCardList from "@/components/shared/TransactionCardList/TransactionCardList.vue";
import CardItemMobile from "@/components/nexus/CardItem/CardItemMobile.vue";
import { label } from "@/plugins/locales/global";

import {
  transactionStatusIcon,
  transactionStatusIconSize,
} from "@/model/transaction";
import { useTheme } from "vuetify";
import {
  ITransaction,
  ITransactionStatus,
} from "@/api/resources/Transactions/transactionResource";
import { useClassByThemeStatus } from "../hooks/useClassByTheme";
import { ITransactionStore } from "@/store/TransactionStore";
import { ellipsisText } from "@/utils/ellipsisUtility";

const classThemeWhole = {
  light: {
    [ITransactionStatus.Approved]: "bg-success-container text-success",
    [ITransactionStatus.Denied]: "bg-error-container text-error",
    [ITransactionStatus.Analyzing]: "bg-information-container text-information",
    [ITransactionStatus.Canceled]:
      "bg-cs_ref_palette_neutral80 text-cs_ref_palette_neutral_variant30",
    [ITransactionStatus.None]: "",
  },
  dark: {
    [ITransactionStatus.Approved]:
      "bg-cs_ref_palette_success80 text-cs_ref_palette_success10",
    [ITransactionStatus.Denied]:
      "bg-cs_ref_palette_error95 text-cs_ref_palette_error10",
    [ITransactionStatus.Analyzing]:
      "bg-cs_ref_palette_information90 text-cs_ref_palette_information10",
    [ITransactionStatus.Canceled]:
      "bg-cs_ref_palette_neutral80 text-cs_ref_palette_neutral_variant30",
    [ITransactionStatus.None]: "",
  },
};

const classThemeText = {
  light: {
    [ITransactionStatus.Approved]: "text-success",
    [ITransactionStatus.Denied]: "text-error",
    [ITransactionStatus.Analyzing]: "text-information",
    [ITransactionStatus.Canceled]: "text-dim",
    [ITransactionStatus.None]: "",
  },
  dark: {
    [ITransactionStatus.Approved]: "text-success",
    [ITransactionStatus.Denied]: "text-error",
    [ITransactionStatus.Analyzing]: "text-information",
    [ITransactionStatus.Canceled]: "text-dim",
    [ITransactionStatus.None]: "",
  },
};

const { name: themeName } = useTheme();
const { t, d } = useI18n();

const classNameWhole = useClassByThemeStatus(classThemeWhole);
const classNameText = useClassByThemeStatus(classThemeText);

defineProps<{ data: ITransactionStore[] }>();

function isTodayDate(date: Date) {
  const dateNow = new Date(date);
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  dateNow.setHours(0, 0, 0, 0);

  return dateNow.getTime() === todayDate.getTime();
}

function handleSelectItem(
  iconSlotData: {
    item: ITransactionStore;
    activeSelected: (value: boolean) => void;
    selectable?: boolean;
  },
  item: ITransactionStore
): void {
  if (!iconSlotData.selectable) return;

  iconSlotData.activeSelected(!item.selected);
  emits("selected", item);
}

const emits = defineEmits<{
  (e: "selected", transaction: ITransactionStore): void;
  (e: "containerClick", transaction: ITransaction): void;
  (e: "fetchData"): void;
}>();
</script>

<style scoped lang="scss"></style>
