import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import TransactionDetails_Contacts_Desktop from "@/components/shared/TransactionDetailsContactsContentDesktop/TransactionDetailsContactsContentDesktop.vue";
import TransactionDetails_Contacts_Mobile from "@/components/shared/TransactionDetailsContactsContentMobile/TransactionDetailsContactsContentMobile.vue";
import { TransactionDetailsContactsProps } from "@/model/transactionDetailsContacts";
import { useDisplay } from "vuetify";


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionDetailsContacts',
  props: {
    billing: {},
    shipping: {}
  },
  setup(__props: any) {



const { mdAndUp } = useDisplay();

return (_ctx: any,_cache: any) => {
  return (_unref(mdAndUp))
    ? (_openBlock(), _createBlock(TransactionDetails_Contacts_Desktop, {
        key: 0,
        billing: _ctx.billing,
        shipping: _ctx.shipping
      }, null, 8, ["billing", "shipping"]))
    : (_openBlock(), _createBlock(TransactionDetails_Contacts_Mobile, {
        key: 1,
        billing: _ctx.billing,
        shipping: _ctx.shipping
      }, null, 8, ["billing", "shipping"]))
}
}

})