import { SuggestionItem } from "@/api/resources/Transactions/transactionResource";
import { reactive } from "vue"

export type ButtonFilterTextEmits = {
  (e: "submit", payload: SuggestionItem[]): void;
  (e: "clear"): void;
}

export function useButtonFilterText(emit: ButtonFilterTextEmits) {
  const selectedChipList: SuggestionItem[] = reactive([])
  const suggestionBoxList: SuggestionItem[] = reactive([])

  function addSelectedChipsList(chip: SuggestionItem) {
    suggestionBoxList.forEach((suggestion) => {
      if (suggestion.id === chip.id && !selectedChipList.includes(suggestion)) {
        selectedChipList.push(suggestion)
      }
    })
  }

  function removeSelectedChipsList(chip: SuggestionItem) {
    const index = selectedChipList.findIndex((suggestion) => suggestion.id === chip.id)
    selectedChipList.splice(index, 1)
  }

  function loadSuggestionBoxList(suggestions: SuggestionItem[]) {
    suggestionBoxList.push(...suggestions)
  }

  function loadSelectedChipList(suggestions: SuggestionItem[]) {
    selectedChipList.push(...suggestions)
  }

  function clearSelectedChipList() {
    selectedChipList.splice(0, selectedChipList.length)
    emit("clear")
  }

  function submitButtonFilterText() {
    if (selectedChipList.length === 0) {
      emit("submit", [])
      return
    }

    emit("submit", selectedChipList)
  }

  function updateSelectedChipList(chips: SuggestionItem[]) {
    selectedChipList.splice(0, selectedChipList.length)
    selectedChipList.push(...chips)
  }

  function clearSuggestionBoxList() {
    suggestionBoxList.splice(0, suggestionBoxList.length)
  }

  return {
    selectedChipList,
    suggestionBoxList,
    addSelectedChipsList,
    loadSuggestionBoxList,
    loadSelectedChipList,
    removeSelectedChipsList,
    submitButtonFilterText,
    clearSelectedChipList,
    updateSelectedChipList,
    clearSuggestionBoxList
  }
}
