import { Chip } from "@/model/chip";
import { ref } from "vue";

export type ButtonFilterTextMobileEmits = {
  (e: "submit", payload: Chip[]): void;
  (e: "clear"): void;
}

export type ButtonFilterTextMobileProps = {
  label: string;
  placeholder?: string;
  options: Chip[];
  loadingSuggestions: boolean;
  showOptions?: boolean;
  name?: string;
}

export function useButtonFilterTextMobile(emit: ButtonFilterTextMobileEmits) {
  const selectedChipList = ref<Chip[]>([])
  const suggestionChipList = ref<Chip[]>([])

  function addSelectedChipsList(chip: Chip) {
    suggestionChipList.value.forEach((suggestion) => {
      if (suggestion.chipId === chip.chipId && !selectedChipList.value.includes(suggestion)) {
        selectedChipList.value.push(suggestion)
      }
    })
  }

  function removeSelectedChipList(chip: Chip) {
    const index = selectedChipList.value.findIndex((suggestion) => suggestion.chipId === chip.chipId)
    selectedChipList.value.splice(index, 1)
  }

  function loadSuggestionChipList(suggestions: Chip[]) {
    suggestionChipList.value.push(...suggestions)
  }

  function loadSuggestionChipListBasedOnSelect(suggestions: Chip[]) {
    if (selectedChipList.value.length > 0) {
      suggestionChipList.value.push(...suggestions.filter(
        (v) => !selectedChipList.value.find((sc) => sc.chipId == v.chipId)
      ));

      return;
    }

    suggestionChipList.value.push(...suggestions);
  }

  function loadSelectedChipList(suggestions: Chip[]) {
    selectedChipList.value.push(...suggestions)
  }

  function clearSelectedChipList() {
    selectedChipList.value.splice(0, selectedChipList.value.length)
    emit("clear")
  }

  function submitButtonFilterText() {
    if (selectedChipList.value.length === 0) {
      emit("submit", [])
      return
    }

    emit("submit", selectedChipList.value)
  }

  function changeOrderOfSuggestionChipList(suggestion: Chip, index: number) {
    if (!selectedChipList.value.includes(suggestion)) {
      suggestionChipList.value.splice(index, 1);
      suggestionChipList.value.splice(selectedChipList.value.length, 0, suggestion);
    }
  }

  function clearSuggestionChipList() {
    suggestionChipList.value.splice(0, suggestionChipList.value.length)
  }

  return {
    selectedChipList,
    suggestionChipList,
    removeSelectedChipList,
    loadSelectedChipList,
    loadSuggestionChipList,
    loadSuggestionChipListBasedOnSelect,
    addSelectedChipsList,
    changeOrderOfSuggestionChipList,
    clearSelectedChipList,
    clearSuggestionChipList,
    submitButtonFilterText,
  }
}
