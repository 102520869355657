import { IPaymentType, ITransactionDevice, ITransactionPaymentDetails } from "@/api/resources/Transactions/transactionResource";
import { computed } from "vue";

export type Payments = {
  [K in IPaymentType]?: ITransactionPaymentDetails[]
}

export interface TransactionPaymentProps {
  device?: ITransactionDevice;
  payments: ITransactionPaymentDetails[];
}

export function useTransactionPayments(transactionPayments: ITransactionPaymentDetails[]) {
  const computedPaymentsMap = computed(() => {
    const map = new Map<IPaymentType, ITransactionPaymentDetails[]>()

    for (const transactionPayment of transactionPayments) {
      if (!map.has(transactionPayment.type)) {
        map.set(transactionPayment.type, []);
      }

      map.get(transactionPayment.type)?.push(transactionPayment);
    }

    return map;
  });

  return { computedPaymentsMap };
}
