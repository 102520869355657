import { gsap } from "gsap";

//esse arquivo foi criado para caso seja necessário adicionar algum plugin, ele já está pronto pra isso. Por exemplo
import { Flip } from "gsap/Flip";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CustomEase } from "gsap/CustomEase";

gsap.registerPlugin(Flip);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);

export type GsapTimeline = gsap.core.Timeline;
export type GsapPosition = gsap.Position;
export type GsapTweenVars = gsap.TweenVars;
export type GsapContext = gsap.Context;

export default gsap;

