import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-cs_on_surface_variant" }
const _hoisted_2 = {
  key: 0,
  class: "text-cs_on_surface_variant flex-shrink-1",
  style: {"justify-self":"start"}
}
const _hoisted_3 = { class: "text-cs_on_surface_variant" }
const _hoisted_4 = { class: "status" }
const _hoisted_5 = {
  style: {"justify-self":"end"},
  class: "text-cs_on_surface_variant"
}
const _hoisted_6 = {
  key: 0,
  class: "text-overline"
}
const _hoisted_7 = {
  key: 1,
  style: {"font-size":"var(--v-typescale-label-medium-size)"}
}

import CardItemDesktop from "@/components/nexus/CardItem/CardItemDesktop.vue";
import TransactionCardList from "@/components/shared/TransactionCardList/TransactionCardList.vue";

import {
  transactionStatusIcon,
  transactionStatusIconSize,
} from "@/model/transaction";
import { useTheme } from "vuetify";
import { computed, ref, watch } from "vue";
import {
  ITransaction,
  ITransactionStatus,
} from "@/api/resources/Transactions/transactionResource";
import {
  useClassByThemeStatus,
  UseClassByThemeStatusProps,
} from "../hooks/useClassByTheme";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";
import { ITransactionStore } from "@/store/TransactionStore";
import { ellipsisText } from "@/utils/ellipsisUtility";


export default /*@__PURE__*/_defineComponent({
  __name: 'ListDesktopTransactions',
  props: {
    data: {}
  },
  emits: ["selected", "containerClick", "fetchData"],
  setup(__props: any, { emit: __emit }) {

const classThemeWhole = {
  light: {
    [ITransactionStatus.Approved]: "bg-success-container text-success",
    [ITransactionStatus.Denied]: "bg-error-container text-error",
    [ITransactionStatus.Analyzing]: "bg-information-container text-information",
    [ITransactionStatus.Canceled]:
      "bg-cs_ref_palette_neutral80 text-cs_ref_palette_neutral_variant30",
    [ITransactionStatus.None]: "",
  },
  dark: {
    [ITransactionStatus.Approved]:
      "bg-cs_ref_palette_success80 text-cs_ref_palette_success10",
    [ITransactionStatus.Denied]:
      "bg-cs_ref_palette_error95 text-cs_ref_palette_error10",
    [ITransactionStatus.Analyzing]:
      "bg-cs_ref_palette_information90 text-cs_ref_palette_information10",
    [ITransactionStatus.Canceled]:
      "bg-cs_ref_palette_neutral80 text-cs_ref_palette_neutral_variant30",
    [ITransactionStatus.None]: "",
  },
};

const classThemeText = {
  light: {
    [ITransactionStatus.Approved]: "text-success",
    [ITransactionStatus.Denied]: "text-error",
    [ITransactionStatus.Analyzing]: "text-information",
    [ITransactionStatus.Canceled]: "text-dim",
    [ITransactionStatus.None]: "",
  },
  dark: {
    [ITransactionStatus.Approved]: "text-success",
    [ITransactionStatus.Denied]: "text-error",
    [ITransactionStatus.Analyzing]: "text-information",
    [ITransactionStatus.Canceled]: "text-dim",
    [ITransactionStatus.None]: "",
  },
};

const props = __props;

const emits = __emit;

const { name: themeName } = useTheme();
const { t, d } = useI18n();

const classNameWhole = useClassByThemeStatus(classThemeWhole);
const classNameText = useClassByThemeStatus(classThemeText);

const isSelected = ref(false);

const hasEntityName = computed(() => {
  return props.data.filter((t) => t.entityName).length > 0;
});

watch(
  () => props.data,
  (newV, oldV) => {
    isSelected.value = newV.some((t) => t.selected);
  },
  { deep: true }
);

function isTodayDate(date: Date) {
  const dateNow = new Date(date);
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  dateNow.setHours(0, 0, 0, 0);

  return dateNow.getTime() === todayDate.getTime();
}

return (_ctx: any,_cache: any) => {
  const _component_v_checkbox_btn = _resolveComponent("v-checkbox-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_ctx.data.length)
    ? (_openBlock(), _createBlock(TransactionCardList, {
        key: 0,
        style: {"display":"flex","flex-direction":"column","gap":"8px"},
        onOnLoadLastItem: () => emits('fetchData')
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
            return (_openBlock(), _createBlock(CardItemDesktop, {
              key: index,
              item: item,
              selectable: false,
              actions: false,
              columns: hasEntityName.value ? 5 : 4,
              selected: item.selected,
              "onUpdate:selected": ($event: any) => ((item.selected) = $event),
              onClick: _withModifiers(($event: any) => (emits('containerClick', item)), ["stop"])
            }, {
              iconOrCheckbox: _withCtx((props) => [
                (props.data.selectable && props.data.isHovering)
                  ? (_openBlock(), _createBlock(_component_v_checkbox_btn, {
                      key: 0,
                      style: {"width":"32px","height":"32px"},
                      size: "32px",
                      color: "information",
                      modelValue: item.selected,
                      "onUpdate:modelValue": ($event: any) => ((item.selected) = $event),
                      onClick: _withModifiers(($event: any) => (emits('selected', item)), ["stop"])
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onClick"]))
                  : (_openBlock(), _createBlock(_component_v_icon, {
                      key: 1,
                      size: _unref(transactionStatusIconSize)[item.status],
                      style: {"width":"32px","height":"32px"},
                      class: _normalizeClass(["rounded-xl", [_unref(classNameWhole)[item.status], 'whole']]),
                      icon: _unref(transactionStatusIcon)[item.status]
                    }, null, 8, ["size", "icon", "class"]))
              ]),
              default: _withCtx((props) => [
                _createElementVNode("span", _hoisted_1, [
                  _cache[5] || (_cache[5] = _createElementVNode("span", { class: "text-cs_ref_palette_neutral70" }, "#", -1)),
                  _createTextVNode(_toDisplayString(item.code), 1)
                ]),
                (hasEntityName.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(ellipsisText)(item.entityName)), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_3, _toDisplayString(item.totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })), 1),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: _normalizeClass([_unref(themeName), _unref(classNameText)[item.status], 'text'])
                  }, [
                    _createVNode(_component_v_icon, {
                      icon: _unref(transactionStatusIcon)[item.status]
                    }, null, 8, ["icon"]),
                    _createElementVNode("span", null, _toDisplayString(_unref(t)(
                  `${_unref(label).Shared.TransactionStatus._}.${
                    _unref(ITransactionStatus)[item.status]
                  }`
                )), 1)
                  ], 2)
                ]),
                _createElementVNode("span", _hoisted_5, [
                  (!isSelected.value && props.data.isHovering && props.data.actions)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_v_btn, {
                          size: "40px",
                          style: {"width":"32px","height":"32px"},
                          variant: "text",
                          icon: "mdi-check",
                          color: "information",
                          onClick: _cache[0] || (_cache[0] = _withModifiers(() => console.log('mdi-check'), ["prevent","stop"]))
                        }),
                        _createVNode(_component_v_btn, {
                          size: "40px",
                          style: {"width":"32px","height":"32px"},
                          variant: "text",
                          icon: "mdi-repeat",
                          color: "information",
                          onClick: _cache[1] || (_cache[1] = _withModifiers(() => console.log('mdi-repeat'), ["prevent","stop"]))
                        }),
                        _createVNode(_component_v_btn, {
                          size: "40px",
                          style: {"width":"32px","height":"32px"},
                          variant: "text",
                          icon: "mdi-priority-high",
                          color: "information",
                          onClick: _cache[2] || (_cache[2] = _withModifiers(() => console.log('mdi-priority-high'), ["prevent","stop"]))
                        }),
                        _createVNode(_component_v_btn, {
                          size: "40px",
                          style: {"width":"32px","height":"32px"},
                          variant: "text",
                          icon: "mdi-rotate-left",
                          color: "information",
                          onClick: _cache[3] || (_cache[3] = _withModifiers(() => console.log('mdi-rotate-left'), ["prevent","stop"]))
                        }),
                        _createVNode(_component_v_btn, {
                          size: "40px",
                          style: {"width":"32px","height":"32px"},
                          variant: "text",
                          icon: "mdi-square",
                          color: "information",
                          onClick: _cache[4] || (_cache[4] = _withModifiers(() => console.log('mdi-square'), ["prevent","stop"]))
                        })
                      ]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(d)(
                  new Date(item.consumerRequestedAt),
                  isTodayDate(new Date(item.consumerRequestedAt))
                    ? "timeago"
                    : "short"
                )), 1))
                ])
              ]),
              _: 2
            }, 1032, ["item", "columns", "selected", "onUpdate:selected", "onClick"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["onOnLoadLastItem"]))
    : _createCommentVNode("", true)
}
}

})