interface RemoveChip {
  handleRemoveChip: (id: string) => void;
}

export interface RemoveChipEmits {
  (e: "removeChip", id: string): void;
}

export function useRemoveChip(emit: RemoveChipEmits): RemoveChip {
  function handleRemoveChip(id: string): void {
    emit("removeChip", id);
  }

  return {
    handleRemoveChip,
  };
}
