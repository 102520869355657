<template>
  <div
    :data-testid="generateTestId(CustomHeroElements.Content)"
    id="hero"
    :style="{
      backgroundImage: mdAndUp ? `url('${srcImage}')` : `url('${srcImage}')`,
      backgroundRepeat: 'no-repeat',
      padding: `${mdAndUp ? '24px' : '12px 0'}`,
      backgroundSize: '150%',
      borderRadius: '28px',
    }"
    class="h-100 w-100 position-relative"
  >
    <h1
      class="on-surface"
      :style="{
        paddingLeft: !mdAndUp && '12px',
        fontSize: fontSize,
        lineHeight: lineHeight,
        fontWeight: fontWeight,
      }"
    >
      {{ label }}
    </h1>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { useDisplay } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { CustomHeroElements } from "./constants/CustomHeroElements";
import { tokens } from "@clearsale/tokens-ds";
import { computed } from "vue";

const { mdAndUp } = useDisplay();

const fontSize = computed(() =>
  mdAndUp.value
    ? tokens.typography["typescale-display-large-size"]
    : tokens.typography["typescale-display-small-size"]
);
const lineHeight = computed(() =>
  mdAndUp.value
    ? tokens.typography["typescale-display-large-line-height"]
    : tokens.typography["typescale-display-small-line-height"]
);
const fontWeight = computed(() =>
  mdAndUp.value
    ? tokens.typography["typescale-display-large-weight"]
    : tokens.typography["typescale-display-small-weight"]
);

defineProps<{ label?: string; srcImage?: string }>();
</script>

<style scoped lang="scss"></style>
