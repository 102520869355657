import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass([
      'bg-cs_background',
      'd-flex',
      'align-center',
      'flex-1-1',
      'rounded-xl',
    ])
}

import { useStyleByTheme } from "@/components/hooks/useStyleByTheme";
import { TabsSwitchItem } from "@/model/tabSwitch";
import { DetailTransactionTabs } from "@/model/transaction";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'TabsSwitch',
  props: {
    options: {}
  },
  emits: ["selected"],
  setup(__props: any, { emit: __emit }) {

const buttonActiveClass = {
  light: { backgroundColor: "var(--bg-ref-palette-neutral92)" },
  dark: {
    backgroundColor: "rgba(33, 31, 38, 1)",
    color: "rgba(var(--v-theme-ref-palette-secondary80)) !important",
  },
};

const { t } = useI18n();

const computedButtonActiveStyle = useStyleByTheme(buttonActiveClass);



const emits =
  __emit;

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, ({ id, label, active }, i) => {
      return (_openBlock(), _createBlock(_component_v_btn, {
        key: i,
        class: _normalizeClass([
        'bg-cs_background',
        'flex-1-1',
        'h-100',
        'text-capitalize',
        'pa-0',
      ]),
        style: _normalizeStyle(active ? _unref(computedButtonActiveStyle) : undefined),
        variant: "text",
        color: "",
        onClick: () => emits('selected', id),
        active: active
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(t)(label)), 1)
        ]),
        _: 2
      }, 1032, ["style", "onClick", "active"]))
    }), 128))
  ]))
}
}

})