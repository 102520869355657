import { ref } from 'vue'
import { useTransactionStore } from '@/store/TransactionStore'

export interface PayloadSubmitButtonFilterStatus {
  fromValue: number,
  toValue: number
}

export function useButtonFilterValue() {
  const fromInput = ref<number | undefined>();
  const toInput = ref<number | undefined>();
  const fromInputMemory = ref<number>()
  const toInputMemory = ref<number>()

  const fromInputValue = ref(
    fromInputMemory.value !== undefined ? fromInputMemory : fromInput
  )

  const toInputValue = ref(
    toInputMemory.value !== undefined ? toInputMemory : toInput
  )

  const transactionStore = useTransactionStore()

  function handleSubmitValue(submitFilterFn: () => void) {
    fromInputMemory.value = fromInput.value
    toInputMemory.value = toInput.value

    const amountFrom = fromInputMemory.value
    const amountTo = toInputMemory.value

    submitFilterFn();

    transactionStore.$patch({
      filters: { amountFrom: amountFrom, amountTo: amountTo }
    })
    transactionStore.$patch({ paging: { page: 1, limit: 20 } })
  }

  function handleClearFilter() {
    fromInput.value = undefined;
    toInput.value = undefined;
    fromInputMemory.value = undefined
    toInputMemory.value = undefined
    transactionStore.$patch({ filters: { amountFrom: 0, amountTo: 0 } });
    transactionStore.$patch({ paging: { page: 1, limit: 20 } });
  }

  function handleCloseFilter() {
    if (fromInputMemory.value || toInputMemory) {
      fromInput.value = fromInputMemory.value
      toInput.value = toInputMemory.value
      return
    }
    fromInput.value = undefined
    toInput.value = undefined
  }

  return {
    fromInputValue,
    toInputValue,
    fromInputMemory,
    toInputMemory,
    handleSubmitValue,
    handleClearFilter,
    handleCloseFilter
  }
}
