import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-caption on-surface-variant" }
const _hoisted_2 = { class: "d-flex flex-column" }
const _hoisted_3 = {
  class: "on-surface-variant",
  style: {"font-size":"var(--v-typescale-label-large-size)","font-weight":"var(--v-typescale-title-medium-weight)"}
}
const _hoisted_4 = {
  class: "on-surface-variant",
  style: {"font-size":"var(--v-typescale-label-medium-size)","font-weight":"var(--v-typescale-title-large-weight)"}
}
const _hoisted_5 = { style: {"justify-self":"end"} }
const _hoisted_6 = {
  class: "on-surface",
  style: {"font-size":"var(--v-typescale-title-large-size)","font-weight":"var(--v-typescale-body-large-weight)"}
}
const _hoisted_7 = { class: "h-100 d-flex align-center" }


import { useI18n } from "vue-i18n";

import TransactionCardList from "@/components/shared/TransactionCardList/TransactionCardList.vue";
import CardItemMobile from "@/components/nexus/CardItem/CardItemMobile.vue";
import { label } from "@/plugins/locales/global";

import {
  transactionStatusIcon,
  transactionStatusIconSize,
} from "@/model/transaction";
import { useTheme } from "vuetify";
import {
  ITransaction,
  ITransactionStatus,
} from "@/api/resources/Transactions/transactionResource";
import { useClassByThemeStatus } from "../hooks/useClassByTheme";
import { ITransactionStore } from "@/store/TransactionStore";
import { ellipsisText } from "@/utils/ellipsisUtility";


export default /*@__PURE__*/_defineComponent({
  __name: 'ListMobileTransactions',
  props: {
    data: {}
  },
  emits: ["selected", "containerClick", "fetchData"],
  setup(__props: any, { emit: __emit }) {

const classThemeWhole = {
  light: {
    [ITransactionStatus.Approved]: "bg-success-container text-success",
    [ITransactionStatus.Denied]: "bg-error-container text-error",
    [ITransactionStatus.Analyzing]: "bg-information-container text-information",
    [ITransactionStatus.Canceled]:
      "bg-cs_ref_palette_neutral80 text-cs_ref_palette_neutral_variant30",
    [ITransactionStatus.None]: "",
  },
  dark: {
    [ITransactionStatus.Approved]:
      "bg-cs_ref_palette_success80 text-cs_ref_palette_success10",
    [ITransactionStatus.Denied]:
      "bg-cs_ref_palette_error95 text-cs_ref_palette_error10",
    [ITransactionStatus.Analyzing]:
      "bg-cs_ref_palette_information90 text-cs_ref_palette_information10",
    [ITransactionStatus.Canceled]:
      "bg-cs_ref_palette_neutral80 text-cs_ref_palette_neutral_variant30",
    [ITransactionStatus.None]: "",
  },
};

const classThemeText = {
  light: {
    [ITransactionStatus.Approved]: "text-success",
    [ITransactionStatus.Denied]: "text-error",
    [ITransactionStatus.Analyzing]: "text-information",
    [ITransactionStatus.Canceled]: "text-dim",
    [ITransactionStatus.None]: "",
  },
  dark: {
    [ITransactionStatus.Approved]: "text-success",
    [ITransactionStatus.Denied]: "text-error",
    [ITransactionStatus.Analyzing]: "text-information",
    [ITransactionStatus.Canceled]: "text-dim",
    [ITransactionStatus.None]: "",
  },
};

const { name: themeName } = useTheme();
const { t, d } = useI18n();

const classNameWhole = useClassByThemeStatus(classThemeWhole);
const classNameText = useClassByThemeStatus(classThemeText);



function isTodayDate(date: Date) {
  const dateNow = new Date(date);
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  dateNow.setHours(0, 0, 0, 0);

  return dateNow.getTime() === todayDate.getTime();
}

function handleSelectItem(
  iconSlotData: {
    item: ITransactionStore;
    activeSelected: (value: boolean) => void;
    selectable?: boolean;
  },
  item: ITransactionStore
): void {
  if (!iconSlotData.selectable) return;

  iconSlotData.activeSelected(!item.selected);
  emits("selected", item);
}

const emits = __emit;

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_ctx.data.length)
    ? (_openBlock(), _createBlock(TransactionCardList, {
        key: 0,
        onOnLoadLastItem: () => emits('fetchData')
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
            return (_openBlock(), _createBlock(CardItemMobile, {
              key: index,
              header: true,
              selectable: false,
              actions: false,
              item: item,
              selected: item.selected,
              "onUpdate:selected": ($event: any) => ((item.selected) = $event),
              onSelect: () => emits('containerClick', item)
            }, {
              header: _withCtx(() => [
                _createElementVNode("span", _hoisted_1, [
                  _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-cs_ref_palette_neutral70" }, "#", -1)),
                  _createTextVNode(_toDisplayString(item.code), 1)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", {
                    class: _normalizeClass([
                _unref(themeName),
                _unref(classNameText)[item.status],
                'text',
                'd-flex',
                'align-center',
              ]),
                    style: {"font-size":"var(--v-typescale-title-small-size)"}
                  }, [
                    _createVNode(_component_v_icon, {
                      style: {"margin-right":"2px"},
                      size: "8px",
                      icon: _unref(transactionStatusIcon)[item.status]
                    }, null, 8, ["icon"]),
                    _createElementVNode("span", null, _toDisplayString(_unref(t)(
                  `${_unref(label).Shared.TransactionStatus._}.${
                    _unref(ITransactionStatus)[item.status]
                  }`
                )), 1)
                  ], 2)
                ])
              ]),
              icon: _withCtx((props) => [
                _createVNode(_component_v_btn, {
                  icon: "",
                  size: "36px",
                  variant: "flat",
                  color: item.selected ? 'tertiary-container' : '',
                  onClick: _withModifiers(($event: any) => (handleSelectItem(props.data, item)), ["stop"]),
                  class: _normalizeClass(!item.selected ? _unref(classNameWhole)[item.status] : '')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, {
                      icon: 
                item.selected
                  ? 'mdi-circle'
                  : _unref(transactionStatusIcon)[item.status]
              ,
                      size: 
                item.selected ? '12px' : _unref(transactionStatusIconSize)[item.status]
              ,
                      color: "",
                      class: _normalizeClass(item.selected ? 'text-cs_on_tertiary_container' : '')
                    }, null, 8, ["icon", "size", "class"])
                  ]),
                  _: 2
                }, 1032, ["color", "onClick", "class"])
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(ellipsisText)(item.entityName)), 1),
                  _createElementVNode("time", _hoisted_4, _toDisplayString(_unref(d)(
                  new Date(item.consumerRequestedAt),
                  isTodayDate(new Date(item.consumerRequestedAt))
                    ? "timeago"
                    : "short"
                )), 1)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("h2", _hoisted_6, _toDisplayString(item.totalAmount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })), 1)
                ])
              ]),
              back: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_v_btn, {
                    variant: "text",
                    icon: "mdi-check",
                    color: "information"
                  }),
                  _createVNode(_component_v_btn, {
                    variant: "text",
                    icon: "mdi-repeat",
                    color: "information"
                  }),
                  _createVNode(_component_v_btn, {
                    variant: "text",
                    icon: "mdi-priority-high",
                    color: "information"
                  }),
                  _createVNode(_component_v_btn, {
                    variant: "text",
                    icon: "mdi-rotate-left",
                    color: "information"
                  }),
                  _createVNode(_component_v_btn, {
                    variant: "text",
                    icon: "mdi-square",
                    color: "information"
                  })
                ])
              ]),
              _: 2
            }, 1032, ["item", "selected", "onUpdate:selected", "onSelect"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["onOnLoadLastItem"]))
    : _createCommentVNode("", true)
}
}

})