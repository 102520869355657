import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, isRef as _isRef, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["data-testid"]
const _hoisted_2 = { class: "bg-on-surface text-left text-h5 mb-6" }

import ButtonFilterMobile from "@/components/shared/ButtonFilterMobile/ButtonFilterMobile.vue";
import ChipSelectable from "@/components/shared/ChipSelectable/ChipSelectable.vue";

import { useButtonFilterStatus } from "./hooks/useButtonFilterStatusMobile";
import { generateTestId } from "@/helpers/generateTestId";
import { ButtonFilterMobileStatus } from "./constants/ButtonFilterStatusMobileIds";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonFilterStatusMobile',
  setup(__props) {

const { t } = useI18n();
const {
  isValid,
  statusActive,
  statusOptions,
  handleClearAll,
  handleSubmitFilter,
} = useButtonFilterStatus();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ButtonFilterMobile, {
    label: _unref(t)(_unref(label).ButtonFilter.Status),
    active: _unref(isValid),
    height: 30,
    onCloseFilter: _unref(handleSubmitFilter),
    onClearFilter: _unref(handleClearAll)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        "data-testid": _unref(generateTestId)(_unref(ButtonFilterMobileStatus)),
        class: "w-100 d-flex flex-column justify-start align-start"
      }, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_unref(t)(_unref(label).ButtonFilter.Status)), 1),
        _createVNode(ChipSelectable, {
          modelValue: _unref(statusActive),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(statusActive) ? (statusActive).value = $event : null)),
          chips: _unref(statusOptions),
          multiple: "",
          isMobile: ""
        }, null, 8, ["modelValue", "chips"])
      ], 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["label", "active", "onCloseFilter", "onClearFilter"]))
}
}

})