import { BillingOrShippingInfo } from "@/model/transactionDetailsContacts";

export function useTransactionDetailsContacts() {
  function fullCityCountry(billingOrShppingInfo: BillingOrShippingInfo | undefined): string {
    if (!billingOrShppingInfo) return "";

    const { city, country } = billingOrShppingInfo;
    const parts = [city, country].filter((a) => a);

    return parts.join(", ");
  }

  function anyProperties(billingOrShppingInfo: BillingOrShippingInfo | undefined): boolean {
    if (!billingOrShppingInfo) return false;
    else return Object.values(billingOrShppingInfo).some((i) => i);
  }

  return {
    fullCityCountry,
    anyProperties
  }
}
