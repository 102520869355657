<template>
  <ul class="cs_joined-list">
    <li
      v-for="(item, index) in list"
      :key="index"
      class="align-center ga-4"
      :class="[
        'cs_joined-list',
        item.classLabel.length > 0 ? item.classLabel : 'default',
        theme,
      ]"
    >
      <p
        class="cs_joined-list__item__text"
        :class="item.classLabel"
        style="text-align: start"
      >
        {{ t(item.label) }}
      </p>
      <span
        v-if="item.date"
        :class="[titleComputedClass, 'cs_joined-list__item__date']"
      >
        {{ d(item.date, "long") }}
      </span>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { useTheme } from "vuetify/lib/framework.mjs";
import { JoinedListItem } from "./useJoinedList";
import { useCardColors } from "@/components/hooks/useCardColors";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";

const { t, d } = useI18n();
const { name: theme } = useTheme();

defineProps<{ list?: JoinedListItem[] }>();

const { titleComputedClass } = useCardColors();
</script>

<style lang="scss">
ul {
  list-style: none;
  margin-left: 16px;
}
li {
  position: relative;
  padding: 10px;
  min-height: 68px;
}

.cs_joined-list:first-child:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  left: -11px;
  top: 30%;
  transform: translateY(-40%);
}

.cs_joined-list:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  left: -13px;
  top: 30%;
  transform: translateY(-40%);
}

.cs_joined-list:not(:last-child):after {
  content: "";
  width: 2px;
  height: 100%;
  position: absolute;
  left: 15px;
  top: 50%;
  position: absolute;
  left: -8px;
  top: 30%;
}

.cs_joined-list.default:first-child:before {
  .default {
    &.light {
      background: rgb(var(--mdui-ref-palette-neutral50));
    }
    &.dark {
      background: rgb(var(--mdui-ref-palette-neutral60));
    }
  }
}

.cs_joined-list.default:before {
  .default {
    &.light {
      background: rgb(var(--mdui-ref-palette-neutral50));
    }
    &.dark {
      background: rgb(var(--mdui-ref-palette-neutral60));
    }
  }
}

.cs_joined-list.default:not(:last-child):after {
  .default {
    &.light {
      background: rgb(var(--mdui-ref-palette-neutral50));
    }
    &.dark {
      background: rgb(var(--mdui-ref-palette-neutral60));
    }
  }
}

.cs_joined-list.default {
  &.light {
    color: rgb(var(--mdui-ref-palette-neutral50));
  }
  &.dark {
    color: rgb(var(--mdui-ref-palette-neutral60));
  }
}

.cs_joined-list__item__date {
  font-size: 0.875rem;
}
</style>
