import { computed, ref, watch } from "vue";
import { useTransactionStore } from "@/store/TransactionStore";

import { Chip } from "@/model/chip";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";

export enum TimePeriodEnum {
  ONE_WEEK = 0,
  MTD = 1,
  ONE_MONTH = 2,
  THREE_MONTHS = 3,
  SIX_MONTHS = 4,
  ONE_YEAR = 5,
  CUSTOM = 6,
}

export interface PayloadSubmitButtonFilter {
  chipId: TimePeriodEnum;
  label: string;
  date: Date[];
}


export function useButtonFilterDate() {

  const timePeriodOptions = [
    {
      chipId: TimePeriodEnum.ONE_WEEK,
      label: label.ButtonFilter.Date.Week,
    },
    {
      chipId: TimePeriodEnum.MTD,
      label: label.ButtonFilter.Date.MTD,
    },
    {
      chipId: TimePeriodEnum.ONE_MONTH,
      label: label.ButtonFilter.Date.Month,
    },
    {
      chipId: TimePeriodEnum.THREE_MONTHS,
      label: label.ButtonFilter.Date.ThreeMonths,
    },
    {
      chipId: TimePeriodEnum.SIX_MONTHS,
      label: label.ButtonFilter.Date.SixMonths,
    },
    {
      chipId: TimePeriodEnum.ONE_YEAR,
      label: label.ButtonFilter.Date.OneYear,
    },
  ];

  const actualDate = new Date();

  const dialogModel = ref(false);
  const datePickerModel = ref<Date[] | undefined>(undefined);

  const dateChips = ref<Date[]>([new Date(), actualDate]);

  const selectedTimePeriodMemory = ref<Chip | null>();
  const selectedTimePeriodModel = ref<Chip | null>();
  const selectedTimePeriodOld = ref<Chip | null>();

  const isDatePickerCancelledWithoutSelection = ref(false);

  const transactionStore = useTransactionStore();

  const isFilterActive = computed(() => !!selectedTimePeriodModel.value);

  const isCustomFilterActive = computed(() => selectedTimePeriodModel.value?.chipId === TimePeriodEnum.CUSTOM);

  watch(selectedTimePeriodModel, (_, oldValue) => {
    selectChip();
    selectOldTimePeriodByWatch(oldValue);
  });

  function getStartDateForPeriod(
    period: TimePeriodEnum,
    currentDate: Date
  ): Date {
    const startDate = new Date(currentDate);

    switch (period) {
      case TimePeriodEnum.ONE_WEEK:
        startDate.setDate(startDate.getDate() - 8);
        break;
      case TimePeriodEnum.MTD:
        startDate.setDate(1);
        break;
      case TimePeriodEnum.ONE_MONTH:
        startDate.setMonth(startDate.getMonth() - 1);
        break;
      case TimePeriodEnum.THREE_MONTHS:
        startDate.setMonth(startDate.getMonth() - 3);
        break;
      case TimePeriodEnum.SIX_MONTHS:
        startDate.setMonth(startDate.getMonth() - 6);
        break;
      case TimePeriodEnum.ONE_YEAR:
        startDate.setFullYear(startDate.getFullYear() - 1);
        break;
    }

    return startDate;
  }

  function handleClickCustom() {
    dialogModel.value = true;
  }

  function submitDatePicker(date: Date[]) {
    datePickerModel.value = date;
    dialogModel.value = false;

    selectedTimePeriodModel.value = {
      chipId: TimePeriodEnum.CUSTOM,
      label: "Custom",
    }
  }

  function cancelDatePicker() {
    dialogModel.value = false;
    // se teve algum valor selecionado ou não, porem ele foi usado como submit, deve se manter selecionado
    if (selectedTimePeriodMemory.value?.chipId == TimePeriodEnum.CUSTOM) return;
    // se teve algum valor selecionado ou não, deve voltar pro ultimo date selecionado e usado na filtragem
    selectOldTimePeriod();
  }

  function selectChip() {
    if (selectedTimePeriodModel.value?.chipId !== TimePeriodEnum.CUSTOM) {
      dateChips.value[0] = getStartDateForPeriod(
        selectedTimePeriodModel.value?.chipId as TimePeriodEnum,
        actualDate
      );
    }
  }

  function selectOldTimePeriod() {
    if (selectedTimePeriodOld.value) {
      selectedTimePeriodModel.value = selectedTimePeriodOld.value;
      return;
    }
    selectedTimePeriodModel.value = null;
  }

  function selectOldTimePeriodByWatch(oldValue: Chip | null | undefined) {
    if (oldValue?.chipId !== TimePeriodEnum.CUSTOM) {
      selectedTimePeriodOld.value = oldValue;
    }
  }

  function submitButtonFilter(submitFilterFn: () => void) {
    if (!selectedTimePeriodModel.value) return;

    selectedTimePeriodMemory.value = selectedTimePeriodModel.value

    if (selectedTimePeriodModel.value.chipId !== TimePeriodEnum.CUSTOM) {
      const [dateFrom, dateTo] = dateChips.value;

      submitFilterFn();

      transactionStore.$patch({
        filters: { dateFrom, dateTo },
      });

      transactionStore.$patch({ page: 1, offset: 20 });

      datePickerModel.value = undefined;
    } else {
      submitFilterFn();

      transactionStore.$patch({
        filters: {
          dateFrom: datePickerModel.value?.[0],
          dateTo: datePickerModel.value?.[datePickerModel.value.length - 1],
        },
      });

      transactionStore.$patch({ page: 1, offset: 20 });
    }
  }

  function resetFilter() {
    selectedTimePeriodModel.value = null;
    selectedTimePeriodMemory.value = null;
    selectedTimePeriodOld.value = null;
    datePickerModel.value = undefined;
    dateChips.value = [new Date(), actualDate];
    dialogModel.value = false;
    isDatePickerCancelledWithoutSelection.value = false;
  }

  function clearAllFilter() {
    resetFilter();

    transactionStore.$patch({ filters: { dateFrom: null, dateTo: null } });
    transactionStore.$patch({ page: 1, offset: 20 });
  }

  function handleCloseFilter() {
    if (selectedTimePeriodMemory.value) {
      selectedTimePeriodModel.value = selectedTimePeriodMemory.value;
    }
    else {
      selectedTimePeriodModel.value = undefined;
    }

  }

  return {
    isFilterActive,
    isCustomFilterActive,
    timePeriodOptions,
    selectedTimePeriodMemory,
    selectedTimePeriodModel,
    submitButtonFilter,
    clearAllFilter,
    handleCloseFilter,
    dialogModel,
    datePickerModel,
    cancelDatePicker,
    submitDatePicker,
    handleClickCustom
  };
}
