import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withModifiers as _withModifiers, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-testid"]
const _hoisted_2 = { style: {"display":"flex","flex":"1 1 auto","align-items":"center"} }
const _hoisted_3 = {
  class: "align-self-start",
  style: {"padding-top":"1px"}
}
const _hoisted_4 = ["data-testid"]

import { useTheme } from "vuetify";

import { generateTestId } from "@/helpers/generateTestId";

import * as btnIds from "./constants/ButtonFilterIds";
import * as btnClasses from "./constants/ButtonFilterClasses";
import { ICON_SIZE } from "./constants/ButtonFilterValues";
import {
  useButtonFilter,
  ButtonFilterEmits,
  ButtonFilterProps,
} from "./hooks/useButtonFilter";
import TransitionHooks from "./TransitionHooks.vue";
import { Ref, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonFilter',
  props: {
    label: { default: "test" },
    open: { type: Boolean, default: false },
    active: { type: Boolean, default: false }
  },
  emits: ["submitFilter", "closeFilter", "clearFilter", "openFilter"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;

const refButtonFilter = ref<Element | undefined>() as Ref<Element>;

const { name: themeName } = useTheme();
const {
  btnOpen,
  btnActive,
  btnHidden,
  buildCssClasses,
  displayNormalIcon,
  displayClearButton,
  handleOpenFilter,
  handleSubmitFilter,
  handleCloseFilter,
  handleClearFilter,
} = useButtonFilter(refButtonFilter, emits, props);

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "refButtonFilter",
    ref: refButtonFilter,
    class: _normalizeClass([btnClasses.ButtonFilterBaseClass, "position-relative"])
  }, [
    _createElementVNode("div", {
      "data-testid": _unref(generateTestId)(btnIds.ButtonFilterContainerElement),
      class: _normalizeClass(_unref(buildCssClasses)(_unref(themeName))),
      style: {"transition":"background-color 1s","padding-right":"24px","padding-left":"24px","min-height":"40px"},
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_unref(handleOpenFilter) && _unref(handleOpenFilter)(...args)), ["stop"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", {
          class: _normalizeClass([btnClasses.ButtonFilterLabelClass, "position-relative d-flex align-center"])
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.label), 1),
          _createVNode(_component_v_icon, {
            class: _normalizeClass(btnClasses.ButtonFilterIconNormalClass),
            style: _normalizeStyle(_unref(displayNormalIcon)),
            icon: "mdi-chevron-down",
            size: _unref(ICON_SIZE)
          }, null, 8, ["class", "style", "size"]),
          _createVNode(_component_v_icon, {
            "data-testid": _unref(generateTestId)(btnIds.ButtonFilterButtonClearElement),
            class: _normalizeClass(btnClasses.ButtonFilterButtonClearClass),
            style: _normalizeStyle(_unref(displayClearButton)),
            icon: "mdi-close",
            size: _unref(ICON_SIZE),
            onClick: _withModifiers(_unref(handleClearFilter), ["stop"])
          }, null, 8, ["data-testid", "class", "style", "size", "onClick"])
        ], 2),
        _createElementVNode("div", {
          "data-testid": _unref(generateTestId)(btnIds.ButtonFilterSlotContainerElement),
          class: _normalizeClass([btnClasses.ButtonFilterSlotContainerClass, "w-100"]),
          style: {"display":"none","opacity":"0","visibility":"hidden"}
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 10, _hoisted_4),
        _createVNode(_component_v_btn, {
          "data-testid": _unref(generateTestId)(btnIds.ButtonFilterButtonSubmitElement),
          class: _normalizeClass([btnClasses.ButtonFilterButtonSubmitClass, 'ml-auto']),
          style: _normalizeStyle(_unref(btnHidden)),
          icon: "mdi-check",
          variant: "text",
          color: "on-surface-variant",
          size: "24px",
          rounded: "extra-large",
          onClick: _withModifiers(_unref(handleSubmitFilter), ["stop"])
        }, null, 8, ["data-testid", "class", "style", "onClick"])
      ])
    ], 10, _hoisted_1),
    _createVNode(_component_v_btn, {
      "data-testid": _unref(generateTestId)(btnIds.ButtonFilterButtonCloseElement),
      class: _normalizeClass([btnClasses.ButtonFilterButtonCloseClass, "top-0 right-0 position-absolute"]),
      style: _normalizeStyle(_unref(btnHidden)),
      icon: "mdi-close",
      variant: "flat",
      color: "surface-container",
      size: "40px",
      rounded: "extra-large",
      onClick: _withModifiers(_unref(handleCloseFilter), ["stop"])
    }, null, 8, ["data-testid", "class", "style", "onClick"])
  ], 2))
}
}

})