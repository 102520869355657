<template>
  <ButtonFilterMobile
    :label="label"
    :height="60"
    :active="selectedChipList.length > 0"
    @close-filter="handleCloseButtonFilterText"
    @clear-filter="handleClearButtonFilterText"
  >
    <div class="d-flex flex-column w-100 mb-6" style="flex-wrap: wrap">
      <h1 class="bg-on-surface text-left text-h5 mb-6">{{ label }}</h1>
      <div
        class="overflow-y-auto d-flex ga-2 border-b-thin pb-2"
        style="max-height: 120px; flex-wrap: wrap"
        :data-testid="
          generateTestId(
            ButtonFilterTextMobileConstants.ButtonFilterTextMobileSelectedItemsInput,
            name ?? ''
          )
        "
      >
        <ChipRemovable
          v-for="suggestion in selectedChipList"
          class="flex-0-1"
          :key="suggestion.chipId"
          :chip-id="(suggestion.chipId as string)"
          :label="suggestion.label"
          @click="inputModel = suggestion.label"
          @remove-chip="
            () => {
              handleRemoveChip(suggestion);
            }
          "
        >
        </ChipRemovable>

        <input
          type="text"
          class="flex-0-1"
          style="width: 150px; min-width: 0"
          v-bind="props"
          :placeholder="placeholder"
          autocomplete="off"
          :data-testid="
            generateTestId(
              ButtonFilterTextMobileConstants.ButtonFilterTextMobileInput,
              name ?? ''
            )
          "
          v-model="inputModel"
          @keypress.enter="
                () => {
                  const regex = new RegExp(inputModel as string, 'i');
                  const suggestionFind = suggestionChipList.find(
                    (suggestion) => regex.test(suggestion.label.toLowerCase())
                  );
                  if (suggestionFind) handleAddSuggestionFromInput(suggestionFind);
                }
              "
        />
      </div>
    </div>
    <p class="text-subtitle-2 on-surface-variant mb-4">{{ t(globalLabel.ButtonFilter.BottomSheet.suggestions)}}</p>
    <ButtonFilterTextMobileSuggestionBox
      v-model="selectedChipList"
      :chips="suggestionChipList"
      @selectChip="handleAddChip"
    />
  </ButtonFilterMobile>
</template>

<script setup lang="ts">
import { nextTick, onMounted, watch, defineModel } from "vue";

import ButtonFilterMobile from "@/components/shared/ButtonFilterMobile/ButtonFilterMobile.vue";
import ButtonFilterTextMobileSuggestionBox from "./ButtonFilterTextMobileSuggestionBox.vue";
import ChipRemovable from "@/components/shared/ChipRemovable/ChipRemovable.vue";
import {
  ButtonFilterTextMobileEmits,
  ButtonFilterTextMobileProps,
} from "@/components/shared/ButtonFilterTextMobile/useButtonFilterTextMobile";
import { useButtonFilterTextMobile } from "./useButtonFilterTextMobile";

import { generateTestId } from "@/helpers/generateTestId";

import { ButtonFilterTextMobileConstants } from "./constants/ButtonFilterMobileIDs";
import { Chip } from "@/model/chip";
import { label as globalLabel } from "@/plugins/locales/global";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const emits = defineEmits<ButtonFilterTextMobileEmits>();

const props = defineProps<ButtonFilterTextMobileProps>();

const inputModel = defineModel("input");

const {
  selectedChipList,
  suggestionChipList,
  removeSelectedChipList,
  loadSelectedChipList,
  loadSuggestionChipList,
  loadSuggestionChipListBasedOnSelect,
  addSelectedChipsList,
  changeOrderOfSuggestionChipList,
  clearSelectedChipList,
  clearSuggestionChipList,
  submitButtonFilterText,
} = useButtonFilterTextMobile(emits);

watch(
  () => props.options,
  (value) => {
    clearSuggestionChipList();

    loadSuggestionChipList(selectedChipList.value);
    loadSuggestionChipListBasedOnSelect(value);
  }
);

onMounted(() => {
  loadSelectedChipList([]);
  loadSuggestionChipList(selectedChipList.value);
});

function handleCloseButtonFilterText() {
  submitButtonFilterText();
}

function handleClearButtonFilterText() {
  clearSuggestionChipList();
  clearSelectedChipList();
}

async function handleAddSuggestionFromInput(suggestion: Chip) {
  inputModel.value = "";
  addSelectedChipsList(suggestion);
}

async function handleAddChip(suggestion: Chip, index: number) {
  inputModel.value = "";
  changeOrderOfSuggestionChipList(suggestion, index);
}

async function handleRemoveChip(suggestion: Chip) {
  removeSelectedChipList(suggestion);
}
</script>

<style scoped lang="scss">
input {
  outline: none;
}
</style>
