<template>
  <v-btn
    variant="outlined"
    rounded="lg"
    height="32"
    style="
      text-transform: none;
      letter-spacing: 0;
      transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    "
    :style="{}"
    color=""
    class="pr-3 font-weight-bold"
    :class="{
      'bg-secondary-container border-none pl-2': active,
      'on-surface-variant pl-3': !active,
    }"
    @click="handleClick"
  >
    <div class="d-flex align-center">
      <Transition
        :isCss="false"
        @before-enter="beforeEnterAnimation"
        @enter="enterAnimation"
        @leave="leaveAnimation"
      >
        <v-icon v-if="active"> mdi-check </v-icon>
      </Transition>
      <span
        style="transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1)"
        :class="{ 'ml-1': active }"
      >
        {{ t(label.ButtonFilter.Date.Custom) }}
      </span>
    </div>
  </v-btn>
</template>

<script setup lang="ts">
import gsap from "@/plugins/gsap";
import CustomEase from "gsap/CustomEase";
import { computed, ref } from "vue";
import { label } from "@/plugins/locales/global";
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n();

const selected = ref(false);

const props = defineProps<{ active: boolean }>();
const emits = defineEmits<(e: "click") => void>();

const active = computed(() => props.active);

function handleClick() {
  selected.value = true;
  emits("click");
}

function beforeEnterAnimation(el: Element) {
  gsap.set(el, {
    autoAlpha: 0,
    minWidth: 0,
    width: 0,
    minHeight: 0,
    height: 0,
  });
}

function enterAnimation(el: Element, done: () => void) {
  gsap
    .timeline({ defaults: { duration: 0.2 } })
    .to(el, {
      minWidth: 18,
      width: 18,
      minHeight: 18,
      height: 18,
      onComplete: done,
      ease: CustomEase.create("", "0.4, 0, 0.2, 1"),
    })
    .to(el, { autoAlpha: 1 }, "<50%");
}

function leaveAnimation(el: Element, done: () => void) {
  gsap
    .timeline({ defaults: { duration: 0.2 } })
    .to(el, { autoAlpha: 0 })
    .to(
      el,
      {
        minWidth: 0,
        width: 0,
        minHeight: 0,
        height: 0,
        onComplete: done,
        ease: CustomEase.create("", "0.4, 0, 0.2, 1"),
      },
      "<30%"
    );
}
</script>
