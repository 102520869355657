import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import ButtonDatePicker from "@/components/shared/ButtonDatePicker/ButtonDatePicker.vue";
import Datepicker from "@/components/shared/DatePicker/DatePicker.vue";
import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ComponentPreview',
  setup(__props) {

const dialogModel = ref(false);
const datePickerModel = ref<Date[] | undefined>(undefined);

function handleClick() {
  dialogModel.value = true;
}

function cancelDatePicker() {
  dialogModel.value = false;
  datePickerModel.value = undefined;
}

function submitDatePicker(date: Date[]) {
  datePickerModel.value = date;
  dialogModel.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ButtonDatePicker, {
      active: !!datePickerModel.value,
      onClick: handleClick
    }, null, 8, ["active"]),
    (dialogModel.value)
      ? (_openBlock(), _createBlock(Datepicker, {
          key: 0,
          dialog: dialogModel.value,
          "onUpdate:dialog": _cache[0] || (_cache[0] = ($event: any) => ((dialogModel).value = $event)),
          dates: datePickerModel.value,
          onCancel: cancelDatePicker,
          onSubmit: submitDatePicker,
          range: ""
        }, null, 8, ["dialog", "dates"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})