import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-testid"]

import { onMounted, ref } from "vue";

import { generateTestId } from "@/helpers/generateTestId";
import { ContainerSwiperElements } from "./constants/ContainerSwiperId";
import { useContainerSwiper } from "./useContainerSwiper";


export default /*@__PURE__*/_defineComponent({
  __name: 'ContainerSwiper',
  props: {
    name: {},
    gap: {}
  },
  setup(__props: any) {



const containerSwipeRef = ref<HTMLDivElement | null>(null);
const initialScroll = ref(0);

const { startEvent } = useContainerSwiper();

onMounted(() => {
  startEvent(initialScroll.value, containerSwipeRef);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "containerSwipeRef",
    ref: containerSwipeRef,
    class: "container-swiper",
    tabindex: "1",
    "data-testid": _unref(generateTestId)(_unref(ContainerSwiperElements).ContainerSwiper, _ctx.name),
    style: _normalizeStyle({ gap: _ctx.gap ?? '24px' })
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 12, _hoisted_1))
}
}

})