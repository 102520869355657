<template>
  <ActionDetailsDesktop
    v-if="mdAndUp"
    :options="options"
    @click="(id) => emits('click', id)"
  />
  <ActionDetailsMobile
    v-else
    :options="options"
    @click="(id) => emits('click', id)"
  />
</template>
<script setup lang="ts">
import ActionDetailsDesktop from "@/components/shared/ActionDetailsDesktop/ActionDetailsDesktop.vue";
import ActionDetailsMobile from "@/components/shared/ActionDetailsMobile/ActionDetailsMobile.vue";
import { ActionItem, ActionTransactionType } from "@/model/actionsDetails";
import { useDisplay } from "vuetify";

defineProps<{ options: ActionItem<ActionTransactionType>[] }>();
const emits = defineEmits<(e: "click", id: ActionTransactionType) => void>();

const { mdAndUp } = useDisplay();
</script>
