import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["data-testid"]
const _hoisted_2 = {
  for: "from",
  style: {"line-height":"1.5rem"}
}
const _hoisted_3 = {
  for: "from",
  style: {"line-height":"1.5rem"}
}

import { generateTestId } from "@/helpers/generateTestId";
import { ButtonFilterValueContent } from "./constants/ButtonFilterValueElements";
import { ButtonFilterValueStyle } from "./constants/ButtonFilterValueStyles";
import ButtonFilter from "@/components/shared/ButtonFilter/ButtonFilter.vue";
import { useButtonFilterValue } from "./useButtonFilterValue";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonFilterValue',
  setup(__props) {

const { t } = useI18n();
const {
  fromInputValue,
  handleClearFilter,
  handleSubmitValue,
  toInputValue,
  handleCloseFilter,
} = useButtonFilterValue();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ButtonFilter, {
    label: _unref(t)(_unref(label).ButtonFilter.Value.title),
    onSubmitFilter: _unref(handleSubmitValue),
    onClearFilter: _unref(handleClearFilter),
    onCloseFilter: _unref(handleCloseFilter),
    active: _unref(fromInputValue) !== undefined || _unref(toInputValue) !== undefined
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        style: {"display":"flex"},
        "data-testid": _unref(generateTestId)(_unref(ButtonFilterValueContent).Content)
      }, [
        _createElementVNode("div", {
          class: "value-button-filter on-surface-variant",
          style: _normalizeStyle(_unref(ButtonFilterValueStyle).ButtonFilterValueContent)
        }, [
          _createElementVNode("div", {
            style: _normalizeStyle(_unref(ButtonFilterValueStyle).ButtonFilterValueInput)
          }, [
            _createElementVNode("label", _hoisted_2, _toDisplayString(_unref(t)(_unref(label).ButtonFilter.Value.From)), 1),
            _withDirectives(_createElementVNode("input", {
              type: "number",
              name: "from",
              id: "from",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(fromInputValue) ? (fromInputValue).value = $event : null))
            }, null, 512), [
              [_vModelText, _unref(fromInputValue)]
            ])
          ], 4),
          _createElementVNode("div", {
            style: _normalizeStyle(_unref(ButtonFilterValueStyle).ButtonFilterValueInput)
          }, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_unref(t)(_unref(label).ButtonFilter.Value.To)), 1),
            _withDirectives(_createElementVNode("input", {
              type: "number",
              name: "to",
              id: "to",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(toInputValue) ? (toInputValue).value = $event : null))
            }, null, 512), [
              [_vModelText, _unref(toInputValue)]
            ])
          ], 4)
        ], 4)
      ], 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["label", "onSubmitFilter", "onClearFilter", "onCloseFilter", "active"]))
}
}

})