import { useClassByTheme } from "@/components/hooks/useClassByTheme";

export function useCardColors() {
  const titleClass = {
    light: "text-cs_ref_palette_neutral50",
    dark: "text-cs_ref_palette_neutral60",
  };

  const contentClass = {
    light: "text-cs_ref_palette_neutral_variant30",
    dark: "text-cs_ref_palette_neutral_variant80",
  };

  const titleComputedClass = useClassByTheme(titleClass);
  const contentComputedClass = useClassByTheme(contentClass);

  return {
    titleComputedClass,
    contentComputedClass
  }
}
