const ButtonFilterValueMobileElement = "button-filter-value-mobile";

const ButtonFilterValueMobileFromElement = `${ButtonFilterValueMobileElement}-from`;
const ButtonFilterValueMobileToElement = `${ButtonFilterValueMobileElement}-to`;

export const ButtonFilterValueMobileContent = {
  ButtonFilterValueMobileElement,
  ButtonFilterValueMobileFromElement,
  ButtonFilterValueMobileToElement
};
