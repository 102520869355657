import { computed, ref, watch } from "vue";
import { useTheme } from "vuetify";

export interface ButtonFilterMobileEmits {
  (e: 'closeFilter'): void
  (e: 'clearFilter'): void
  (e: 'openFilter'): void
}

export interface ButtonFilterMobileProps { label: string, height: number, active: boolean }

export function useButtonFilterMobile(props: ButtonFilterMobileProps, emits: ButtonFilterMobileEmits) {
  const { name: themeName } = useTheme();

  const activeBottomSheet = ref(false);

  const filterClasses = computed(() => {
    const baseClasses = [
      "rounded-xl",
      "bg-surface-container",
      "on-surface-variant",
      "position-relative",
    ];

    if (props.active) {
      if (themeName.value == "light") {
        baseClasses.splice(1, 1);
        baseClasses.push("bg-surface-variant");

      }
      else if (themeName.value == "dark") {
        baseClasses.splice(1, 1);
        baseClasses.push("bg-surface-variant");

      }
      baseClasses.push("text-primary");
    }

    return baseClasses;
  });

  watch(() => props.active, onActiveModelUpdated);

  function openBottomSheet() {
    activeBottomSheet.value = true;
    emits('openFilter');
  }

  function onCloseBottomSheet() {
    emits('closeFilter');
  }

  function clearFilter() {
    emits('clearFilter');
  }

  function onActiveModelUpdated() {
    //force bottom-sheet to close if active filter is programmatic closed
    if (!props.active && activeBottomSheet.value)
      activeBottomSheet.value = false;
  }

  return { activeBottomSheet, filterClasses, openBottomSheet, onCloseBottomSheet, clearFilter };
}
