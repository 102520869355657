<template>
  <ButtonFilter
    :label="t(label.ButtonFilter.Value.title)"
    @submit-filter="handleSubmitValue"
    @clear-filter="handleClearFilter"
    @close-filter="handleCloseFilter"
    :active="fromInputValue !== undefined || toInputValue !== undefined"
  >
    <div
      style="display: flex"
      :data-testid="generateTestId(ButtonFilterValueContent.Content)"
    >
      <div
        class="value-button-filter on-surface-variant"
        :style="ButtonFilterValueStyle.ButtonFilterValueContent"
      >
        <div :style="ButtonFilterValueStyle.ButtonFilterValueInput">
          <label for="from" style="line-height: 1.5rem">{{ t(label.ButtonFilter.Value.From) }}</label>
          <input type="number" name="from" id="from" v-model="fromInputValue" />
        </div>
        <div :style="ButtonFilterValueStyle.ButtonFilterValueInput">
          <label for="from" style="line-height: 1.5rem">{{ t(label.ButtonFilter.Value.To) }}</label>
          <input type="number" name="to" id="to" v-model="toInputValue" />
        </div>
      </div>
    </div>
  </ButtonFilter>
</template>
<script setup lang="ts">
import { generateTestId } from "@/helpers/generateTestId";
import { ButtonFilterValueContent } from "./constants/ButtonFilterValueElements";
import { ButtonFilterValueStyle } from "./constants/ButtonFilterValueStyles";
import ButtonFilter from "@/components/shared/ButtonFilter/ButtonFilter.vue";
import { useButtonFilterValue } from "./useButtonFilterValue";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";

const { t } = useI18n();
const {
  fromInputValue,
  handleClearFilter,
  handleSubmitValue,
  toInputValue,
  handleCloseFilter,
} = useButtonFilterValue();
</script>

<style lang="scss" scoped>
*:focus {
  outline: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
