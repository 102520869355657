import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-testid"]
const _hoisted_2 = ["data-testid"]
const _hoisted_3 = { class: "pl-6 pr-6" }

import {
  watch,
  ref,
  Ref,
  onMounted,
  computed,
  onBeforeUnmount,
} from "vue";
import { BottomSheetElements } from "./constants/BottomSheetElements";
import { BottomSheetAnimation } from "./constants/BottomSheetAnimation";
import { generateTestId } from "@/helpers/generateTestId";
import Hammer from "hammerjs";
import { useBottomSheetAnimation } from "./useBottomSheetAnimation";
import { BottomSheetEvents } from "./constants/BottemSheetEvents";


export default /*@__PURE__*/_defineComponent({
  __name: 'BottomSheet',
  props: /*@__PURE__*/_mergeModels({
    height: {}
  }, {
    "active": { type: Boolean, ...{ required: true } },
    "activeModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels([BottomSheetEvents.closed], ["update:active"]),
  setup(__props: any, { emit: __emit }) {

const activeModel = _useModel<boolean>(__props, "active");
const props = __props;
const emit = __emit;

const defaultHeight = computed(() => (props.height / 100) * window.innerHeight);
const innerActive = ref(activeModel.value);
const height = ref(0);
const heightPx = computed(() => `${height.value}px`);

const overlay = ref<HTMLDivElement>() as Ref<HTMLDivElement>;
const bottomSheet = ref<HTMLDivElement>() as Ref<HTMLDivElement>;
const handle = ref<HTMLDivElement>() as Ref<HTMLDivElement>;

const animation = useBottomSheetAnimation(
  bottomSheet,
  overlay,
  defaultHeight.value
);

watch(
  activeModel,
  (value) => (value ? openBottomSheet() : closeBottomSheet()),
  { immediate: true }
);
watch(handle, () => {
  if (!handle.value) return;
  createHammerManager();
  animation.onOpened(() => (height.value = defaultHeight.value));
});

function openBottomSheet() {
  innerActive.value = true;
}

function createHammerManager() {
  const hammer = new Hammer(handle.value, {});
  hammer
    .get("pan")
    .set({ direction: Hammer.DIRECTION_VERTICAL, threshold: 0, pointers: 0 });
  hammer.on("pan", (e: any) => {
    if (e.deltaY < 0) return;
    height.value = defaultHeight.value - e.deltaY;
  });
  hammer.on("panend", (e: any) => {
    const relativeHeight = height.value / defaultHeight.value;
    if (relativeHeight >= BottomSheetAnimation.PercentageForClosingSheet)
      animation.reset();
    else activeModel.value = false;
  });
}

function closeBottomSheet() {
  if (!bottomSheet.value) return;
  emit(BottomSheetEvents.closed);
  animation.onClosed(() => {
    height.value = 0;
    //Destroy bottomsheet
    innerActive.value = false;
  });
}

return (_ctx: any,_cache: any) => {
  return (innerActive.value)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createElementVNode("div", {
          "data-testid": 
        _unref(generateTestId)(_unref(BottomSheetElements).BottomSheetOverlayElement)
      ,
          class: "overlay",
          ref_key: "overlay",
          ref: overlay,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (activeModel.value = false))
        }, null, 8, _hoisted_1),
        _createElementVNode("section", {
          ref_key: "bottomSheet",
          ref: bottomSheet,
          style: _normalizeStyle({ height: heightPx.value }),
          class: "bg-cs_background",
          "data-testid": 
        _unref(generateTestId)(_unref(BottomSheetElements).BottomSheetContentElement)
      
        }, [
          _createElementVNode("div", {
            class: "handle-container",
            ref_key: "handle",
            ref: handle
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("div", { class: "handle" }, null, -1)
          ]), 512),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ], 12, _hoisted_2)
      ], 64))
    : _createCommentVNode("", true)
}
}

})