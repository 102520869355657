import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from "vue";
import { generateTestId } from "@/helpers/generateTestId";
import { ChipRemovableElements } from "./constants/ChipRemovableElements";
import {
  RemoveChipEmits,
  useRemoveChip,
} from "./constants/ChipRemovableEvents";


export default /*@__PURE__*/_defineComponent({
  __name: 'ChipRemovable',
  props: {
  label: String,
  chipId: { type: String, required: true },
},
  emits: ["removeChip"],
  setup(__props, { emit: __emit }) {

const props = __props;

let id = ref(props.chipId);

const emit = __emit;

const removeChip = useRemoveChip(emit);

const handleRemoveChip = (id: string) => removeChip.handleRemoveChip(id);

return (_ctx: any,_cache: any) => {
  const _component_v_chip = _resolveComponent("v-chip")!

  return (_openBlock(), _createBlock(_component_v_chip, {
    "data-testeid": _unref(generateTestId)(_unref(ChipRemovableElements).ChipRemovableContent),
    closable: true,
    "close-icon": _unref(ChipRemovableElements).ChipRemoveIcon,
    style: {"font-weight":"bold"},
    variant: "outlined",
    class: "rounded-lg on-surface-variant",
    "onClick:close": _cache[0] || (_cache[0] = ($event: any) => (handleRemoveChip(_unref(id))))
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(__props.label), 1)
    ]),
    _: 1
  }, 8, ["data-testeid", "close-icon"]))
}
}

})