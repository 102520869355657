<template>
  <div
    :data-testid="
      generateTestId(TransactionSummaryConstants.TransactionSummary)
    "
    :class="['content', displaySizeClass]"
  >
    <h2 class="on-surface-variant">
      {{
        t(
          label.TransactionDetailsPage.DetailTransactionTabs.Summary.Order.title
        )
      }}
    </h2>
    <section class="elevation-1 rounded-xl pa-6">
      <div class="d-flex justify-space-between">
        <span :class="titleComputedClass">{{
          t(label.TransactionDetailsPage.DetailTransactionTabs.Summary.Order.ID)
        }}</span>
        <span :class="contentComputedClass">#{{ summary?.id }}</span>
      </div>
      <div class="d-flex justify-space-between">
        <span :class="titleComputedClass">{{
          t(
            label.TransactionDetailsPage.DetailTransactionTabs.Summary.Order
              .Store
          )
        }}</span>
        <span :class="contentComputedClass">{{ summary?.entityName }}</span>
      </div>
      <div class="d-flex justify-space-between">
        <span :class="titleComputedClass">{{
          t(
            label.TransactionDetailsPage.DetailTransactionTabs.Summary.Order
              .Value
          )
        }}</span>
        <span :class="contentComputedClass">${{ summary?.totalAmount }}</span>
      </div>
    </section>

    <h2 class="on-surface-variant">
      {{
        t(
          label.TransactionDetailsPage.DetailTransactionTabs.Summary.Status
            .title
        )
      }}
    </h2>
    <section class="elevation-1 rounded-xl pa-6">
      <div class="d-flex justify-space-between">
        <span :class="titleComputedClass">{{
          t(
            label.TransactionDetailsPage.DetailTransactionTabs.Summary.Status
              .TransactionStatus
          )
        }}</span>
        <span
          v-if="summary?.status"
          :class="['status', theme, transactionStatusTheme]"
          class="d-flex align-center"
        >
          <v-icon :icon="transactionStatusIcon[summary.status]" :size="18" />
          {{
            t(
              `${label.Shared.TransactionStatus._}.${
                ITransactionStatus[summary.status]
              }`
            )
          }}
        </span>
      </div>
      <div
        class="d-flex justify-space-between"
        v-if="summary?.chargebackStatus"
      >
        <span :class="titleComputedClass">{{
          t(
            label.TransactionDetailsPage.DetailTransactionTabs.Summary.Status
              .ChargebackStatus
          )
        }}</span>
        <span
          :class="['status', theme, chargebackStatusTheme]"
          class="d-flex align-center"
        >
          <v-icon
            :icon="transactionStatusIcon[summary?.chargebackStatus]"
            :size="18"
          />
          {{
            t(
              `${label.Shared.TransactionStatus._}.${
                ITransactionStatus[summary?.chargebackStatus]
              }`
            )
          }}
        </span>
      </div>
    </section>

    <h2 class="on-surface-variant">
      {{
        t(
          label.TransactionDetailsPage.DetailTransactionTabs.Summary.Costumer
            .title
        )
      }}
    </h2>
    <section class="elevation-1 rounded-xl pa-6">
      <div class="d-flex justify-space-between">
        <span :class="titleComputedClass">{{
          t(
            label.TransactionDetailsPage.DetailTransactionTabs.Summary.Costumer
              .Email
          )
        }}</span>
        <span :class="contentComputedClass">
          <v-icon icon="mdi-email-outline" :size="18" />
          {{ summary?.email }}
        </span>
      </div>
    </section>

    <h2 class="on-surface-variant">
      {{
        t(
          label.TransactionDetailsPage.DetailTransactionTabs.Summary.Dates.title
        )
      }}
    </h2>
    <section class="elevation-1 rounded-xl pa-6">
      <div
        v-if="summary?.consumerRequestedAt"
        class="d-flex justify-space-between"
      >
        <span :class="titleComputedClass">{{
          t(
            label.TransactionDetailsPage.DetailTransactionTabs.Summary.Dates
              .TransactionDate
          )
        }}</span>
        <span :class="contentComputedClass">{{
          d(
            summary.consumerRequestedAt,
            isTodayDate(summary.consumerRequestedAt) ? "timeago" : "short"
          )
        }}</span>
      </div>
      <div v-if="summary?.clearSaleDate" class="d-flex justify-space-between">
        <span :class="titleComputedClass">{{
          t(
            label.TransactionDetailsPage.DetailTransactionTabs.Summary.Dates
              .ClearSaleDate
          )
        }}</span>
        <span :class="contentComputedClass">{{
          d(
            summary.clearSaleDate,
            isTodayDate(summary.clearSaleDate) ? "timeago" : "short"
          )
        }}</span>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { generateTestId } from "@/helpers/generateTestId";
import { ITransactionStatus } from "@/api/resources/Transactions/transactionResource";
import { transactionStatusIcon } from "@/model/transaction";
import { computed } from "vue";
import TransactionSummaryConstants from "./constants/TransactionSummaryId";
import { useDate, useDisplay, useTheme } from "vuetify/lib/framework.mjs";
import { TransactionDetailsSummaryProps } from "./constants/TransactionSummaryProps";
import { useCardColors } from "@/components/hooks/useCardColors";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";

const { t, d } = useI18n();
const props = defineProps<{ summary?: TransactionDetailsSummaryProps }>();

const { name: theme } = useTheme();
const transactionStatusTheme = computed(() => {
  if (!props.summary?.status) return "";
  return ITransactionStatus[props.summary?.status].toLowerCase();
});

const chargebackStatusTheme = computed(() => {
  if (!props.summary?.chargebackStatus) return "";
  //TODO add chargeback status
  return ITransactionStatus[props.summary?.chargebackStatus]?.toLowerCase();
});

const { mdAndUp } = useDisplay();
const date = useDate();
const { titleComputedClass, contentComputedClass } = useCardColors();

const orderDate = computed(() => {
  return isTodayDate(props.summary?.consumerRequestedAt as Date)
    ? date.format(props.summary?.consumerRequestedAt, "fullTime12h")
    : date.format(props.summary?.consumerRequestedAt, "fullDate");
});

const clearSaleDate = computed(() => {
  return isTodayDate(props.summary?.clearSaleDate as Date)
    ? date.format(props.summary?.clearSaleDate, "fullTime12h")
    : date.format(props.summary?.clearSaleDate, "fullDate");
});

const displaySizeClass = computed(() => (mdAndUp.value ? "md" : "sm"));

function isTodayDate(date: Date) {
  const dateNow = new Date(date);
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  dateNow.setHours(0, 0, 0, 0);

  return dateNow.getTime() === todayDate.getTime();
}
</script>

<style scoped lang="scss">
.content {
  &.sm {
    font-size: var(--v-typescale-body-medium-size);

    section {
      row-gap: 0.5rem;
      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }

    h2 {
      font-size: var(--v-typescale-title-medium-size) !important;
      font-weight: var(--v-typescale-title-small-weight);
      margin-left: 8px;
      margin-bottom: 4px;
    }
  }

  &.md {
    font-size: var(--v-typescale-body-large-size);

    section {
      row-gap: 1rem;
      &:not(:last-of-type) {
        margin-bottom: 40px;
      }
    }

    h2 {
      font-size: var(--v-typescale-title-large-size) !important;
      font-weight: var(--v-typescale-title-large-weight);
      margin-left: 16px;
      margin-bottom: 8px;
    }
  }
}

section {
  display: grid;
}

.status {
  &.light {
    &.approved {
      color: rgb(var(--mdui-ref-palette-success50));
    }

    &.denied {
      color: rgb(var(--mdui-ref-palette-error30));
    }

    &.analyzing {
      color: rgb(var(--mdui-ref-palette-information40));
    }

    &.canceled {
      color: rgb(var(--mdui-ref-palette-neutral-variant30));
    }
  }

  &.dark {
    &.approved {
      color: rgb(var(--mdui-ref-palette-success80));
    }

    &.denied {
      color: rgb(var(--mdui-ref-palette-error70));
    }

    &.analyzing {
      color: rgb(var(--mdui-ref-palette-information80));
    }

    &.canceled {
      color: rgb(var(--mdui-ref-palette-neutral-variant80));
    }
  }
}
</style>
