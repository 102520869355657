import { ref } from 'vue'
import { Chip } from "@/model/chip"
import { useTransactionStore } from "@/store/TransactionStore";
import { ITransactionStatus } from '@/api/resources/Transactions/transactionResource';
import { useI18n } from 'vue-i18n';
import { label } from '@/plugins/locales/global';

export interface PayloadSubmitButtonFilter {
  chipId: ITransactionStatus;
  label: string
}


export function useButtonFilterStatus() {
  const statusOptions = [
    {
      label: label.Shared.TransactionStatus.Approved,
      chipId: ITransactionStatus.Approved,
    },
    {
      label: label.Shared.TransactionStatus.Denied,
      chipId: ITransactionStatus.Denied,
    },
    {
      label: label.Shared.TransactionStatus.Analyzing,
      chipId: ITransactionStatus.Analyzing,
    },
    {
      label: label.Shared.TransactionStatus.Canceled,
      chipId: ITransactionStatus.Canceled,
    },
  ]
  const status = ref(statusOptions);
  const selectedStatus = ref<Chip[] | [] | undefined>([]);
  const selectedStatusMemory = ref<Chip[] | [] | undefined>()

  const transactionStore = useTransactionStore();

  const modelStatus = ref((selectedStatusMemory.value ?? []).length > 0 ? selectedStatusMemory : selectedStatus)

  function handleSubmitStatus(submitFilterFn: () => void) {
    selectedStatusMemory.value = selectedStatus.value
    const status = (selectedStatusMemory.value ?? []).map(item => item.chipId)
    submitFilterFn();
    transactionStore.$patch({
      filters: {
        status: status as ITransactionStatus[]
      }
    })
    transactionStore.$patch({ paging: { page: 1, limit: 20 } });
  }

  function handleClearAll() {
    selectedStatus.value = [];
    selectedStatusMemory.value = []
    transactionStore.$patch({ filters: { status: [] as ITransactionStatus[] } })
    transactionStore.$patch({ paging: { page: 1, limit: 20 } });
  }

  function handleCloseFilter() {
    if ((selectedStatusMemory.value ?? []).length > 0) {
      selectedStatus.value = selectedStatusMemory.value
      return
    }
    selectedStatus.value = []
  }

  return {
    status,
    selectedStatus,
    selectedStatusMemory,
    handleSubmitStatus,
    handleClearAll,
    handleCloseFilter,
    modelStatus
  }
}
