import { lib } from "@clearsale/one-lib-auth";

const params = {
  clientId: process.env.VUE_APP_AUTH_CLIENT_ID ?? "",
  redirectUri: process.env.VUE_APP_AUTH_REDIRECT_URI ?? "",
  appName: "shield",
  authFlow: process.env.VUE_APP_AUTH_FLOW ?? '',
  authTenant: process.env.VUE_APP_AUTH_TENANT ?? '',
  authUrl: process.env.VUE_APP_AUTH_URL ?? '',
  redirectUriOne: process.env.VUE_APP_AUTH_REDIRECT_URI_ONE ?? '',
};

export const auth = new lib.Authenticate(params);
