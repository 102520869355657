<template>
  <WidthScrollContainer
    width="600px"
    :height="scrollContainerHeight.to"
    @scroll-on-top="(onTop: boolean) => (isOnTop = onTop)"
  >
    <div class="d-flex ga-3 pt-1 rounded-b-xl" :class="computedContainerClass">
      <v-btn
        variant="flat"
        density="comfortable"
        :size="isOnTop ? scrollContainerHeight.to : scrollContainerHeight.from"
        style="transition: width ease-in-out 0.3s, height ease-in-out 0.3s"
        :style="computedReturnButtonStyle"
        color=""
        icon="mdi-arrow-left"
        :to="{ name: routeNames.transactionView }"
      />
      <TabsSwitch
        :options="options"
        @selected="(id: DetailTransactionTabs) => emits('click', id)"
      />
    </div>
  </WidthScrollContainer>
</template>
<script setup lang="ts">
import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import TabsSwitch from "@/components/shared/TabsSwitch/TabsSwitch.vue";
import WidthScrollContainer from "@/components/shared/WidthScrollContainer/WidthScrollContainer.vue";

import { TabsSwitchItem } from "@/model/tabSwitch";
import { DetailTransactionTabs } from "@/model/transaction";
import { ref } from "vue";

import { routeNames } from "@/router";
import { useStyleByTheme } from "@/components/hooks/useStyleByTheme";

const scrollContainerHeight = { from: "48px", to: "40px" };

const containerClass = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};

const returnButtonStyle = {
  light: {
    backgroundColor: "rgba(var(--v-ref-palette-neutral95))",
    color: "rgba(var(--v-ref-palette-neutral-variant30))",
  },
  dark: {
    backgroundColor: "rgba(var(--v-theme-surface-container-high))",
    color: "rgba(var(--v-theme-on-surface-variant))",
  },
};

defineProps<{ options: TabsSwitchItem<DetailTransactionTabs>[] }>();
const emits = defineEmits<(e: "click", id: DetailTransactionTabs) => void>();

const isOnTop = ref(false);

const computedContainerClass = useClassByTheme(containerClass);
const computedReturnButtonStyle = useStyleByTheme(returnButtonStyle);
</script>
