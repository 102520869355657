import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "date" }
const _hoisted_2 = { class: "date__content" }
const _hoisted_3 = { key: 0 }


import { useDatepicker } from "./useDatepicker";


export default /*@__PURE__*/_defineComponent({
  __name: 'DatePicker',
  props: /*@__PURE__*/_mergeModels({
    range: { type: Boolean },
    dates: {},
    year: {},
    month: {}
  }, {
    "dialog": { type: Boolean },
    "dialogModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["cancel", "submit"], ["update:dialog"]),
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;

const dialogModel = _useModel<boolean>(__props, "dialog");

const { dateModel, mapFormatDate, handleCancelDate, handleSubmit, showToDate } =
  useDatepicker(props, emits);

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: dialogModel.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((dialogModel).value = $event))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_date_picker, {
          color: "primary",
          "bg-color": "surface-container",
          modelValue: _unref(dateModel),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(dateModel) ? (dateModel).value = $event : null)),
          multiple: _ctx.range ? 'range' : false,
          month: _ctx.month,
          year: _ctx.year
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_unref(showToDate).length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Select date"))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(mapFormatDate)(_unref(showToDate), true), (date, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: date }, [
                  (index === 0)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(date), 1)
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _cache[2] || (_cache[2] = _createElementVNode("span", null, " - ", -1)),
                        _createTextVNode(" " + _toDisplayString(date), 1)
                      ], 64))
                ], 64))
              }), 128))
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("div", {
              class: "bg-cs_outline_variant",
              style: {"width":"100%","height":"1px","display":"block"}
            }, null, -1))
          ]),
          actions: _withCtx(() => [
            _createVNode(_component_v_btn, { onClick: _unref(handleCancelDate) }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Cancel")
              ])),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_v_btn, { onClick: _unref(handleSubmit) }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("Ok")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["modelValue", "multiple", "month", "year"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})