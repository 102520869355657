import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, vModelText as _vModelText, withKeys as _withKeys, mergeProps as _mergeProps, withDirectives as _withDirectives, isRef as _isRef, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "d-flex flex-column w-100 mb-6",
  style: {"flex-wrap":"wrap"}
}
const _hoisted_2 = { class: "bg-on-surface text-left text-h5 mb-6" }
const _hoisted_3 = ["data-testid"]
const _hoisted_4 = ["placeholder", "data-testid"]
const _hoisted_5 = { class: "text-subtitle-2 on-surface-variant mb-4" }

import { nextTick, onMounted, watch } from "vue";

import ButtonFilterMobile from "@/components/shared/ButtonFilterMobile/ButtonFilterMobile.vue";
import ButtonFilterTextMobileSuggestionBox from "./ButtonFilterTextMobileSuggestionBox.vue";
import ChipRemovable from "@/components/shared/ChipRemovable/ChipRemovable.vue";
import {
  ButtonFilterTextMobileEmits,
  ButtonFilterTextMobileProps,
} from "@/components/shared/ButtonFilterTextMobile/useButtonFilterTextMobile";
import { useButtonFilterTextMobile } from "./useButtonFilterTextMobile";

import { generateTestId } from "@/helpers/generateTestId";

import { ButtonFilterTextMobileConstants } from "./constants/ButtonFilterMobileIDs";
import { Chip } from "@/model/chip";
import { label as globalLabel } from "@/plugins/locales/global";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonFilterTextMobile',
  props: /*@__PURE__*/_mergeModels({
    label: {},
    placeholder: {},
    options: {},
    loadingSuggestions: { type: Boolean },
    showOptions: { type: Boolean },
    name: {}
  }, {
    "input": {},
    "inputModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["submit", "clear"], ["update:input"]),
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();
const emits = __emit;

const props = __props;

const inputModel = _useModel(__props, "input");

const {
  selectedChipList,
  suggestionChipList,
  removeSelectedChipList,
  loadSelectedChipList,
  loadSuggestionChipList,
  loadSuggestionChipListBasedOnSelect,
  addSelectedChipsList,
  changeOrderOfSuggestionChipList,
  clearSelectedChipList,
  clearSuggestionChipList,
  submitButtonFilterText,
} = useButtonFilterTextMobile(emits);

watch(
  () => props.options,
  (value) => {
    clearSuggestionChipList();

    loadSuggestionChipList(selectedChipList.value);
    loadSuggestionChipListBasedOnSelect(value);
  }
);

onMounted(() => {
  loadSelectedChipList([]);
  loadSuggestionChipList(selectedChipList.value);
});

function handleCloseButtonFilterText() {
  submitButtonFilterText();
}

function handleClearButtonFilterText() {
  clearSuggestionChipList();
  clearSelectedChipList();
}

async function handleAddSuggestionFromInput(suggestion: Chip) {
  inputModel.value = "";
  addSelectedChipsList(suggestion);
}

async function handleAddChip(suggestion: Chip, index: number) {
  inputModel.value = "";
  changeOrderOfSuggestionChipList(suggestion, index);
}

async function handleRemoveChip(suggestion: Chip) {
  removeSelectedChipList(suggestion);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ButtonFilterMobile, {
    label: _ctx.label,
    height: 60,
    active: _unref(selectedChipList).length > 0,
    onCloseFilter: handleCloseButtonFilterText,
    onClearFilter: handleClearButtonFilterText
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.label), 1),
        _createElementVNode("div", {
          class: "overflow-y-auto d-flex ga-2 border-b-thin pb-2",
          style: {"max-height":"120px","flex-wrap":"wrap"},
          "data-testid": 
          _unref(generateTestId)(
            _unref(ButtonFilterTextMobileConstants).ButtonFilterTextMobileSelectedItemsInput,
            _ctx.name ?? ''
          )
        
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(selectedChipList), (suggestion) => {
            return (_openBlock(), _createBlock(ChipRemovable, {
              class: "flex-0-1",
              key: suggestion.chipId,
              "chip-id": (suggestion.chipId as string),
              label: suggestion.label,
              onClick: ($event: any) => (inputModel.value = suggestion.label),
              onRemoveChip: 
            () => {
              handleRemoveChip(suggestion);
            }
          
            }, null, 8, ["chip-id", "label", "onClick", "onRemoveChip"]))
          }), 128)),
          _withDirectives(_createElementVNode("input", _mergeProps({
            type: "text",
            class: "flex-0-1",
            style: {"width":"150px","min-width":"0"}
          }, props, {
            placeholder: _ctx.placeholder,
            autocomplete: "off",
            "data-testid": 
            _unref(generateTestId)(
              _unref(ButtonFilterTextMobileConstants).ButtonFilterTextMobileInput,
              _ctx.name ?? ''
            )
          ,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputModel).value = $event)),
            onKeypress: _cache[1] || (_cache[1] = _withKeys(
                () => {
                  const regex = new RegExp(inputModel.value as string, 'i');
                  const suggestionFind = _unref(suggestionChipList).find(
                    (suggestion) => regex.test(suggestion.label.toLowerCase())
                  );
                  if (suggestionFind) handleAddSuggestionFromInput(suggestionFind);
                }
              , ["enter"]))
          }), null, 16, _hoisted_4), [
            [_vModelText, inputModel.value]
          ])
        ], 8, _hoisted_3)
      ]),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(t)(_unref(globalLabel).ButtonFilter.BottomSheet.suggestions)), 1),
      _createVNode(ButtonFilterTextMobileSuggestionBox, {
        modelValue: _unref(selectedChipList),
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(selectedChipList) ? (selectedChipList).value = $event : null)),
        chips: _unref(suggestionChipList),
        onSelectChip: handleAddChip
      }, null, 8, ["modelValue", "chips"])
    ]),
    _: 1
  }, 8, ["label", "active"]))
}
}

})