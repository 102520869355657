const ActionBarContent = 'action-bar'
const ActionBarButtonVariations = [
  {
    label: 'ForceApproval',
    icon: 'mdi-check'
  },
  {
    label: 'Reanalyze',
    icon: 'mdi-repeat'
  },
  {
    label: 'Prioritize',
    icon: 'mdi-exclamation'
  },
  {
    label: 'RequestChargeback',
    icon: 'mdi-rotate-left'
  },
  {
    label: 'CancelOrder',
    icon: 'mdi-stop'
  }
]
const ActionBarHeight = '2.5rem'

export const ActionBarElements = {
  ActionBarContent,
  ActionBarButtonVariations,
  ActionBarHeight
}
