import { ITransactionStatus } from "@/api/resources/Transactions/transactionResource";
import { Chip } from "@/model/chip";
import { useTransactionStore } from "@/store/TransactionStore";
import { computed, onMounted, reactive, ref, watch, withDefaults } from "vue";
import { label } from "@/plugins/locales/global";
import { useI18n } from "vue-i18n";

export type ButtonFilterStatusProps = { open: boolean };

export enum ButtonFilterStatus {
  Analyzing = 0,
  Approved = 1,
  Denied = 2,
  Canceled = 3,
}


export function useButtonFilterStatus() {
  const statusOptions: Chip[] = [
    {
      label: label.Shared.TransactionStatus.Approved,
      chipId: ITransactionStatus.Approved,
    },
    {
      label: label.Shared.TransactionStatus.Denied,
      chipId: ITransactionStatus.Denied,
    },
    {
      label: label.Shared.TransactionStatus.Analyzing,
      chipId: ITransactionStatus.Analyzing,
    },
    {
      label: label.Shared.TransactionStatus.Canceled,
      chipId: ITransactionStatus.Canceled,
    },
  ];

  const store = useTransactionStore();

  const statusActive = ref<Chip[]>([]);

  const isValid = computed(() => {
    return statusActive.value.length > 0;
  })

  function handleClearAll() {
    statusActive.value = [];
    store.$patch({ filters: { status: [] } });
  }

  function handleSubmitFilter() {
    const resultStatus: ITransactionStatus[] = statusActive.value.map(v => (v.chipId as number));

    store.$patch({ filters: { status: resultStatus } });
  }

  return {
    isValid,
    statusActive,
    statusOptions,
    handleClearAll,
    handleSubmitFilter,
  };
}
