<template>
  <div
    style="
      background-color: white;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    "
    v-if="showApp"
  >
    <v-theme-provider>
      <div
        class="bg-cs_background"
        style="flex: 1; display: flex; flex-direction: column"
      >
        <RouterView />
      </div>
    </v-theme-provider>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, onUnmounted, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import type {
  LANGUAGE_CHANGED_PAYLOAD,
  THEME_CHANGED_PAYLOAD,
} from "@clearsale/one-lib-events";
import { customEvents } from "@clearsale/one-lib-events";

import imgShield from "@/assets/logo_shield.svg";
import { MergeHead, ActiveHeadEntry, useHead, UseHeadInput } from "@unhead/vue";
import { useTheme } from "vuetify";
import { i18n } from "./plugins/i18n";
import { auth } from "./services/auth";
import { dispatchConfigEvent } from "./utils/loadTopMenu";
// import { lib } from "@clearsale/one-lib-auth";

const theme = useTheme();

const headInfo = useHead({
  title: "Shield",
  link: [{ rel: "icon", href: imgShield, type: "image/svg+xml" }],
}) as ActiveHeadEntry<UseHeadInput<MergeHead>>;

const activeSSO = process.env.VUE_APP_ENV !== "development";

const router = useRouter();

const showApp = ref(false);

function changeThemeOnEvent(event: CustomEvent<THEME_CHANGED_PAYLOAD>) {
  const themeName = event.detail;
  theme.global.name.value = themeName;
}

function changeLanguageOnEvent(event: CustomEvent<LANGUAGE_CHANGED_PAYLOAD>) {
  const language = event.detail;
  i18n.global.locale.value = language;
}

onBeforeMount(() => {
  const storeThemeName = localStorage.getItem("theme");
  if (storeThemeName && storeThemeName?.length > 0) {
    theme.global.name.value = storeThemeName;
  }

  const storeLanguage = localStorage.getItem("language");
  if (storeLanguage && storeLanguage?.length > 0) {
    i18n.global.locale.value = storeLanguage;
  }
});

onMounted(async () => {
  customEvents.themeEvents.listener(changeThemeOnEvent);
  customEvents.languageEvent.listener(changeLanguageOnEvent);

  if (activeSSO) {
    await auth.execute(() => {
      showApp.value = true;
      dispatchConfigEvent();
    });
    return;
  }

  showApp.value = true;
});

onUnmounted(() => {
  customEvents.languageEvent.unsubscribe();
  customEvents.themeEvents.unsubscribe();

  headInfo.dispose();
});
</script>
