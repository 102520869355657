<template>
  <div :class="['content', displaySizeClass]">
    <h2 class="on-surface-variant">
      {{
        t(
          label.TransactionDetailsPage.DetailTransactionTabs.Items.Breakdown
            .title
        )
      }}
    </h2>
    <section
      v-for="(e, i) in items"
      :key="i"
      class="elevation-1 rounded-xl pa-6"
    >
      <div class="d-flex justify-space-between">
        <span :class="titleComputedClass">{{ e.name }}</span>
        <span :class="titleComputedClass">{{
          (e.price * e.quantity).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        }}</span>
      </div>
      <div class="d-flex justify-space-between text-caption">
        <span :class="contentComputedClass">{{
          e.price.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        }}</span>
        <span :class="contentComputedClass">x{{ e.quantity }}</span>
      </div>
    </section>
  </div>
</template>

<script lang="ts" setup>
import { ITransactionDetails_Items } from "@/api/resources/Transactions/transactionResource";
import { computed } from "vue";
import { label } from "@/plugins/locales/global";
import { useI18n } from "vue-i18n";
import { useDisplay } from "vuetify";
import { useCardColors } from "@/components/hooks/useCardColors";

defineProps<{
  items?: ITransactionDetails_Items[];
}>();

const { t } = useI18n();
const display = useDisplay();
const {
  titleComputedClass: contentComputedClass,
  contentComputedClass: titleComputedClass,
} = useCardColors();

const displaySizeClass = computed(() => (display.mdAndUp.value ? "md" : "sm"));
</script>

<style scoped lang="scss">
.content {
  &.sm {
    font-size: var(--v-typescale-body-medium-size);

    h2 {
      font-size: var(--v-typescale-title-medium-size) !important;
      font-weight: var(--v-typescale-title-small-weight);
      margin-left: 8px;
      margin-bottom: 4px;
    }

    section:not(:last-of-type) {
      margin-bottom: 4px;
    }
  }

  &.md {
    font-size: var(--v-typescale-body-large-size);

    h2 {
      font-size: var(--v-typescale-title-large-size) !important;
      font-weight: var(--v-typescale-title-large-weight);
      margin-left: 16px;
      margin-bottom: 8px;
    }

    section:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }
}
</style>
