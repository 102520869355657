import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { Ref, onMounted, onUnmounted, provide, ref } from "vue";
import { useButtonFilterContainer } from "./hooks/useButtonFilterContainer";
import { ButtonFilterContainerAnimationContext } from "./constants/ButtonFilterContainer";


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonFilterContainer',
  setup(__props) {

const refContainer = ref<Element>();
let context = ref<ButtonFilterContainerAnimationContext>();

provide('button-filter__container--context', context);

const { buildAnimationContext } = useButtonFilterContainer()

onMounted(() => {
  context.value = buildAnimationContext(refContainer as Ref<Element>)
});

onUnmounted(() => context.value?.revert());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "refContainer",
    ref: refContainer,
    class: "d-flex flex-1-1 align-start justify-start position-relative"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 512))
}
}

})