import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, isRef as _isRef, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100 d-flex flex-column justify-start align-start" }
const _hoisted_2 = { class: "bg-on-surface text-left text-h5 mb-3" }

import ButtonFilterMobile from "@/components/shared/ButtonFilterMobile/ButtonFilterMobile.vue";
import ChipSelectable from "@/components/shared/ChipSelectable/ChipSelectable.vue";
import DatePicker from "@/components/shared/DatePicker/DatePicker.vue";
import { useButtonFilterDate } from "./useButtonFilterDateMobile";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonFilterDateMobile',
  setup(__props) {

const { t } = useI18n();
const {
  isFilterActive,
  dialogModel,
  datePickerModel,
  cancelDatePicker,
  submitDatePicker,
  submitButtonFilter,
  clearAllFilter,
  selectedTimePeriodMemory,
  timePeriodOptions,
} = useButtonFilterDate();

function closeBottomSheet() {
  submitButtonFilter();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ButtonFilterMobile, {
      label: _unref(t)(_unref(label).ButtonFilter.Date.title),
      active: _unref(isFilterActive),
      height: 40,
      onCloseFilter: closeBottomSheet,
      onClearFilter: _unref(clearAllFilter)
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h1", _hoisted_2, _toDisplayString(_unref(t)(_unref(label).ButtonFilter.Date.title)), 1),
          _createVNode(ChipSelectable, {
            modelValue: _unref(selectedTimePeriodMemory),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(selectedTimePeriodMemory) ? (selectedTimePeriodMemory).value = $event : null)),
            chips: _unref(timePeriodOptions),
            isMobile: ""
          }, null, 8, ["modelValue", "chips"])
        ])
      ]),
      _: 1
    }, 8, ["label", "active", "onClearFilter"]),
    (_unref(dialogModel))
      ? (_openBlock(), _createBlock(DatePicker, {
          key: 0,
          dialog: _unref(dialogModel),
          "onUpdate:dialog": _cache[1] || (_cache[1] = ($event: any) => (_isRef(dialogModel) ? (dialogModel).value = $event : null)),
          dates: _unref(datePickerModel),
          onCancel: _unref(cancelDatePicker),
          onSubmit: _unref(submitDatePicker),
          range: ""
        }, null, 8, ["dialog", "dates", "onCancel", "onSubmit"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})