<template>
  <ButtonFilterTextMobile
    v-model:input="inputValue"
    :options="suggestionBoxListFiltered"
    :showOptions="suggestionBoxListFiltered.length > 0 && inputValue.length > 3"
    :loadingSuggestions="loadingSuggestionBox"
    :label="t(label.ButtonFilter.Store.title)"
    :placeholder="t(label.ButtonFilter.Store.placeholder)"
    @submit="
      (value) => {
        $patch({ filters: { entityIds: value.map((chip) => chip.label) } });
        $patch({ offset: 20, page: 1 });
      }
    "
    @clear="
      () => {
        $patch({ filters: { entityIds: [] } });
        $patch({ offset: 20, page: 1 });
      }
    "
    name="filter-store"
  />
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useDebounceFn } from "@vueuse/core";

import ButtonFilterTextMobile from "@/components/shared/ButtonFilterTextMobile/ButtonFilterTextMobile.vue";
import { getSuggestionAPIPOST } from "@/api/resources/Transactions/transactionResource";
import { useTransactionStore } from "@/store/TransactionStore";
import { Chip } from "@/model/chip";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";

const { t } = useI18n();
const TIME_DEBOUNCE_INPUT_VALUE = 500;

const inputValue = ref("");
const suggestionBoxListFiltered = ref<Chip[]>([]);
const loadingSuggestionBox = ref(false);

const { $patch } = useTransactionStore();

const debounceInputValue = useDebounceFn((value: string) => {
  getSuggestionAPIPOST({ type: 2, limit: 10, value })
    .then((data) => {
      suggestionBoxListFiltered.value = data.result.map((r) => ({
        chipId: r.id,
        label: r.subValue ? `${r.value} - ${r.subValue}` : r.value,
      }));
    })
    .finally(() => {
      loadingSuggestionBox.value = false;
    });
}, TIME_DEBOUNCE_INPUT_VALUE);

watch(inputValue, (value) => {
  if (value.length > 3) {
    loadingSuggestionBox.value = true;
    debounceInputValue(value);
  }
});
</script>
