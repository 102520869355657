import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-testid"]
const _hoisted_2 = ["data-testid"]

import { generateTestId } from "@/helpers/generateTestId";
import { ContainerFixedTopElements } from "./constants/ContainerFixedTopId";
import { useContainerFixedTop } from "./useContainerFixedTop";



export default /*@__PURE__*/_defineComponent({
  __name: 'ContainerFixedTop',
  props: {
    name: {}
  },
  emits: ["handleTop"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const { containerFixedTop } = useContainerFixedTop(emit);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    style: {
      position: 'sticky',
      top: 0,
      width: '100%',
    },
    class: "bg-cs_background",
    "data-testid": 
      _unref(generateTestId)(_unref(ContainerFixedTopElements).ContainerFixedTop, _ctx.name)
    ,
    ref_key: "containerFixedTop",
    ref: containerFixedTop
  }, [
    _createElementVNode("div", {
      "data-testid": 
        _unref(generateTestId)(_unref(ContainerFixedTopElements).ContainerFixedTopContent, _ctx.name)
      
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_2)
  ], 8, _hoisted_1))
}
}

})