<template>
  <div
    :class="['order-history', displaySizeClass]"
    style="padding-bottom: 40px"
    :data-testid="generateTestId(TransactionHistoryElements.Id)"
  >
    <h2 class="on-surface-variant">
      {{ t(label.TransactionDetailsPage.DetailTransactionTabs.History.title) }}
    </h2>
    <section class="elevation-1 rounded-xl pa-6">
      <JoinedList :list="orderList"></JoinedList>
    </section>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useDisplay, useTheme } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { JoinedListItem } from "@/components/shared/JoinedList/useJoinedList";
import JoinedList from "@/components/shared/JoinedList/JoinedList.vue";
import { TransactionHistoryElements } from "./constants/TransactionHistoryElements";
import { ITransactionDetails_History } from "@/api/resources/Transactions/transactionResource";
import { label } from "@/plugins/locales/global";
import { useI18n } from "vue-i18n";

const props = defineProps<{ history?: ITransactionDetails_History[] }>();
const { t } = useI18n();
const { name: theme } = useTheme();
const { mdAndUp } = useDisplay();

const orderList = computed(() => {
  const list = [];

  list.push({
    label:
      label.TransactionDetailsPage.DetailTransactionTabs.History
        .TransactionReceived,
    classLabel: "default",
  });

  props.history?.map((item) => list.push(item));

  return list as JoinedListItem[];
});

const displaySizeClass = computed(() => (mdAndUp.value ? "md" : "sm"));
</script>

<style lang="scss">
.order-history {
  &.sm {
    font-size: var(--v-typescale-body-medium-size);

    h2 {
      font-size: var(--v-typescale-title-medium-size);
      font-weight: var(--v-typescale-title-small-weight);
      margin-left: 8px;
      margin-bottom: 4px;
    }
  }

  &.md {
    font-size: var(--v-typescale-body-large-size);

    h2 {
      font-size: var(--v-typescale-title-large-size);
      font-weight: var(--v-typescale-title-large-weight);
      margin-left: 16px;
      margin-bottom: 8px;
    }
  }
}

$colors: (
  "success" rgb(var(--v-theme-success)),
  "warning" rgb(var(--v-theme-warning)),
  "error" rgb(var(--v-theme-error)),
  "info" rgb(var(--v-theme-information))
);

@each $colorKey, $colorValue in $colors {
  .cs_joined-list.#{$colorKey}:before {
    background: $colorValue;
  }

  .cs_joined-list__item__text.#{$colorKey} {
    color: $colorValue;
  }

  .cs_joined-list.#{$colorKey}:after {
    background: $colorValue;
  }
}

// #region default values
.cs_joined-list.default.light:before {
  background: rgb(var(--mdui-ref-palette-neutral50));
}
.cs_joined-list.default.dark:before {
  background: rgb(var(--mdui-ref-palette-neutral60));
}

.cs_joined-list__item__text.default.light {
  color: rgb(var(--mdui-ref-palette-neutral50));
}
.cs_joined-list__item__text.default.dark {
  color: rgb(var(--mdui-ref-palette-neutral60));
}

.cs_joined-list.default.light:after {
  background: rgb(var(--mdui-ref-palette-neutral50));
}
.cs_joined-list.default.dark:after {
  background: rgb(var(--mdui-ref-palette-neutral60));
}
// #endregion
</style>
