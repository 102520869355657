import { http, HttpResponse } from "msw";
import { suggestionTransactionIDsDB, suggestionTransactionStoreDB, transactionDetailsDB, transactionsDB } from "./transactionDBMock";
import {
  ITransaction,
  RequestDataSuggestion,
  RequestDataTransactionList,
  SuggestionItem,
} from "@/api/resources/Transactions/transactionResource";
import { BASE_URL } from "@/api/resources/Transactions/transactionResource";


export const handlersHTTP = [
  http.get(BASE_URL + "/shield/total-transactions-status", async () => {
    const countStatus = transactionsDB.reduce((acc, transaction) => {
      acc[transaction.status] = acc[transaction.status]
        ? acc[transaction.status] + 1
        : 1;
      return acc;
    }, {} as Record<number, number>);

    const valueTransactionStatus = transactionsDB.reduce((acc, transaction) => {
      acc[transaction.status] = acc[transaction.status]
        ? acc[transaction.status] + transaction.totalAmount
        : transaction.totalAmount;
      return acc;
    }, {} as Record<number, number>);

    const response = {
      countStatus,
      valueTransactionStatus,
    };

    return HttpResponse.json(response);
  }),

  http.post(BASE_URL + "/shield/transactions", async ({ request }) => {
    const requestData = (await request.json()) as RequestDataTransactionList;
    const page = requestData.page ?? 1;
    const offset = requestData.offset ?? 10;

    const startIndex = (page - 1) * offset;
    const endIndex = startIndex + offset;

    let filtered: ITransaction[] = transactionsDB;

    if (requestData.dateFrom && requestData.dateTo) {
      const fromDate = new Date(requestData.dateFrom);
      const toDate = new Date(requestData.dateTo);

      filtered = filtered.filter((transaction) => {
        const transactionDate = new Date(transaction.consumerRequestedAt);

        transactionDate.setHours(0, 0, 0, 0);
        fromDate.setHours(0, 0, 0, 0);
        toDate.setHours(0, 0, 0, 0);

        return transactionDate >= fromDate && transactionDate <= toDate;
      });
    }

    if (requestData.codes.length > 0) {
      filtered = filtered.filter((transaction) => {
        return requestData.codes.includes(transaction.code);
      })
    }

    if (requestData.entityIds.length > 0) {
      filtered = filtered.filter((transaction) =>
        requestData.entityIds.includes(transaction.entityName)
      );
    }

    if (requestData.status.length > 0) {
      filtered = filtered.filter((transaction) => {
        return requestData.status.includes(transaction.status);
      })
    }

    if (requestData.amountFrom || requestData.amountTo) {
      const fromAmount = requestData.amountFrom
      const toAmount = requestData.amountTo


      filtered = filtered.filter((transaction) => {
        const totalAmount = transaction.totalAmount

        if (requestData.amountFrom) {
          if (totalAmount < fromAmount) {
            return false;
          }
        }

        if (requestData.amountTo) {
          if (totalAmount > toAmount) {
            return false;
          }
        }
        return true;
      });
    }

    const transactionsPage = filtered.slice(startIndex, endIndex);
    const finished = endIndex >= filtered.length;

    const response = {
      result: transactionsPage,
      page,
      offset,
      total: filtered.length,
      finished,
    };


    return HttpResponse.json(response);
  }),

  http.get(BASE_URL + "/shield/transactions/:id", async ({ params }) => {
    const { id } = params;

    const filteredResult = transactionDetailsDB.find(t => t.summary.id == id);

    return HttpResponse.json(filteredResult);

  }),

  http.post(BASE_URL + "/shield/suggestions", async ({ request }) => {
    const requestData = (await request.json()) as RequestDataSuggestion;
    const offset = requestData.limit ?? 10;
    const requestValue = requestData.value.toLowerCase();

    let result: SuggestionItem[] = [];

    function generateSuggestion(store: SuggestionItem[]) {
      result = store
        .filter((suggestion) => suggestion.value.toLowerCase().includes(requestValue))
        .slice(0, offset)
        .map((suggestion) => ({ id: suggestion.id, value: suggestion.value, subValue: suggestion.subValue }));
    }

    if (requestData.type === 1) {
      generateSuggestion(suggestionTransactionIDsDB)
    }

    if (requestData.type === 2) {
      generateSuggestion(suggestionTransactionStoreDB)
    }

    const response = { result };

    return HttpResponse.json(response);
  }),

  http.get(BASE_URL + '/one/users/apps', async () => {
    return HttpResponse.json({
      results: ["One", "Shield", "Nexus"]
    })
  })
];
