<template>
  <v-card
    id="summary-card"
    :data-testid="generateTestId(SummaryCardElement)"
    @click="$emit('click', { ...$props })"
    :class="[themeName, classTheme[status], backgroundImage]"
    :image="backgroundImage"
    variant="flat"
  >
    <v-card-text
      :style="{
        display: 'flex',
        flexDirection: 'column',
        'justify-content': 'end',
        minWidth: width,
        height: height,
      }"
    >
      <slot />
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { withDefaults } from "vue";
import { useTheme } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { SummaryCardElement } from "./constants/SummaryCardId";
import { ITransactionStatus } from "@/api/resources/Transactions/transactionResource";
import { useClassByThemeStatus } from "@/components/hooks/useClassByTheme";

type Props = {
  status?: ITransactionStatus;
  justify?: "flex-start" | "center" | "flex-end";
  width?: `${number}px`;
  height?: `${number}px`;
  backgroundImage?: string;
};

type Events = {
  click: [Props];
};

withDefaults(defineProps<Props>(), {
  status: ITransactionStatus.Analyzing,
  justify: "flex-start",
  width: "120px",
  height: "120px",
  backgroundImage: "",
});

const classThemeWholeDefault = {
  [ITransactionStatus.Approved]: "bg-success-container text-success",
  [ITransactionStatus.Denied]: "bg-error-container text-error",
  [ITransactionStatus.Analyzing]: "bg-information-container text-information",
  [ITransactionStatus.Canceled]: "bg-surface-dim text-on-surface-variant",
  [ITransactionStatus.None]: "",
};

const classThemeWhole = {
  light: {
    ...classThemeWholeDefault,
  },
  dark: {
    ...classThemeWholeDefault,
    [ITransactionStatus.Denied]: "bg-ref-palette-error10 text-error",
    [ITransactionStatus.Analyzing]:
      "bg-ref-palette-information10 text-ref-palette-information",
    [ITransactionStatus.Canceled]:
      "bg-ref-palette-neutral20 text-ref-palette-neutral-variant80",
    [ITransactionStatus.None]: "",
  },
};

const classTheme = useClassByThemeStatus(classThemeWhole);

defineEmits<Events>();

const { name: themeName } = useTheme();
</script>

<style lang="scss" scoped>
#summary-card {
  // padding: 12px 0 12px 12px;

  box-sizing: border-box;

  border-radius: 28px !important;
  min-width: 120px;

  display: flex;
  flex-direction: column;
}

#summary-card {
  &.light {
    &.transaction {
      background-image: url("@/assets/backgrounds/transaction-mobile-light.png");
    }

    &.approved {
      background-color: rgb(var(--mdui-ref-palette-success90));
      color: rgb(var(--mdui-ref-palette-success50));
    }

    &.denied {
      background-color: rgb(var(--mdui-ref-palette-error90));
      color: rgb(var(--mdui-ref-palette-error30));
    }

    &.analyzing {
      background-color: rgb(var(--mdui-ref-palette-information80));
      color: rgb(var(--mdui-ref-palette-information40));
    }

    &.canceled {
      background-color: rgb(var(--mdui-ref-palette-neutral80));
      color: rgb(var(--mdui-ref-palette-neutral-variant30));
    }

    & p {
      color: rgb(var(--mdui-ref-palette-black));
    }
  }

  &.dark {
    &.transaction {
      background-image: url("@/assets/backgrounds/transaction-mobile-dark.png");
    }

    &.Approved {
      background-color: rgb(var(--mdui-ref-palette-success30));
      color: rgb(var(--mdui-ref-palette-success80));
    }

    &.Denied {
      background-color: rgb(var(--mdui-ref-palette-error15));
      color: rgb(var(--mdui-ref-palette-error70));
    }

    &.Analyzing {
      background-color: rgb(var(--mdui-ref-palette-information20));
      color: rgb(var(--mdui-ref-palette-information80));
    }

    &.Canceled {
      background-color: rgb(var(--mdui-ref-palette-neutral20));
      color: rgb(var(--mdui-ref-palette-neutral-variant80));
    }

    & p {
      color: rgb(var(--mdui-ref-palette-neutral90));
    }
  }
}
</style>
