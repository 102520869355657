import { ScrollTrigger } from "gsap/ScrollTrigger";
import { StyleValue, onMounted, ref, watch } from "vue";

export type WidthScrollContainerProps = {
  width?: string | number;
  height?: string | number;
  pinedStyle?: StyleValue
};

export type WidthScrollContainerEmits = { (e: "scrollOnTop", onTop: boolean): void }

export function useWidthScrollContainer(emits: WidthScrollContainerEmits, props: WidthScrollContainerProps) {
  const scrollToHeight = ref<string | undefined>(undefined);
  const scrollToWidth = ref<string | undefined>(undefined);

  const refPinnedContainer = ref<HTMLDivElement | null>(null);
  const pinnedContainerClass = "pinned-container";

  const refSlotContainer = ref<HTMLDivElement | null>(null);
  const slotContainerClass = "slot-container";

  watch(
    () => props,
    (value) => {
      setScrollToSize(value);
    },
    { deep: true, immediate: true }
  );

  onMounted(() => {
    const t = setTimeout(() => {
      attachScrollAnimation();
      clearTimeout(t);
    }, 500);
  });

  function setScrollToSize({ width, height }: WidthScrollContainerProps) {
    const size = (
      refSize: typeof scrollToWidth | typeof scrollToHeight,
      value: typeof width | typeof height
    ) => {
      if (value) {
        if (
          typeof value == "number" ||
          (typeof value == "string" && !Number.isNaN(Number(value)))
        ) {
          refSize.value = `${value}px`;
        } else if (typeof value == "string") {
          refSize.value = value;
        }
      }
    };

    size(scrollToWidth, width);
    size(scrollToHeight, height);
  }

  function buildToggleClass(): string[] {
    const baseClass = []
    if (scrollToWidth.value) baseClass.push(`${slotContainerClass}--width`);
    if (scrollToHeight.value) baseClass.push(`${slotContainerClass}--height`);
    if (baseClass.length > 0) baseClass.push(`${slotContainerClass}--pinned`);
    return baseClass;
  }

  function attachScrollAnimation() {
    if (refPinnedContainer.value && refSlotContainer.value) {
      const classes = buildToggleClass();

      ScrollTrigger.create({
        start: "top top",
        end: () => document.body.scrollHeight,
        markers: false,
        pin: true,
        pinSpacing: false,
        onToggle: (self) => emits("scrollOnTop", self.isActive),
        onEnter: () => {
          if (refSlotContainer.value) {
            refSlotContainer.value.classList.add(...classes);
          }
        },
        onLeaveBack: () => {
          if (refSlotContainer.value) {
            refSlotContainer.value.classList.remove(...classes);
          }
        },
        trigger: refPinnedContainer.value,
      });
    }
  }

  return {
    scrollToHeight,
    scrollToWidth,
    buildToggleClass,
    refPinnedContainer,
    pinnedContainerClass,
    refSlotContainer,
    slotContainerClass
  }
}
