<template>
  <div
    style="position: relative"
    :data-testid="
      generateTestId(ButtonFilterTextConstants.ButtonFilterText, name ?? '')
    "
  >
    <v-menu
      close-on-content-click
      :open-on-click="false"
      v-model="openSuggestionBox"
    >
      <template v-slot:activator="{ props }">
        <ButtonFilter
          :label="label"
          @openFilter="
            () => {
              buttonOpen = true;
            }
          "
          @closeFilter="handleCloseButtonFilterText"
          @submit-filter="handleSubmitButtonFilterText"
          @clear-filter="handleClearButtonFilterText"
          :active="selectedChipList.length > 0"
        >
          <div
            class="d-flex align-items-center w-100"
            ref="containerRef"
            style="flex-wrap: nowrap; gap: 1rem"
          >
            <div
              v-if="!chipsGreaterThanInputContainer"
              class="d-flex align-items-center"
              style="flex-wrap: nowrap; gap: 1rem"
              ref="chipContainerRef"
              :data-testid="
                generateTestId(
                  ButtonFilterTextConstants.ButtonFilterTextSelectedItemsInput,
                  name ?? ''
                )
              "
            >
              <ChipRemovable
                v-for="suggestion in selectedChipListMemory"
                :key="suggestion.id"
                :chip-id="(suggestion.id as string)"
                :label="suggestion.value"
                @click="inputModel = suggestion.value"
                @remove-chip="
                  () => {
                    removeSelectedChipsListMemory(suggestion);
                  }
                "
                style="width: max-content"
              >
              </ChipRemovable>
            </div>

            <input
              style="width: 100%"
              type="text"
              v-bind="props"
              :placeholder="placeholder"
              v-model="inputModel"
              @blur="handleBlurInput"
              @focus="
                () => {
                  if (suggestionBoxListMemory.length > 0 && (inputModel as string).length){
                      openSuggestionBox = true;

                  }
                }
              "
              @keypress.enter="
                () => {
                  const regex = new RegExp(inputModel as string, 'i');
                  const suggestionFind = suggestionBoxListMemory.find(
                    (suggestion) => regex.test(suggestion.value.toLowerCase())
                  );
                  if (suggestionFind) handleAddSuggestion(suggestionFind);
                }
              "
              :data-testid="
                generateTestId(
                  ButtonFilterTextConstants.ButtonFilterTextInput,
                  name ?? ''
                )
              "
            />
          </div>
        </ButtonFilter>
      </template>

      <ButtonFilterTextSuggestionBox
        :show="openSuggestionBox!"
        :options="options"
        :loading-suggestions="loadingSuggestions"
        :name="name"
        :handle-add-suggestion="handleAddSuggestion"
      />
    </v-menu>

    <Transition mode="in-out">
      <v-card
        v-if="
          Boolean(
            buttonOpen &&
              chipsGreaterThanInputContainer &&
              selectedChipListMemory.length
          )
        "
        style="
          position: absolute;
          width: 100%;
          padding: 0.5rem 1.5rem;
          gap: 1rem;
          display: flex;
          flex-wrap: nowrap;
          top: 3rem;
        "
      >
        <div
          style="display: flex; flex-wrap: wrap; gap: 1rem"
          ref="chipsSelectedRef"
          :data-testid="
            generateTestId(
              ButtonFilterTextConstants.ButtonFilterTextSelectedItemsBox,
              name ?? ''
            )
          "
        >
          <ChipRemovable
            v-for="suggestion in selectedChipListMemory"
            :key="suggestion.id"
            :chip-id="(suggestion as any).id"
            :label="suggestion.value"
            @remove-chip="
              () => {
                handleRemoveSuggestion(suggestion);
              }
            "
          >
          </ChipRemovable>
        </div>
      </v-card>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref, watch, defineModel } from "vue";
import gsap from "gsap";

import ButtonFilter from "@/components/shared/ButtonFilter/ButtonFilter.vue";
import ChipRemovable from "@/components/shared/ChipRemovable/ChipRemovable.vue";

import {
  ButtonFilterTextEmits,
  useButtonFilterText,
} from "@/components/shared/ButtonFilterText/useButtonFilterText";
import { SuggestionItem } from "@/api/resources/Transactions/transactionResource";
import { generateTestId } from "@/helpers/generateTestId";
import { ButtonFilterTextConstants } from "./constants/ButtonFilterIDs";
import ButtonFilterTextSuggestionBox from "./ButtonFilterTextSuggestionBox.vue";

const emits = defineEmits<ButtonFilterTextEmits>();
const props = defineProps<{
  label: string;
  placeholder?: string;
  percentLimit: number;
  options: SuggestionItem[];
  loadingSuggestions: boolean;
  showOptions?: boolean;
  name?: string;
}>();

function handleBlurInput() {
  const time = setTimeout(() => {
    openSuggestionBox.value = false;

    clearTimeout(time);
  }, 200);
}

const inputModel = defineModel("input");

const TIME_ANIMATION_EXPANDED = 600;

const {
  selectedChipList,
  loadSelectedChipList,
  submitButtonFilterText,
  updateSelectedChipList,
  clearSelectedChipList,
} = useButtonFilterText(emits);

const {
  suggestionBoxList: suggestionBoxListMemory,
  selectedChipList: selectedChipListMemory,
  removeSelectedChipsList: removeSelectedChipsListMemory,
  addSelectedChipsList: addSelectedChipsListMemory,
  loadSelectedChipList: loadSuggestionChipListMemory,
  clearSelectedChipList: clearSuggestionsChipListMemory,
  updateSelectedChipList: updateSelectedChipListMemory,
  loadSuggestionBoxList: loadSuggestionBoxListMemory,
  clearSuggestionBoxList: clearSuggestionBoxListMemory,
} = useButtonFilterText(emits);

const openSuggestionBox = ref(false);
const buttonOpen = ref(false);

const chipsSelectedRef = ref<HTMLElement | null>(null);
const containerRef = ref<HTMLElement | null>(null);
const chipContainerRef = ref<HTMLElement | null>(null);
const chipsGreaterThanInputContainer = ref(false);

function handleCloseButtonFilterText() {
  buttonOpen.value = false;
  updateSelectedChipListMemory(selectedChipList);
}

function handleSubmitButtonFilterText(ev: () => void) {
  ev();
  updateSelectedChipList(selectedChipListMemory);
  submitButtonFilterText();
  buttonOpen.value = false;
}

function handleClearButtonFilterText() {
  clearSuggestionsChipListMemory();
  clearSelectedChipList();
  buttonOpen.value = false;
}

function setGreaterThanInputContainer(chipContainerWidth: number) {
  if (!containerRef.value || chipContainerWidth < 0) return;

  const containerWidth = containerRef.value.offsetWidth;

  const percentage = (chipContainerWidth * 100) / containerWidth;
  chipsGreaterThanInputContainer.value = isNaN(percentage)
    ? 0 > props.percentLimit
    : percentage > props.percentLimit;
}

async function handleAddSuggestion(suggestion: SuggestionItem) {
  addSelectedChipsListMemory(suggestion);
  inputModel.value = "";
  await nextTick();
  if (!containerRef.value || !chipContainerRef.value) return;

  const chipContainerWidth = chipContainerRef.value.offsetWidth;
  setGreaterThanInputContainer(chipContainerWidth);
}

async function handleRemoveSuggestion(suggestion: SuggestionItem) {
  removeSelectedChipsListMemory(suggestion);
  await nextTick();

  if (!containerRef.value || !chipsSelectedRef.value) return;

  const childrensArray = Array.from(chipsSelectedRef.value.children);
  const childrenFilterChips = childrensArray.filter((child) => {
    return child.className.includes("v-chip");
  });

  const reduceGetTotalWidth = childrenFilterChips.reduce(
    (acc, child) => acc + (child as HTMLElement).offsetWidth,
    0
  );

  const chipContainerWidth = reduceGetTotalWidth;

  setGreaterThanInputContainer(chipContainerWidth);
}

watch(buttonOpen, async () => {
  const root_properties = document.querySelector(":root") as HTMLElement;

  if (
    buttonOpen.value &&
    chipsGreaterThanInputContainer &&
    selectedChipListMemory.length
  ) {
    root_properties.style.setProperty("--chip-selected-box-delay", "500ms");

    if (buttonOpen.value) {
      const time = setTimeout(async () => {
        await nextTick();
        if (!containerRef.value || !chipContainerRef.value) return;

        const chipContainerWidth = chipContainerRef.value.offsetWidth;

        setGreaterThanInputContainer(chipContainerWidth);
        clearTimeout(time);
      }, TIME_ANIMATION_EXPANDED);
    }

    return;
  }
  root_properties.style.setProperty("--chip-selected-box-delay", "0ms");
  gsap.to(chipsSelectedRef.value, {
    opacity: 0,
    display: "flex",
    flexWrap: "nowrap",
    duration: 0.1,
  });
});

watch(
  () => props.options,
  (value) => {
    clearSuggestionBoxListMemory();
    loadSuggestionBoxListMemory(value);
  },
  {
    deep: true,
  }
);
watch(
  () => props.showOptions,
  (value) => {
    openSuggestionBox.value = value;
  }
);

watch(
  inputModel,
  () => {
    if ((inputModel.value as string).length === 0) {
      openSuggestionBox.value = false;
      return;
    }
    openSuggestionBox.value = true;
  },
  {
    immediate: true,
  }
);
onMounted(() => {
  loadSelectedChipList([]);
  loadSuggestionChipListMemory(selectedChipList);
});
</script>

<style lang="scss">
:root {
  --chip-selected-box-delay: 0s;
}
</style>

<style scoped lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
  transition-delay: var(--chip-selected-box-delay);
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

input {
  outline: none;
}
</style>
