<template>
  <div ref="refContainer" class="d-flex flex-1-1 align-start justify-start position-relative">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { Ref, onMounted, onUnmounted, provide, ref } from "vue";
import { useButtonFilterContainer } from "./hooks/useButtonFilterContainer";
import { ButtonFilterContainerAnimationContext } from "./constants/ButtonFilterContainer";

const refContainer = ref<Element>();
let context = ref<ButtonFilterContainerAnimationContext>();

provide('button-filter__container--context', context);

const { buildAnimationContext } = useButtonFilterContainer()

onMounted(() => {
  context.value = buildAnimationContext(refContainer as Ref<Element>)
});

onUnmounted(() => context.value?.revert());
</script>
