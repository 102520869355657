import { useTransactionStore } from "@/store/TransactionStore";
import { computed, ref } from "vue";

export function useButtonFilterValueMobile() {
  const store = useTransactionStore();

  const fromInput = ref("");
  const toInput = ref("");


  const isValid = computed(() => {
    return fromInput.value !== "" || toInput.value !== "";
  });

  function handleSubmitFilter(): void {
    store.$patch({ filters: { amountFrom: fromInput.value !== "" ? parseInt(fromInput.value) : 0, amountTo: toInput.value !== "" ? parseInt(toInput.value) : 0 } });
  }

  function handleClearAll(): void {
    fromInput.value = "";
    toInput.value = "";
    store.$patch({ filters: { amountFrom: 0, amountTo: 0 } });
  }

  return {
    fromInput,
    toInput,
    isValid,
    handleSubmitFilter,
    handleClearAll,
  };
}
