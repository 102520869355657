export const ButtonFilterBaseClass = "button-filter";

export const ButtonFilterContainerClass = `${ButtonFilterBaseClass}__container`;
export const ButtonFilterSlotContainerClass = `${ButtonFilterBaseClass}__slot-container`;
export const ButtonFilterLabelClass = `${ButtonFilterBaseClass}__label`;
export const ButtonFilterIconNormalClass = `${ButtonFilterBaseClass}__icon--normal`;
export const ButtonFilterIconActiveClass = `${ButtonFilterBaseClass}__icon--active`;
export const ButtonFilterButtonSubmitClass = `${ButtonFilterBaseClass}__button--submit`;
export const ButtonFilterButtonCloseClass = `${ButtonFilterBaseClass}__button--close`;
export const ButtonFilterButtonClearClass = `${ButtonFilterBaseClass}__button--clear`;
