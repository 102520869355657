import { StyleValue } from "vue";
import { tokens } from '@clearsale/tokens-ds'

const ButtonFilterValueInput = {
  display: "flex",
  lineHeight: tokens.typography["typescale-body-medium-line-height"],
  gap: "0.5rem",
};

const ButtonFilterValueContent: StyleValue = {
  display: "flex",
  flexDirection: "row",
  fontSize: tokens.typography["typescale-body-medium-size"],
  lineHeight: tokens.typography["typescale-body-medium-line-height"],
};

export const ButtonFilterValueStyle = {
  ButtonFilterValueInput,
  ButtonFilterValueContent,
};
