import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { style: {"display":"flex","gap":"16px","align-items":"center"} }

import ButtonFilter from "@/components/shared/ButtonFilter/ButtonFilter.vue";
import ChipSelectable from "@/components/shared/ChipSelectable/ChipSelectable.vue";
import DatePicker from "@/components/shared/DatePicker/DatePicker.vue";
import ButtonDatePicker from "@/components/shared/ButtonDatePicker/ButtonDatePicker.vue";

import { useButtonFilterDate } from "./useButtonFilterDate";
import { useTransactionStore } from "@/store/TransactionStore";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonFilterDate',
  setup(__props) {

const { t,locale } = useI18n();
const { $state } = useTransactionStore();
const {
  isFilterActive,
  isCustomFilterActive,
  cancelDatePicker,
  clearAllFilter,
  datePickerModel,
  dialogModel,
  selectedTimePeriodModel,
  submitButtonFilter,
  submitDatePicker,
  timePeriodOptions,
  handleCloseFilter,
  handleClickCustom,
} = useButtonFilterDate();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ButtonFilter, {
    label: _unref(t)(_unref(label).ButtonFilter.Date.title),
    onSubmitFilter: _unref(submitButtonFilter),
    onClearFilter: _unref(clearAllFilter),
    onCloseFilter: _unref(handleCloseFilter),
    active: _unref(isFilterActive)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(ChipSelectable, {
          chips: _unref(timePeriodOptions),
          modelValue: _unref(selectedTimePeriodModel),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(selectedTimePeriodModel) ? (selectedTimePeriodModel).value = $event : null))
        }, null, 8, ["chips", "modelValue"]),
        _createVNode(ButtonDatePicker, {
          active: _unref(isCustomFilterActive),
          onClick: _unref(handleClickCustom)
        }, null, 8, ["active", "onClick"])
      ]),
      (_unref(dialogModel))
        ? (_openBlock(), _createBlock(DatePicker, {
            key: 0,
            dialog: _unref(dialogModel),
            "onUpdate:dialog": _cache[1] || (_cache[1] = ($event: any) => (_isRef(dialogModel) ? (dialogModel).value = $event : null)),
            dates: _unref(datePickerModel),
            onCancel: _unref(cancelDatePicker),
            onSubmit: _unref(submitDatePicker),
            range: ""
          }, null, 8, ["dialog", "dates", "onCancel", "onSubmit"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["label", "onSubmitFilter", "onClearFilter", "onCloseFilter", "active"]))
}
}

})