const TransactionDetails_Contacts = "transaction-details_contacts";

export const TransactionDetails_Contacts_Billing = `${TransactionDetails_Contacts}_billing`;
export const TransactionDetails_Contacts_Shipping = `${TransactionDetails_Contacts}_shipping`;

export const TransactionDetails_Contacts_Properties = {
  Name: 'name',
  Phone: 'phone',
  AddressLine1: 'addressLine1',
  AddressLine2: 'addressLine2',
  City: 'city',
  Country: 'country',
  ZipCode: 'zipCode',
  Title: 'title',
  Value: 'value',
  Card: 'card',
}
