<template>
  <v-dialog v-model="dialogModel">
    <div class="date">
      <v-date-picker
        color="primary"
        bg-color="surface-container"
        v-model="dateModel"
        :multiple="range ? 'range' : false"
        :month="month"
        :year="year"
      >
        <template v-slot:header>
          <div class="date__content">
            <div v-if="showToDate.length === 0">Select date</div>

            <template
              v-for="(date, index) in mapFormatDate(showToDate, true)"
              :key="date"
            >
              <template v-if="index === 0">
                {{ date }}
              </template>
              <template v-else>
                <span> - </span>
                {{ date }}
              </template>
            </template>
          </div>
          <div
            class="bg-cs_outline_variant"
            style="width: 100%; height: 1px; display: block"
          ></div>
        </template>
        <template v-slot:actions>
          <v-btn @click="handleCancelDate">Cancel</v-btn>
          <v-btn @click="handleSubmit">Ok</v-btn>
        </template>
      </v-date-picker>
    </div>
  </v-dialog>
</template>

<script setup lang="ts">
import { defineModel } from "vue";
import { useDatepicker } from "./useDatepicker";

const props = defineProps<{
  range?: boolean;
  dates?: Date[];
  year?: number;
  month?: number;
}>();

const emits = defineEmits<{
  (e: "cancel"): void;
  (e: "submit", date: Date[]): void;
}>();

const dialogModel = defineModel<boolean>("dialog");

const { dateModel, mapFormatDate, handleCancelDate, handleSubmit, showToDate } =
  useDatepicker(props, emits);
</script>

<style lang="scss" scoped>
.date__content {
  padding: 0px 16px 0px 24px;
  background-color: rgb(var(--v-theme-surface-container)) !important;
  color: rgb(var(--v-theme-on-surface)) !important;
  font-size: var(--mdui-typescale-headline-small-size);
}
.date {
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
}
.date::v-deep(.v-picker) {
  border-radius: 24px;
  padding-top: 16px;
  width: 100%;
}

.date::v-deep .v-picker-title {
  background-color: rgb(var(--v-theme-surface-container)) !important;
  color: rgb(var(--v-theme-on-surface)) !important;
}

.date::v-deep(.v-date-picker-header) {
  background-color: rgb(var(--v-theme-surface-container)) !important;
  color: rgb(var(--v-theme-on-surface)) !important;
}

.date::v-deep(.v-date-picker-month) {
  .v-date-picker-month__day button {
    background-color: rgb(var(--v-theme-surface-container)) !important;
  }

  .v-date-picker-month__day--selected button {
    background-color: rgb(var(--v-theme-primary)) !important;
  }
}

.date::v-deep(.v-date-picker-controls) {
  * {
    color: rgb(var(--v-theme-on-surface)) !important;
  }
}

.date::v-deep(.v-date-picker-months__content) {
  .v-btn {
    color: rgb(var(--v-theme-on-surface)) !important;

    &.v-btn--active {
      color: rgb(var(--v-theme-on-primary)) !important;
    }
  }
}

.date::v-deep(.v-date-picker-years__content) {
  .v-btn {
    color: rgb(var(--v-theme-on-surface)) !important;

    &.v-btn--active {
      color: rgb(var(--v-theme-on-primary)) !important;
    }
  }
}
</style>
