import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, watch } from "vue";
import { useDebounceFn } from "@vueuse/core";

import ButtonFilterTextMobile from "@/components/shared/ButtonFilterTextMobile/ButtonFilterTextMobile.vue";
import { getSuggestionAPIPOST } from "@/api/resources/Transactions/transactionResource";
import { useTransactionStore } from "@/store/TransactionStore";
import { Chip } from "@/model/chip";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";

const TIME_DEBOUNCE_INPUT_VALUE = 500;


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonFilterIDMobile',
  setup(__props) {

const { t } = useI18n();
const inputValue = ref("");
const suggestionBoxListFiltered = ref<Chip[]>([]);
const loadingSuggestionBox = ref(false);

const { $patch } = useTransactionStore();

const debounceInputValue = useDebounceFn((value: string) => {
  getSuggestionAPIPOST({ type: 1, limit: 10, value })
    .then((data) => {
      suggestionBoxListFiltered.value = data.result.map((r) => ({
        chipId: r.id,
        label: r.subValue ? `${r.value} - ${r.subValue}` : r.value,
      }));
    })
    .finally(() => {
      loadingSuggestionBox.value = false;
    });
}, TIME_DEBOUNCE_INPUT_VALUE);

watch(inputValue, (value) => {
  if (inputValue.value !== "") {
    loadingSuggestionBox.value = true;
    debounceInputValue(value);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ButtonFilterTextMobile, {
    input: inputValue.value,
    "onUpdate:input": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
    options: suggestionBoxListFiltered.value,
    loadingSuggestions: loadingSuggestionBox.value,
    label: _unref(t)(_unref(label).ButtonFilter.ID.title),
    placeholder: _unref(t)(_unref(label).ButtonFilter.ID.placeholder),
    showOptions: suggestionBoxListFiltered.value.length > 0,
    onSubmit: _cache[1] || (_cache[1] = 
      (value) => {
        _unref($patch)({ filters: { ids: value.map((chip) => chip.label) } });
        _unref($patch)({ paging: { page: 1, limit: 20 } });
      }
    ),
    onClear: _cache[2] || (_cache[2] = 
      () => {
        _unref($patch)({ filters: { ids: [] } });
        _unref($patch)({ paging: { page: 1, limit: 20 } });
      }
    ),
    name: "filter-id"
  }, null, 8, ["input", "options", "loadingSuggestions", "label", "placeholder", "showOptions"]))
}
}

})