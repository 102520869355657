<template>
  <v-chip
    :data-testeid="generateTestId(ChipRemovableElements.ChipRemovableContent)"
    :closable="true"
    :close-icon="ChipRemovableElements.ChipRemoveIcon"
    style="font-weight: bold"
    variant="outlined"
    class="rounded-lg on-surface-variant"
    @click:close="handleRemoveChip(id)"
  >
    {{ label }}
  </v-chip>
</template>

<script lang="ts" setup>
import { ref, defineProps } from "vue";
import { generateTestId } from "@/helpers/generateTestId";
import { ChipRemovableElements } from "./constants/ChipRemovableElements";
import {
  RemoveChipEmits,
  useRemoveChip,
} from "./constants/ChipRemovableEvents";

const props = defineProps({
  label: String,
  chipId: { type: String, required: true },
});

let id = ref(props.chipId);

const emit = defineEmits<RemoveChipEmits>();

const removeChip = useRemoveChip(emit);

const handleRemoveChip = (id: string) => removeChip.handleRemoveChip(id);
</script>
