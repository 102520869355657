<template>
  <div>
    <template v-if="anyProperties(billing)">
      <h2
        :data-testid="
          generateTestId(
            TransactionDetails_Contacts_Billing,
            TransactionDetails_Contacts_Properties.Title
          )
        "
        class="on-surface-variant font-weight-medium pl-2 pb-1"
      >
        {{
          t(
            label.TransactionDetailsPage.DetailTransactionTabs.Contacts.Billing
              .title
          )
        }}
      </h2>
      <v-card
        :data-testid="
          generateTestId(
            TransactionDetails_Contacts_Billing,
            TransactionDetails_Contacts_Properties.Card
          )
        "
        color="transparent"
        class="mb-2"
      >
        <template #text>
          <div id="content">
            <div
              v-if="billing?.phone"
              id="row"
              class="d-flex align-start justify-space-between mb-2"
            >
              <span :class="titleComputedClass">{{
                t(
                  label.TransactionDetailsPage.DetailTransactionTabs.Contacts
                    .Phone
                )
              }}</span>
              <span
                :data-testid="
                  generateTestId(
                    TransactionDetails_Contacts_Billing,
                    TransactionDetails_Contacts_Properties.Phone
                  )
                "
                :class="contentComputedClass"
                >{{ billing?.phone }}</span
              >
            </div>
            <div id="row" class="d-flex ga-2 align-start justify-space-between">
              <span :class="titleComputedClass" class="flex-1-0">
                {{
                  t(
                    label.TransactionDetailsPage.DetailTransactionTabs.Contacts
                      .Address
                  )
                }}
              </span>
              <div :class="contentComputedClass">
                <p
                  v-if="billing?.name"
                  :data-testid="
                    generateTestId(
                      TransactionDetails_Contacts_Billing,
                      TransactionDetails_Contacts_Properties.Name
                    )
                  "
                >
                  {{ billing?.name }}
                </p>
                <p
                  v-if="billing?.addressLine1"
                  :data-testid="
                    generateTestId(
                      TransactionDetails_Contacts_Billing,
                      TransactionDetails_Contacts_Properties.AddressLine1
                    )
                  "
                >
                  {{ billing?.addressLine1 }}
                </p>
                <p
                  v-if="billing?.addressLine2"
                  :data-testid="
                    generateTestId(
                      TransactionDetails_Contacts_Billing,
                      TransactionDetails_Contacts_Properties.AddressLine2
                    )
                  "
                >
                  {{ billing?.addressLine2 }}
                </p>
                <p
                  v-if="billing?.city || billing?.country"
                  :data-testid="
                    generateTestId(
                      TransactionDetails_Contacts_Billing,
                      TransactionDetails_Contacts_Properties.City,
                      TransactionDetails_Contacts_Properties.Country
                    )
                  "
                >
                  {{ fullCityCountry(billing) }}
                </p>
                <p
                  v-if="billing?.zipCode"
                  :data-testid="
                    generateTestId(
                      TransactionDetails_Contacts_Billing,
                      TransactionDetails_Contacts_Properties.ZipCode
                    )
                  "
                >
                  {{ billing?.zipCode }}
                </p>
              </div>
            </div>
          </div>
        </template>
      </v-card>
    </template>
    <template v-if="anyProperties(shipping)">
      <h2
        :data-testid="
          generateTestId(
            TransactionDetails_Contacts_Shipping,
            TransactionDetails_Contacts_Properties.Title
          )
        "
        class="on-surface-variant font-weight-medium pl-2 pb-1"
      >
        {{
          t(
            label.TransactionDetailsPage.DetailTransactionTabs.Contacts.Shipping
              .title
          )
        }}
      </h2>
      <v-card
        :data-testid="
          generateTestId(
            TransactionDetails_Contacts_Shipping,
            TransactionDetails_Contacts_Properties.Card
          )
        "
        color="transparent"
      >
        <template #text>
          <div id="content">
            <div
              v-if="shipping?.phone"
              id="row"
              class="d-flex align-start justify-space-between mb-2"
            >
              <span :class="titleComputedClass">{{
                t(
                  label.TransactionDetailsPage.DetailTransactionTabs.Contacts
                    .Phone
                )
              }}</span>
              <span
                :data-testid="
                  generateTestId(
                    TransactionDetails_Contacts_Shipping,
                    TransactionDetails_Contacts_Properties.Phone
                  )
                "
                :class="contentComputedClass"
                >{{ shipping?.phone }}</span
              >
            </div>
            <div id="row" class="d-flex ga-2 align-start justify-space-between">
              <span :class="titleComputedClass" class="flex-1-0">
                {{
                  t(
                    label.TransactionDetailsPage.DetailTransactionTabs.Contacts
                      .Address
                  )
                }}
              </span>
              <div :class="contentComputedClass">
                <p
                  v-if="shipping?.name"
                  :data-testid="
                    generateTestId(
                      TransactionDetails_Contacts_Shipping,
                      TransactionDetails_Contacts_Properties.Name
                    )
                  "
                >
                  {{ shipping?.name }}
                </p>
                <p
                  v-if="shipping?.addressLine1"
                  :data-testid="
                    generateTestId(
                      TransactionDetails_Contacts_Shipping,
                      TransactionDetails_Contacts_Properties.AddressLine1
                    )
                  "
                >
                  {{ shipping?.addressLine1 }}
                </p>
                <p
                  v-if="shipping?.addressLine2"
                  :data-testid="
                    generateTestId(
                      TransactionDetails_Contacts_Shipping,
                      TransactionDetails_Contacts_Properties.AddressLine2
                    )
                  "
                >
                  {{ shipping?.addressLine2 }}
                </p>
                <p
                  v-if="shipping?.city || shipping?.country"
                  :data-testid="
                    generateTestId(
                      TransactionDetails_Contacts_Shipping,
                      TransactionDetails_Contacts_Properties.City,
                      TransactionDetails_Contacts_Properties.Country
                    )
                  "
                >
                  {{ fullCityCountry(shipping) }}
                </p>
                <p
                  v-if="shipping?.zipCode"
                  :data-testid="
                    generateTestId(
                      TransactionDetails_Contacts_Shipping,
                      TransactionDetails_Contacts_Properties.ZipCode
                    )
                  "
                >
                  {{ shipping?.zipCode }}
                </p>
              </div>
            </div>
          </div>
        </template>
      </v-card>
    </template>
  </div>
</template>

<script setup lang="ts">
import { generateTestId } from "@/helpers/generateTestId";
import {
  TransactionDetails_Contacts_Billing,
  TransactionDetails_Contacts_Properties,
  TransactionDetails_Contacts_Shipping,
} from "./constants/TransactionDetailsContactsContentMobileIds";
import { useTransactionDetailsContacts } from "./hooks/useTransactionDetailsContacts";
import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import { TransactionDetailsContactsProps } from "@/model/transactionDetailsContacts";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";

const { t } = useI18n();

defineProps<TransactionDetailsContactsProps>();

const titleClass = {
  light: "text-cs_ref_palette_neutral50",
  dark: "text-cs_ref_palette_neutral60",
};

const contentClass = {
  light: "text-cs_ref_palette_neutral_variant30",
  dark: "text-cs_ref_palette_neutral_variant80",
};

const titleComputedClass = useClassByTheme(titleClass);
const contentComputedClass = useClassByTheme(contentClass);

const { fullCityCountry, anyProperties } = useTransactionDetailsContacts();
</script>

<style scoped lang="scss">
p {
  text-align: end;
}
h2 {
  font-size: 1rem;
}
</style>
