import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-testid"]

import { useDisplay } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { CustomHeroElements } from "./constants/CustomHeroElements";
import { tokens } from "@clearsale/tokens-ds";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomHero',
  props: {
    label: {},
    srcImage: {}
  },
  setup(__props: any) {

const { mdAndUp } = useDisplay();

const fontSize = computed(() =>
  mdAndUp.value
    ? tokens.typography["typescale-display-large-size"]
    : tokens.typography["typescale-display-small-size"]
);
const lineHeight = computed(() =>
  mdAndUp.value
    ? tokens.typography["typescale-display-large-line-height"]
    : tokens.typography["typescale-display-small-line-height"]
);
const fontWeight = computed(() =>
  mdAndUp.value
    ? tokens.typography["typescale-display-large-weight"]
    : tokens.typography["typescale-display-small-weight"]
);



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    "data-testid": _unref(generateTestId)(_unref(CustomHeroElements).Content),
    id: "hero",
    style: _normalizeStyle({
      backgroundImage: _unref(mdAndUp) ? `url('${_ctx.srcImage}')` : `url('${_ctx.srcImage}')`,
      backgroundRepeat: 'no-repeat',
      padding: `${_unref(mdAndUp) ? '24px' : '12px 0'}`,
      backgroundSize: '150%',
      borderRadius: '28px',
    }),
    class: "h-100 w-100 position-relative"
  }, [
    _createElementVNode("h1", {
      class: "on-surface",
      style: _normalizeStyle({
        paddingLeft: !_unref(mdAndUp) && '12px',
        fontSize: fontSize.value,
        lineHeight: lineHeight.value,
        fontWeight: fontWeight.value,
      })
    }, _toDisplayString(_ctx.label), 5),
    _renderSlot(_ctx.$slots, "default")
  ], 12, _hoisted_1))
}
}

})