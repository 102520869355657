import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "cs_joined-list" }

import { useTheme } from "vuetify/lib/framework.mjs";
import { JoinedListItem } from "./useJoinedList";
import { useCardColors } from "@/components/hooks/useCardColors";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'JoinedList',
  props: {
    list: {}
  },
  setup(__props: any) {

const { t, d } = useI18n();
const { name: theme } = useTheme();



const { titleComputedClass } = useCardColors();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: index,
        class: _normalizeClass(["align-center ga-4", [
        'cs_joined-list',
        item.classLabel.length > 0 ? item.classLabel : 'default',
        _unref(theme),
      ]])
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(["cs_joined-list__item__text", item.classLabel]),
          style: {"text-align":"start"}
        }, _toDisplayString(_unref(t)(item.label)), 3),
        (item.date)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass([_unref(titleComputedClass), 'cs_joined-list__item__date'])
            }, _toDisplayString(_unref(d)(item.date, "long")), 3))
          : _createCommentVNode("", true)
      ], 2))
    }), 128))
  ]))
}
}

})