import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "action-bar__button-group" }

import { useI18n } from "vue-i18n";
import { ActionBarElements } from "./constants/ActionBarElements";
import ContainerSwiper from "@/components/shared/ContainerSwiper/ContainerSwiper.vue";
import { label } from "@/plugins/locales/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'ActionBar',
  props: {
    length: {},
    height: {}
  },
  setup(__props: any) {

const { t } = useI18n();


return (_ctx: any,_cache: any) => {
  const _component_v_checkbox_btn = _resolveComponent("v-checkbox-btn")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!

  return (_openBlock(), _createBlock(_component_v_toolbar, {
    class: "action-bar bg-information-container text-tertiary",
    rounded: "pill",
    height: _ctx.height
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_badge, {
        color: "error",
        "offset-y": "8",
        "offset-x": "6",
        content: _ctx.length,
        class: "action-bar__badge-content"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_checkbox_btn, {
            slot: "default",
            indeterminate: ""
          })
        ]),
        _: 1
      }, 8, ["content"]),
      _createVNode(ContainerSwiper, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(ActionBarElements).ActionBarButtonVariations, (item, index) => {
              return (_openBlock(), _createBlock(_component_v_btn, {
                key: index,
                variant: "text",
                "prepend-icon": item.icon,
                class: "action-bar__button-group__button"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)(`${_unref(label).Shared.TransactionActions._}.${item.label}`)), 1)
                ]),
                _: 2
              }, 1032, ["prepend-icon"]))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["height"]))
}
}

})