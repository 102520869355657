import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ActionItem, ActionTransactionType } from "@/model/actionsDetails";
import { onMounted, ref } from "vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useI18n } from "vue-i18n";
import { tokens } from "@clearsale/tokens-ds";
import { label } from "@/plugins/locales/global";

const pinnedContainerClass = "pinned-container";

const slotContainerClass = "slot-container";


export default /*@__PURE__*/_defineComponent({
  __name: 'ActionDetailsDesktop',
  props: {
    options: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();

const emits = __emit;

onMounted(() => {
  const t = setTimeout(() => {
    attachScrollAnimation();
    clearTimeout(t);
  }, 300);
});

const refPinnedContainer = ref<HTMLDivElement | null>(null);
const refSlotContainer = ref<HTMLDivElement | null>(null);
const fontSize = tokens.typography["typescale-title-large-size"];

function attachScrollAnimation() {
  if (refPinnedContainer.value && refSlotContainer.value) {
    ScrollTrigger.create({
      start: "top-=100px top",
      end: () => document.body.scrollHeight,
      markers: false,
      pin: true,
      pinSpacing: false,
      trigger: refPinnedContainer.value,
    });
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "refPinnedContainer",
    ref: refPinnedContainer,
    class: _normalizeClass(pinnedContainerClass)
  }, null, 512))
}
}

})