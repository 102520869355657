import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "d-flex align-center" }

import gsap from "@/plugins/gsap";
import CustomEase from "gsap/CustomEase";
import { computed, ref } from "vue";
import { label } from "@/plugins/locales/global";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonDatePicker',
  props: {
    active: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const { t, locale } = useI18n();

const selected = ref(false);

const props = __props;
const emits = __emit;

const active = computed(() => props.active);

function handleClick() {
  selected.value = true;
  emits("click");
}

function beforeEnterAnimation(el: Element) {
  gsap.set(el, {
    autoAlpha: 0,
    minWidth: 0,
    width: 0,
    minHeight: 0,
    height: 0,
  });
}

function enterAnimation(el: Element, done: () => void) {
  gsap
    .timeline({ defaults: { duration: 0.2 } })
    .to(el, {
      minWidth: 18,
      width: 18,
      minHeight: 18,
      height: 18,
      onComplete: done,
      ease: CustomEase.create("", "0.4, 0, 0.2, 1"),
    })
    .to(el, { autoAlpha: 1 }, "<50%");
}

function leaveAnimation(el: Element, done: () => void) {
  gsap
    .timeline({ defaults: { duration: 0.2 } })
    .to(el, { autoAlpha: 0 })
    .to(
      el,
      {
        minWidth: 0,
        width: 0,
        minHeight: 0,
        height: 0,
        onComplete: done,
        ease: CustomEase.create("", "0.4, 0, 0.2, 1"),
      },
      "<30%"
    );
}

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    variant: "outlined",
    rounded: "lg",
    height: "32",
    style: _normalizeStyle([{"text-transform":"none","letter-spacing":"0","transition":"all 0.2s cubic-bezier(0.4, 0, 0.2, 1)"}, {}]),
    color: "",
    class: _normalizeClass(["pr-3 font-weight-bold", {
      'bg-secondary-container border-none pl-2': active.value,
      'on-surface-variant pl-3': !active.value,
    }]),
    onClick: handleClick
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_Transition, {
          isCss: false,
          onBeforeEnter: beforeEnterAnimation,
          onEnter: enterAnimation,
          onLeave: leaveAnimation
        }, {
          default: _withCtx(() => [
            (active.value)
              ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode(" mdi-check ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createElementVNode("span", {
          style: {"transition":"all 0.2s cubic-bezier(0.4, 0, 0.2, 1)"},
          class: _normalizeClass({ 'ml-1': active.value })
        }, _toDisplayString(_unref(t)(_unref(label).ButtonFilter.Date.Custom)), 3)
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})