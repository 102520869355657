import { http, HttpResponse } from "msw";
import { suggestionTransactionIDsDB, suggestionTransactionStoreDB, transactionDetailsDB, transactionsDB } from "./transactionDBMock";
import {
  ITransaction,
  ITransactionStatus,
  RequestDataSuggestion,
  RequestDataTotalAmount,
  RequestDataTransactionList,
  SuggestionItem,
} from "@/api/resources/Transactions/transactionResource";
import { BASE_URL } from "@/api/resources/Transactions/transactionResource";


export const handlersHTTP = [
  http.post(BASE_URL + "/shield/transactions/totalamount", async ({ request }) => {

    const requestData = (await request.json()) as RequestDataTotalAmount

    let filtered: ITransaction[] = transactionsDB;

    const filtersDefault = {
      filters: requestData.filters ? {
        amountFrom: requestData.filters.amountFrom,
        amountTo: requestData.filters.amountTo,
        dateFrom: requestData.filters.dateFrom,
        dateTo: requestData.filters.dateTo,
        entityIds: requestData.filters.entityIds,
        ids: requestData.filters.ids,
        status: requestData.filters.status
      } : {
        amountFrom: 0,
        amountTo: 99999999999,
        dateFrom: 0,
        dateTo: new Date,
        entityIds: filtered.map((transaction) => transaction.entityName),
        ids: filtered.map((transaction) => transaction.id),
        status: filtered.map((transaction) => transaction.status)
      }
    }

    const filtersExist = !!filtersDefault.filters

    if (filtersExist) {
      const statusFilterExist = !!filtersDefault.filters.status
      const statusFilterHasContent = (filtersDefault.filters.status ?? []).length > 0

      if (statusFilterExist && statusFilterHasContent) {
        filtered = filtered.filter((transaction) => {
          return (filtersDefault.filters.status ?? []).includes(transaction.status);
        })
      }

      const entityIdsExist = !!filtersDefault.filters.entityIds
      const entityIdsFilterHasContent = (filtersDefault.filters.entityIds ?? []).length > 0

      if (entityIdsExist && entityIdsFilterHasContent) {
        filtered = filtered.filter((transaction) =>
          (filtersDefault.filters.entityIds ?? []).includes(transaction.entityName)
        );
      }

      const amountFromExist = !!filtersDefault.filters.amountFrom
      const amountToExist = !!filtersDefault.filters.amountTo

      if (amountFromExist || amountToExist) {
        const fromAmount = filtersDefault.filters.amountFrom
        const toAmount = filtersDefault.filters.amountTo


        filtered = filtered.filter((transaction) => {
          const totalAmount = transaction.totalAmount

          if (filtersDefault.filters.amountFrom) {
            if (fromAmount && totalAmount < fromAmount) {
              return false;
            }
          }

          if (filtersDefault.filters.amountTo) {
            if (toAmount && totalAmount > toAmount) {
              return false;
            }
          }
          return true;
        });
      }

      if (filtersDefault.filters.dateFrom && filtersDefault.filters.dateTo) {
        const fromDate = new Date(filtersDefault.filters.dateFrom);
        const toDate = new Date(filtersDefault.filters.dateTo);

        filtered = filtered.filter((transaction) => {
          const transactionDate = new Date(transaction.entityDate);

          transactionDate.setHours(0, 0, 0, 0);
          fromDate.setHours(0, 0, 0, 0);
          toDate.setHours(0, 0, 0, 0);

          return transactionDate >= fromDate && transactionDate <= toDate;
        });
      }
    }

    const summaryTransaction = {
      [ITransactionStatus.Approved]: 0,
      [ITransactionStatus.Denied]: 0,
      [ITransactionStatus.Analyzing]: 0,
      [ITransactionStatus.Canceled]: 0,
    } as Record<ITransactionStatus, number>;

    filtered.forEach((totalAmountItem) => {
      if (totalAmountItem.status === ITransactionStatus.Approved) {
        summaryTransaction[ITransactionStatus.Approved] +=
          totalAmountItem.totalAmount;
      }
      if (totalAmountItem.status === ITransactionStatus.Denied) {
        summaryTransaction[ITransactionStatus.Denied] +=
          totalAmountItem.totalAmount;
      }
      if (totalAmountItem.status === ITransactionStatus.Analyzing) {
        summaryTransaction[ITransactionStatus.Analyzing] +=
          totalAmountItem.totalAmount;
      }
      if (totalAmountItem.status === ITransactionStatus.Canceled) {
        summaryTransaction[ITransactionStatus.Canceled] +=
          totalAmountItem.totalAmount;
      }
    });

    const response = {
      results: [
        {
          totalAmount: summaryTransaction[ITransactionStatus.Approved],
          currency: 'USD',
          status: ITransactionStatus.Approved
        },
        {
          totalAmount: summaryTransaction[ITransactionStatus.Denied],
          currency: 'USD',
          status: ITransactionStatus.Denied
        },
        {
          totalAmount: summaryTransaction[ITransactionStatus.Analyzing],
          currency: 'USD',
          status: ITransactionStatus.Analyzing
        },
        {
          totalAmount: summaryTransaction[ITransactionStatus.Canceled],
          currency: 'USD',
          status: ITransactionStatus.Canceled
        },
      ]
    };

    return HttpResponse.json(response);
  }),

  http.post(BASE_URL + "/shield/transactions/search", async ({ request }) => {
    const requestData = (await request.json()) as RequestDataTransactionList;
    const page = requestData.paging.page ?? 1;
    const offset = requestData.paging.limit ?? 50;

    const startIndex = (page - 1) * offset;
    const endIndex = startIndex + offset;

    let filtered: ITransaction[] = transactionsDB;

    const filtersDefault = {
      filters: requestData.filters ? {
        amountFrom: requestData.filters.amountFrom,
        amountTo: requestData.filters.amountTo,
        dateFrom: requestData.filters.dateFrom,
        dateTo: requestData.filters.dateTo,
        entityIds: requestData.filters.entityIds,
        ids: requestData.filters.ids,
        status: requestData.filters.status
      } : {
        amountFrom: 0,
        amountTo: 0,
        dateFrom: new Date,
        dateTo: new Date,
        entityIds: [],
        ids: [],
        status: []
      }
    }

    if (filtersDefault.filters.dateFrom && filtersDefault.filters.dateTo) {
      const fromDate = new Date(filtersDefault.filters.dateFrom);
      const toDate = new Date(filtersDefault.filters.dateTo);

      filtered = filtered.filter((transaction) => {
        const transactionDate = new Date(transaction.entityDate);

        transactionDate.setHours(0, 0, 0, 0);
        fromDate.setHours(0, 0, 0, 0);
        toDate.setHours(0, 0, 0, 0);

        return transactionDate >= fromDate && transactionDate <= toDate;
      });
    }

    if ((filtersDefault.filters.ids ?? []).length > 0) {
      filtered = filtered.filter((transaction) =>
        (filtersDefault.filters.ids ?? []).includes(transaction.code)
      );
    }

    if ((filtersDefault.filters.entityIds ?? []).length > 0) {
      filtered = filtered.filter((transaction) =>
        (filtersDefault.filters.entityIds ?? []).includes(transaction.entityName)
      );
    }

    if (filtersDefault.filters.status && filtersDefault.filters.status.length > 0) {
      filtered = filtered.filter((transaction) => {
        return (filtersDefault.filters.status ?? []).includes(transaction.status);
      })
    }

    if (filtersDefault.filters.amountFrom || filtersDefault.filters.amountTo) {
      const fromAmount = filtersDefault.filters.amountFrom
      const toAmount = filtersDefault.filters.amountTo


      filtered = filtered.filter((transaction) => {
        const totalAmount = transaction.totalAmount

        if (filtersDefault.filters.amountFrom) {
          if (fromAmount && totalAmount < fromAmount) {
            return false;
          }
        }

        if (filtersDefault.filters.amountTo) {
          if (toAmount && totalAmount > toAmount) {
            return false;
          }
        }
        return true;
      });
    }

    const transactionsPage = filtered.slice(startIndex, endIndex);
    const finished = endIndex >= filtered.length;

    const response = {
      result: transactionsPage,
      page,
      offset,
      total: filtered.length,
      finished,
    };

    return HttpResponse.json(response);
  }),

  http.get(BASE_URL + "/shield/transactions/:id", async ({ params }) => {
    const { id } = params;

    const filteredResult = transactionDetailsDB.find(t => t.summary.id == id);

    return HttpResponse.json(filteredResult);

  }),

  http.post(BASE_URL + "/shield/suggestions", async ({ request }) => {
    const requestData = (await request.json()) as RequestDataSuggestion;
    const offset = requestData.limit ?? 10;
    const requestValue = requestData.value.toLowerCase();

    let result: SuggestionItem[] = [];

    function generateSuggestion(store: SuggestionItem[]) {
      result = store
        .filter((suggestion) => suggestion.value.toLowerCase().includes(requestValue))
        .slice(0, offset)
        .map((suggestion) => ({ id: suggestion.id, value: suggestion.value, subValue: suggestion.subValue }));
    }

    if (requestData.type === 1) {
      generateSuggestion(suggestionTransactionIDsDB)
    }

    if (requestData.type === 2) {
      generateSuggestion(suggestionTransactionStoreDB)
    }

    const response = { result };

    return HttpResponse.json(response);
  }),

  http.get(BASE_URL + '/one/users/apps', async () => {
    return HttpResponse.json({
      results: ["One", "Shield", "Nexus"]
    })
  })
];
