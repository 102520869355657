import { Chip } from "@/model/chip";

export type SelectChipEmits = (e: "selectChip", chip: Chip, index: number) => void;

export function useSelectChip(emits: SelectChipEmits) {

  function handleSelectChip(chip: Chip, index: number): void {
    emits("selectChip", chip, index);
  }

  return {
    handleSelectChip,
  };
}
