<template>
  <v-card
    id="card-item"
    class="elevation-1 pr-4 pl-2 py-2 bg-cs_background rounded-pill d-flex align-center justify-space-between"
    :class="[modelSelected ? backgroundName : className]"
    @mouseover="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div class="mr-5 d-flex align-center">
      <slot name="iconOrCheckbox" :data="{ item, isHovering, selectable }" />
    </div>
    <div
      class="flex-grow-1"
      style="display: grid; grid-template-rows: 32px; align-items: center"
      :style="`grid-template-columns: repeat(${columns}, 1fr)`"
    >
      <slot :data="{ item, isHovering, isSelected: modelSelected, actions }">
      </slot>
    </div>
  </v-card>
</template>

<script setup lang="ts" generic="T">
import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import { ref, watch } from "vue";

defineProps<{
  selectable?: boolean;
  columns?: number;
  icon?: { name?: string; color?: string; buttonColor?: string };
  actions?: boolean;
  item: T;
}>();

const modelSelected = defineModel<boolean>("selected");

const isHovering = ref(false);

const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};

const selectedBackgroundClass = {
  light: "bg-cs_ref_palette_information95",
  dark: "bg-cs_ref_palette_information30",
};

const className = useClassByTheme(classTheme);
const backgroundName = useClassByTheme(selectedBackgroundClass);

function handleMouseEnter() {
  isHovering.value = true;
}

function handleMouseLeave() {
  if (!modelSelected.value) isHovering.value = false;
}

watch(
  modelSelected,
  (value) => {
    if (value) {
      isHovering.value = true;
      return;
    }
    isHovering.value = false;
  },
  { immediate: true }
);
</script>
