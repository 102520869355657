const ButtonFilterText = "button-filter-text"
const ButtonFilterTextInput = `${ButtonFilterText}-input`;
const ButtonFilterTextSuggestionsBox = `${ButtonFilterText}-suggestions-box`;
const ButtonFilterTextSelectedItemsBox = `${ButtonFilterText}-selected-items-box`;
const ButtonFilterTextSelectedItemsInput = `${ButtonFilterText}-selected-items-input`;

export const ButtonFilterTextConstants = {
  ButtonFilterText,
  ButtonFilterTextInput,
  ButtonFilterTextSuggestionsBox,
  ButtonFilterTextSelectedItemsBox,
  ButtonFilterTextSelectedItemsInput
}
