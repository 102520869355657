import { Ref, toValue } from "vue";
import gsap from "gsap";
import { BottomSheetAnimation } from "./constants/BottomSheetAnimation";

export function useBottomSheetAnimation(bottomSheet: Ref<HTMLDivElement>, overlay: Ref<HTMLDivElement>, defaultBottomSheetHeight: number) {

  function onOpened(onComplete: () => void) {
    gsap.fromTo(toValue(overlay), { opacity: 0 }, { opacity: BottomSheetAnimation.OverlayOpacity, duration: BottomSheetAnimation.OpenTimeInSeconds });
    gsap.fromTo(toValue(bottomSheet), { height: 0 }, { height: defaultBottomSheetHeight, duration: BottomSheetAnimation.OpenTimeInSeconds, onComplete: onComplete });
  }

  function onClosed(onComplete: () => void) {
    gsap.fromTo(toValue(overlay), { opacity: BottomSheetAnimation.OverlayOpacity }, { opacity: 0, duration: BottomSheetAnimation.CloseTimeInSeconds });
    gsap.fromTo(toValue(bottomSheet), { height: bottomSheet.value.style.height }, { height: 0, duration: BottomSheetAnimation.CloseTimeInSeconds, onComplete: onComplete })
  }

  function reset() {
    gsap.fromTo(toValue(bottomSheet), { height: bottomSheet.value.style.height }, { height: defaultBottomSheetHeight });
  }

  return { onOpened, onClosed, reset }
}
