import { onMounted, onUnmounted, ref } from "vue";
export function useContainerFixedTop(emit: (e: "handleTop", isTop: boolean) => void) {
  const containerFixedTop = ref<HTMLDivElement | null>(null);

  const handleCheckScroll = () => {
    if (!containerFixedTop.value) return;

    const rect = containerFixedTop.value.getBoundingClientRect();
    if (rect.top <= 0) {
      // O elemento está no topo da página, adicione padding
      containerFixedTop.value.style.paddingTop = "16px";
      containerFixedTop.value.style.paddingBottom = "16px";
      emit("handleTop", true);
    } else {
      // O elemento não está no topo da página, remova padding
      containerFixedTop.value.style.paddingTop = "0";
      containerFixedTop.value.style.paddingBottom = "0";
      emit("handleTop", false);
    }
  }

  onMounted(() => {
    window.addEventListener("scroll", handleCheckScroll);
  });

  onUnmounted(() => {
    window.removeEventListener("scroll", handleCheckScroll);
  });

  return {
    containerFixedTop,
    handleCheckScroll
  }
}
