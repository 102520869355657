<template>
  <div
    ref="containerSwipeRef"
    class="container-swiper"
    tabindex="1"
    :data-testid="generateTestId(ContainerSwiperElements.ContainerSwiper, name)"
    :style="{ gap: gap ?? '24px' }"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";

import { generateTestId } from "@/helpers/generateTestId";
import { ContainerSwiperElements } from "./constants/ContainerSwiperId";
import { useContainerSwiper } from "./useContainerSwiper";

defineProps<{ name?: string; gap?: string }>();

const containerSwipeRef = ref<HTMLDivElement | null>(null);
const initialScroll = ref(0);

const { startEvent } = useContainerSwiper();

onMounted(() => {
  startEvent(initialScroll.value, containerSwipeRef);
});
</script>

<style lang="scss" scoped>
.container-swiper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
}
</style>
