const ButtonFilterTextMobile = "button-filter-text-mobile"
const ButtonFilterTextMobileInput = `${ButtonFilterTextMobile}-input`;
const ButtonFilterTextMobileSuggestionsBox = `${ButtonFilterTextMobile}-suggestions-box`;
const ButtonFilterTextMobileSelectedItemsBox = `${ButtonFilterTextMobile}-selected-items-box`;
const ButtonFilterTextMobileSelectedItemsInput = `${ButtonFilterTextMobile}-selected-items-input`;

export const ButtonFilterTextMobileConstants = {
  ButtonFilterTextMobile,
  ButtonFilterTextMobileInput,
  ButtonFilterTextMobileSuggestionsBox,
  ButtonFilterTextMobileSelectedItemsBox,
  ButtonFilterTextMobileSelectedItemsInput
}
