<template>
  <v-chip-group
    :data-testid="generateTestId(ChipSelectableElements.ChipGroupContent)"
    :selected-class="`bg-secondary-container border-none ${classSelectedByTheme}`"
    v-model="model"
    multiple
    column
  >
    <div class="overflow-y-auto" style="height: 120px">
      <v-chip
        v-for="(chip, index) in chips"
        :data-testid="
          generateTestId(
            (ChipSelectableElements.ChipGroupContent,
            `${ChipSelectableElements.ChipSelectableContent}-${chip.chipId}`)
          )
        "
        class="rounded-lg on-surface-variant"
        variant="outlined"
        style="font-weight: bold"
        :value="chip"
        :key="chip.chipId"
        filter
        :filter-icon="ChipSelectableElements.ChipSelectedIcon"
        @click="handleSelectChip(chip, index)"
      >
        {{ chip.label }}
      </v-chip>
    </div>
  </v-chip-group>
</template>

<script lang="ts" setup>
import { defineModel } from "vue";
import { generateTestId } from "@/helpers/generateTestId";
import { Chip } from "@/model/chip";
import { ChipSelectableElements } from "@/components/shared/ChipSelectable/constants/ChipSelectableElements";
import {
  SelectChipEmits,
  useSelectChip,
} from "@/components/shared/ChipSelectable/constants/ChipSelectableEvents";
import { useClassByTheme } from "@/components/hooks/useClassByTheme";

defineProps<{ chips: Chip[] }>();

const model = defineModel();

const emits = defineEmits<SelectChipEmits>();

const { handleSelectChip } = useSelectChip(emits);
const classSelectedByTheme = useClassByTheme({
  light: "text-shadow",
  dark: "",
});
</script>
