<template>
  <template v-if="innerActive">
    <div
      :data-testid="
        generateTestId(BottomSheetElements.BottomSheetOverlayElement)
      "
      class="overlay"
      ref="overlay"
      @click="activeModel = false"
    ></div>
    <section
      ref="bottomSheet"
      :style="{ height: heightPx }"
      class="bg-cs_background"
      :data-testid="
        generateTestId(BottomSheetElements.BottomSheetContentElement)
      "
    >
      <div class="handle-container" ref="handle">
        <div class="handle"></div>
      </div>
      <div class="pl-6 pr-6">
        <slot />
      </div>
    </section>
  </template>
</template>

<script setup lang="ts">
import {
  watch,
  defineModel,
  ref,
  Ref,
  onMounted,
  computed,
  onBeforeUnmount,
} from "vue";
import { BottomSheetElements } from "./constants/BottomSheetElements";
import { BottomSheetAnimation } from "./constants/BottomSheetAnimation";
import { generateTestId } from "@/helpers/generateTestId";
import Hammer from "hammerjs";
import { useBottomSheetAnimation } from "./useBottomSheetAnimation";
import { BottomSheetEvents } from "./constants/BottemSheetEvents";

const activeModel = defineModel<boolean>("active", { required: true });
const props = defineProps<{ height: number }>();
const emit = defineEmits([BottomSheetEvents.closed]);

const defaultHeight = computed(() => (props.height / 100) * window.innerHeight);
const innerActive = ref(activeModel.value);
const height = ref(0);
const heightPx = computed(() => `${height.value}px`);

const overlay = ref<HTMLDivElement>() as Ref<HTMLDivElement>;
const bottomSheet = ref<HTMLDivElement>() as Ref<HTMLDivElement>;
const handle = ref<HTMLDivElement>() as Ref<HTMLDivElement>;

const animation = useBottomSheetAnimation(
  bottomSheet,
  overlay,
  defaultHeight.value
);

watch(
  activeModel,
  (value) => (value ? openBottomSheet() : closeBottomSheet()),
  { immediate: true }
);
watch(handle, () => {
  if (!handle.value) return;
  createHammerManager();
  animation.onOpened(() => (height.value = defaultHeight.value));
});

function openBottomSheet() {
  innerActive.value = true;
}

function createHammerManager() {
  const hammer = new Hammer(handle.value, {});
  hammer
    .get("pan")
    .set({ direction: Hammer.DIRECTION_VERTICAL, threshold: 0, pointers: 0 });
  hammer.on("pan", (e: any) => {
    if (e.deltaY < 0) return;
    height.value = defaultHeight.value - e.deltaY;
  });
  hammer.on("panend", (e: any) => {
    const relativeHeight = height.value / defaultHeight.value;
    if (relativeHeight >= BottomSheetAnimation.PercentageForClosingSheet)
      animation.reset();
    else activeModel.value = false;
  });
}

function closeBottomSheet() {
  if (!bottomSheet.value) return;
  emit(BottomSheetEvents.closed);
  animation.onClosed(() => {
    height.value = 0;
    //Destroy bottomsheet
    innerActive.value = false;
  });
}
</script>

<style scoped>
.overlay {
  pointer-events: auto;
  background: rgb(var(--v-theme-on-surface));
  border-radius: inherit;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2000;
}

section {
  bottom: 0;
  left: 0;
  position: fixed;
  margin-inline: 0;
  margin-bottom: 0;
  width: 100%;
  border-radius: var(--mdui-shape-corner-extra-large);
  box-shadow: 0px 7px 8px -4px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
    0px 12px 17px 2px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
    0px 5px 22px 4px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12));
  z-index: 2001;
  display: flex;
  flex-direction: column;
}

.handle-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;
}

.handle {
  cursor: pointer;
  justify-self: center;
  background-color: rgb(var(--mdui-color-outline));
  width: 32px;
  height: 4px;
  border-radius: 100px;
}
</style>
