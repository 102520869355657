export enum ActionTransactionType {
  None = 0,
  ForceApproval = 1,
  Reanalyze = 2,
  Prioritize = 3,
  RequestChargeback = 4,
  CancelOrder = 5
}

export type ActionItem<T = number> = {
  id: T
  icon: string
}
