<template>
  <v-card
    id="card-item"
    class="position-relative rounded-xl"
    elevation="1"
    @click.stop="handleSelectItem"
  >
    <div class="d-flex justify-end">
      <div class="pa-4 bg-cs_ref_palette_information95"></div>
      <div
        ref="hiddenContent"
        class="pa-4 bg-cs_ref_palette_information95"
        :style="{
          height: cardItemRef?.getBoundingClientRect().height + 'px',
          borderRadius: '0px 24px 24px 0px',
        }"
      >
        <slot :data="{ item }" name="back" />
      </div>
    </div>
    <div
      v-ripple
      class="position-absolute top-0 left-0 right-0 pa-4 elevation-1 rounded-xl"
      ref="cardItemRef"
      :class="modelSelected ? 'bg-cs_ref_palette_information95' : className"
    >
      <div class="header d-flex justify-space-between mb-2" v-if="header">
        <slot :data="{ item }" name="header" />
      </div>
      <div class="content d-flex align-start">
        <div class="mr-4">
          <slot name="icon" :data="{ selectable, item, activeSelected }" />
        </div>
        <div
          class="flex-grow-1"
          :style="`display: grid; grid-template-columns: repeat(${
            columns ?? 2
          }, 1fr); align-items:center; `"
        >
          <slot :data="{ item }" />
        </div>
      </div>
    </div>
  </v-card>
</template>

<script setup lang="ts" generic="T">
import { onMounted, ref } from "vue";
import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import { swipeUtility } from "@/utils/swipeUtility";

const props = withDefaults(
  defineProps<{
    header?: boolean;
    selectable?: boolean;
    columns?: number;
    actions?: boolean;
    icon?: { name?: string; color?: string; buttonColor?: string };
    item: T;
  }>(),
  { header: true }
);

const emits = defineEmits<{ (e: "select", item: T): void }>();

const modelSelected = defineModel<boolean>("selected");

const cardItemRef = ref<HTMLElement | null>(null);
const hiddenContent = ref<HTMLElement | null>(null);

const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};
const className = useClassByTheme(classTheme);

function activeSelected(value: boolean) {
  if (props.selectable) modelSelected.value = value;
}

function handleSelectItem() {
  emits("select", props.item);
}

onMounted(() => {
  if (props.actions && cardItemRef.value && hiddenContent.value) {
    let swipe = swipeUtility(cardItemRef.value, {
      leftElement: hiddenContent.value,
      rightElement: cardItemRef.value,
      type: "LEFT",
    });

    // if (mobile && props.active) {
    //   swipe.hammer.destroy();
    //   return;
    // }
    swipe.onLoadEvents(swipe.hammer);
  }
});
</script>
